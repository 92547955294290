import { useEffect, useRef } from 'react';

export const useInterval = (callback: () => void, delay: number, shouldCallImmediately = false, deps: any[] = []) => {
  const intervalRef = useRef<NodeJS.Timeout>();
  const callbackRef = useRef<() => void>(callback);
  callbackRef.current = callback;

  useEffect(() => {
    shouldCallImmediately && callbackRef.current();
    intervalRef.current = setInterval(() => callbackRef.current(), delay);
    return () => intervalRef.current && clearInterval(intervalRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay, shouldCallImmediately, ...deps]);

  return intervalRef;
};

export default useInterval;
