import React from 'react';

import ChatTestWidgetHeader from './components/ChatTestWidgetHeader';
import SendMessage from './components/SendMessage';
import MessagesContainer from './components/MessagesContainer';

import { ChatTestWidgetContextProviderComponent, useChatTestWidgetContext } from './contexts/ChatTestWidgetContext';
import { ModelInfoData } from 'api/CailagateApi/api/client';
import { WidgetComponentProps, WidgetConfigProps, withWidgetLayout } from 'HOC/withWidgetLayout';
import TestingForm from '../TestingForm';
import styles from './styles.module.scss';
import { t } from 'localization';
import { withCaptcha } from 'HOC/withCaptcha';

interface ChatTestWidgetInterface extends WidgetComponentProps {
  isFullScreen: boolean;
  toggleFullScreen: () => void;
}

const ChatTestWidget: React.FC<ChatTestWidgetInterface> = ({ isFullScreen, toggleFullScreen }) => {
  const { formMethods, predictConfigs, developerMode } = useChatTestWidgetContext();

  return (
    <>
      <ChatTestWidgetHeader />
      <TestingForm
        formMethods={formMethods}
        errors={formMethods.formState.errors}
        predictConfigs={predictConfigs}
        onlyConfig={!developerMode}
        hideRequestBodyInput={developerMode}
        classNameConfigSelect={styles.chatTestWidget__configSelect}
        editRequestBodyTitle={t('ChatTestWidget:Title')}
      />
      <MessagesContainer />
      <SendMessage />
    </>
  );
};

const ChatTestWidgetWithLayout = withCaptcha(withWidgetLayout(ChatTestWidget));

export default React.memo(
  ({
    serviceData,
    developerMode,
    ...props
  }: { serviceData?: ModelInfoData; developerMode?: boolean } & WidgetConfigProps) => {
    if (!serviceData) return null;
    return (
      <ChatTestWidgetContextProviderComponent serviceData={serviceData} developerMode={developerMode}>
        <ChatTestWidgetWithLayout {...props} />
      </ChatTestWidgetContextProviderComponent>
    );
  }
);
