import { Inject, Service } from 'typedi';

import { AccessRequestFormFieldsType } from './types';
import { ManualManagementApiService } from 'services/ApiServices/ManualManagementApiService';
import { ManagementRequestData } from 'api/CailagateApi/api/client';

@Service()
export class AccessRequestFormService {
  @Inject(() => ManualManagementApiService) manualManagementApi!: ManualManagementApiService;

  private convertFieledValues(formValues: AccessRequestFormFieldsType): ManagementRequestData {
    const { comment, contacts } = formValues;
    return {
      comment,
      contacts,
    };
  }

  public async submitFormToRequestModelAdmin(accountId: number, formValues: AccessRequestFormFieldsType) {
    return this.manualManagementApi.requestFeature(
      accountId.toString(),
      'model_admin',
      this.convertFieledValues(formValues)
    );
  }

  public async submitFormToRequestPublicModelAdmin(accountId: number, formValues: AccessRequestFormFieldsType) {
    return this.manualManagementApi.requestFeature(
      accountId.toString(),
      'public_model_admin',
      this.convertFieledValues(formValues)
    );
  }
}
