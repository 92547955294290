import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import cn from 'classnames';

import ResizableTextArea from 'components/ResizableTextArea';
import ControlledSelect from 'components/ControlledSelect';

import { ConfigListItemType, ConfigSelectFieldsType } from '../../types';

import { t } from 'localization';
import styles from './styles.module.scss';

interface ConfigEditInterface {
  configList?: ConfigListItemType[];
  setIsEditing?: (isEditing: boolean) => void;
}

const ConfigEdit = ({ configList, setIsEditing }: ConfigEditInterface) => {
  const { control, watch } = useFormContext<ConfigSelectFieldsType>();
  const watchConfigMode = watch('configMode');

  const handleOnFocus = useCallback(() => {
    setIsEditing && setIsEditing(true);
  }, [setIsEditing]);

  const handleOnBlur = useCallback(() => {
    setIsEditing && setIsEditing(false);
  }, [setIsEditing]);

  return (
    <>
      <Controller
        name='config'
        control={control}
        rules={{ required: false }}
        render={({ field: { value, onChange, name }, fieldState: { invalid, error } }) => (
          <ResizableTextArea
            name={name}
            value={value}
            onChange={onChange}
            hidden={watchConfigMode !== 'writeConfig'}
            placeholder={t('Model:TestingForm:ConfigPlaceholder')}
            className={cn(styles.configurationSelect__textarea, {
              [styles.configurationSelect__hidden]: watchConfigMode !== 'writeConfig',
            })}
            data-test-id='TestingForm:textareaConfig'
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            errorText={invalid ? error?.message : ''}
            hasError={invalid}
          />
        )}
      />
      <ControlledSelect
        name='configId'
        control={control}
        rules={{ required: false }}
        options={configList}
        fullWidth
        className={cn(styles.configurationSelect__select, {
          [styles.configurationSelect__hidden]: watchConfigMode === 'writeConfig',
        })}
        inputPlaceholder={t('Model:TestingForm:SelectConfig')}
      />
    </>
  );
};

export default React.memo(ConfigEdit);
