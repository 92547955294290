import React, { CSSProperties, useEffect } from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import { Icon } from '@just-ai/just-ui/dist/Icon';
import { usePrevious } from '@just-ai/just-ui/dist/utils';

import { SelectOption } from 'components/IconSelect/types';
import { ListPositionsType } from 'components/IconSelect';

import styles from './styles.module.scss';
import './styles.scss';

interface IconSelectListInterface {
  options: SelectOption[];
  handleSelect: (option: SelectOption) => void;
  isOpen: boolean;
  listPositionStyle?: CSSProperties;
  className?: string;
  position?: ListPositionsType;
}

const IconSelectList = ({
  isOpen,
  options,
  handleSelect,
  listPositionStyle,
  className,
  position,
}: IconSelectListInterface) => {
  const prevIsOpen = usePrevious(isOpen);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('force-overflow-hidden');
    }
    return () => {
      document.body.classList.remove('force-overflow-hidden');
    };
  }, [isOpen, prevIsOpen]);

  if (!isOpen) return null;
  if (position === 'absolute') {
    return (
      <div className={cn(styles.iconSelectList, styles.iconSelectList_absolute, className)}>
        {options.map((option, index) => (
          <div className={styles.iconSelectList__item} key={index + option.label} onClick={() => handleSelect(option)}>
            <Icon name={option.label} />
          </div>
        ))}
      </div>
    );
  }
  return ReactDOM.createPortal(
    <div className={cn(styles.iconSelectList, styles.iconSelectList_fixed, className)} style={listPositionStyle}>
      {options.map((option, index) => (
        <div className={styles.iconSelectList__item} key={index + option.label} onClick={() => handleSelect(option)}>
          <Icon name={option.label} />
        </div>
      ))}
    </div>,
    document.body
  );
};

export default React.memo(IconSelectList);
