import React from 'react';

import Chip, { ChipProps } from 'components/Chip';

import styles from './styles.module.scss';

export enum TagsChipType {
  TextTask,
  Language,
  AvailableInJaicp,
}

const typeMap: Record<TagsChipType, { class: string }> = {
  [TagsChipType.TextTask]: {
    class: styles.textTask,
  },
  [TagsChipType.Language]: {
    class: styles.language,
  },
  [TagsChipType.AvailableInJaicp]: {
    class: styles.availableInJaicp,
  },
};
export interface TagsChipProps extends Omit<ChipProps, 'className'> {
  type: TagsChipType;
}

export interface TagsChipElement extends TagsChipProps {
  key: string;
}

const TagsChip = ({ type, ...props }: TagsChipProps) => {
  const typeValue = typeMap[type];
  return <Chip {...props} className={typeValue.class} />;
};

export default React.memo(TagsChip);
