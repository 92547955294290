import { useCallback, useEffect, useState } from 'react';
import { useDI } from 'contexts/AppContext';
import { PredictConfigApiService } from 'services/ApiServices/PredictConfigApiService';
import { ModelInfoData, PredictConfigData } from 'api/CailagateApi/api/client';
import { useLoading } from 'utils/hooks';
import { AppLogger } from 'services/AppLogger';

export const usePredictConfigs = (serviceData: ModelInfoData): [PredictConfigData[] | undefined, boolean] => {
  const predictConfigApi = useDI(PredictConfigApiService);
  const [predictConfigs, setPredictConfigs] = useState<PredictConfigData[] | undefined>();
  const [isLoading, , startLoading, endLoading] = useLoading();

  const listPredictConfigs = useCallback(async () => {
    if (!serviceData) return;
    startLoading();
    const {
      id: { accountId, modelId },
    } = serviceData;
    try {
      const { data: configs = undefined } = await predictConfigApi.listPredictConfigs(
        accountId.toString(),
        modelId.toString()
      );
      setPredictConfigs(configs);
    } catch (error: any) {
      AppLogger.error({ exception: error });
    }
    endLoading();
  }, [serviceData, startLoading, endLoading, predictConfigApi]);

  useEffect(() => {
    listPredictConfigs();
  }, [listPredictConfigs]);

  return [predictConfigs, isLoading];
};
