import { useEffect } from 'react';
import keyboardJS, { KeyEvent } from 'keyboardjs';

interface useKeyboardActionsInterface {
  enable: boolean;
  action: (event?: KeyEvent) => void;
  hotKeys: string[];
}

const useKeyboardAction = ({ enable, hotKeys, action }: useKeyboardActionsInterface) => {
  useEffect(() => {
    if (!enable) return;
    keyboardJS.bind(hotKeys, action);
    return () => {
      keyboardJS.unbind(hotKeys, action);
    };
  }, [action, enable, hotKeys]);
};

export default useKeyboardAction;
