import { createContext, FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';

import { useAppContext, useDI } from 'contexts/AppContext';
import { useAuthContext } from 'contexts/AuthContext';
import { SystemConfigApiService } from 'services/ApiServices/SystemConfigApiService';
import { CaptchaData, ModelGroupData, TaskTypeData } from 'api/CailagateApi/api/client';
import { ModelGroupApiService } from 'services/ApiServices/ModelGroupApiService';

export interface TaskTypeDataMap {
  [key: string]: TaskTypeData;
}

export type ServicesContextType = {
  taskTypesMap?: TaskTypeDataMap;
  taskTypes?: TaskTypeData[];
  getTaskTypeData: (taskTypeName?: string) => TaskTypeData;
  serviceGroups: ModelGroupData[];
  getServiceGroups: () => Promise<void>;
  getServicesSupportInfo: () => Promise<void>;
  deleteServicesGroup: (groupId: number) => Promise<void>;
  editServicesGroup: (name: string) => Promise<void>;
  updateServicesGroup: (name: string, accountId: number, groupId: number) => Promise<void>;
  captchaConfig?: CaptchaData;
};

export const ServicesContext = createContext({} as ServicesContextType);

const defaultTask: TaskTypeData = {
  displayName: '',
  name: '',
};

export const ServicesContextProviderComponent: FunctionComponent = ({ children }) => {
  const [taskTypes, setTaskTypes] = useState<
    { taskTypesMap?: TaskTypeDataMap; taskTypesRaw?: TaskTypeData[] } | undefined
  >();
  const [serviceGroups, setServiceGroups] = useState<ModelGroupData[]>([]);
  const [isInitialized, setIsInitialized] = useState(false);
  const [captchaConfig, setCaptchaConfig] = useState<CaptchaData>();

  const { handleError, setLoading } = useAppContext();
  const { user } = useAuthContext();

  const systemConfigApi = useDI(SystemConfigApiService);
  const modelGroupApi = useDI(ModelGroupApiService);

  const getCaptchaConfig = useCallback(async () => {
    setLoading(true);
    try {
      const { data: captchaData = undefined } = await systemConfigApi.getCaptchaConfig();
      setCaptchaConfig(captchaData);
    } catch (error) {
      handleError(error);
    }
    setLoading(false);
  }, [handleError, setLoading, systemConfigApi]);

  const getTaskTypes = useCallback(async () => {
    setLoading(true);
    try {
      const { data: taskTypes = undefined } = await systemConfigApi.getTaskTypes();
      const taskTypesMap = taskTypes?.reduce((taskTypesMap, taskType) => {
        taskTypesMap[taskType.name] = taskType;
        return taskTypesMap;
      }, {} as TaskTypeDataMap);
      setTaskTypes({ taskTypesMap, taskTypesRaw: taskTypes });
    } catch (error) {
      handleError(error);
    }
    setLoading(false);
  }, [handleError, setLoading, systemConfigApi]);

  const getServiceGroups = useCallback(async () => {
    if (!user) return;
    setLoading(true);
    try {
      const { data: groupsData } = await modelGroupApi.getGroups(user.accountId.toString());
      setServiceGroups(groupsData);
    } catch (error) {
      handleError(error);
    }
    setLoading(false);
  }, [handleError, modelGroupApi, setLoading, user]);

  const deleteServicesGroup = useCallback(
    async (groupId: number) => {
      if (!user) return;
      setLoading(true);
      try {
        await modelGroupApi.deleteGroup(user.shortName, groupId);
        await getServiceGroups();
      } catch (error) {
        handleError(error);
      }
      setLoading(false);
    },
    [getServiceGroups, handleError, modelGroupApi, setLoading, user]
  );
  const editServicesGroup = useCallback(
    async (name: string) => {
      if (!user) return;
      setLoading(true);
      try {
        await modelGroupApi.createGroup(user.shortName, { name });
      } catch (error) {
        setLoading(false);
        throw error;
      }
      await getServiceGroups();
      setLoading(false);
    },
    [getServiceGroups, modelGroupApi, setLoading, user]
  );
  const updateServicesGroup = useCallback(
    async (name: string, accountId: number, groupId: number) => {
      if (!user) return;
      setLoading(true);
      try {
        await modelGroupApi.updateGroup(user.shortName, { name, id: { accountId, groupId } });
      } catch (error) {
        setLoading(false);
        throw error;
      }
      await getServiceGroups();
      setLoading(false);
    },
    [getServiceGroups, modelGroupApi, setLoading, user]
  );

  const getTaskTypeData = useCallback(
    (taskTypeName?: string) =>
      taskTypes?.taskTypesMap && taskTypeName ? taskTypes.taskTypesMap[taskTypeName] : defaultTask,
    [taskTypes?.taskTypesMap]
  );

  const getServicesSupportInfo = useCallback(async () => {
    await Promise.allSettled([getTaskTypes(), getServiceGroups(), getCaptchaConfig()]);
  }, [getCaptchaConfig, getServiceGroups, getTaskTypes]);

  useEffect(() => {
    getServicesSupportInfo().finally(() => setIsInitialized(true));
  }, [getServicesSupportInfo]);

  if (!isInitialized) {
    return null;
  }
  return (
    <ServicesContext.Provider
      value={{
        taskTypesMap: taskTypes?.taskTypesMap,
        taskTypes: taskTypes?.taskTypesRaw,
        getTaskTypeData,
        serviceGroups,
        getServiceGroups,
        deleteServicesGroup,
        editServicesGroup,
        getServicesSupportInfo,
        updateServicesGroup,
        captchaConfig,
      }}
    >
      {children}
    </ServicesContext.Provider>
  );
};

export const useServicesContext = () => useContext(ServicesContext);
