import React from 'react';
import { Route, RouteProps } from 'react-router';

const Simple = (props: RouteProps) => {
  return (
    <Route {...props}>
      <div className='page-content'>{props.children}</div>
    </Route>
  );
};

export default Simple;
