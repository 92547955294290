import React, { DependencyList, useCallback, useState } from 'react';

interface PromiseCallbackInterface<RESULT, INPUT> {
  (resolve: (data: RESULT) => void, reject: () => void, opened: boolean, inputData?: INPUT): React.ReactNode;
}
interface PromiseHandlersInterface<RESULT> {
  onResolve?: (data: RESULT) => void;
  onReject?: () => void;
}

export function usePromisifyComponent<RESULT, INPUT>(
  callback: PromiseCallbackInterface<RESULT, INPUT>,
  handlers: PromiseHandlersInterface<RESULT> = {},
  deps: DependencyList = []
): [React.ReactNode, (data?: INPUT) => void, () => void, boolean, INPUT | undefined] {
  const [opened, setOpen] = useState(false);
  const [inputData, setInputData] = useState<INPUT | undefined>();

  const innerCallback = useCallback(callback, [callback, opened, ...deps]);
  const open = useCallback((data?: INPUT) => {
    setInputData(data);
    setOpen(true);
  }, []);
  const close = useCallback(() => setOpen(false), []);
  const onClose = useCallback(() => {
    setOpen(false);
    handlers.onReject && handlers.onReject();
  }, [handlers]);
  const onResolve = useCallback(
    data => {
      setOpen(false);
      return handlers.onResolve ? handlers.onResolve(data) : null;
    },
    [handlers]
  );

  return [innerCallback(onResolve, onClose, opened, inputData), open, close, opened, inputData];
}
