import { Service } from 'typedi';

import { SystemConfigEndpointApi } from 'api/CailagateApi/api/client';

@Service()
export class SystemConfigApiService extends SystemConfigEndpointApi {
  constructor() {
    super({ baseOptions: { timeout: 30000 } }, '');
  }
}
