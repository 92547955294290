import Iframe from 'components/Iframe';
import { useScrollIframeToAnchor } from './utils';
import { useAppContext } from 'contexts/AppContext';

import styles from './styles.module.scss';
import { Helmet } from 'react-helmet';

const LandingPage = function () {
  const [setIframeOnLoad] = useScrollIframeToAnchor();
  const { isExtendedLanding, canonicalHref } = useAppContext();
  const landingToShow = isExtendedLanding ? '/staticPages/LandingPage.html' : '/staticPages/LandingPageShortened.html';

  return (
    <div className='content-page' style={{ padding: '0 0', width: '100%' }}>
      <Helmet>{canonicalHref && <link rel='canonical' href={canonicalHref} />}</Helmet>
      <Iframe
        wrapClassName={!isExtendedLanding ? styles.landingPage__shortened : undefined}
        src={landingToShow}
        title='landing iframe'
        fullHeight
        onLoadControlled={setIframeOnLoad}
      />
    </div>
  );
};

export default LandingPage;
