import { useState, useCallback, useEffect } from 'react';
import { useAppContext } from '../contexts/AppContext';

export type SetTrueCallback = () => void;
export type SetFalseCallback = () => void;
export type ToggleCallback = () => void;

export const useToggle = (
  initialValue: boolean = false
): [boolean, SetTrueCallback, SetFalseCallback, ToggleCallback] => {
  const [value, setValue] = useState<boolean>(initialValue);
  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);
  const toggle = useCallback(() => setValue(!value), [value]);
  return [value, setTrue, setFalse, toggle];
};

export type LoadCallback = <PAYLOAD>(promise: Promise<PAYLOAD>) => Promise<PAYLOAD>;

export const useGlobalLoading = (): [boolean, LoadCallback, () => void, () => void] => {
  const { loading: isLoading, setLoading } = useAppContext();
  const load: LoadCallback = useCallback(
    promise => {
      setLoading(true);
      return promise.finally(() => setLoading(false));
    },
    [setLoading]
  );
  const startLoading = useCallback(() => {
    setLoading(true);
  }, [setLoading]);

  const endLoading = useCallback(() => {
    setLoading(false);
  }, [setLoading]);

  return [isLoading, load, startLoading, endLoading];
};

export const useLoading = (): [boolean, LoadCallback, () => void, () => void] => {
  const [isLoading, setLoading] = useState(false);
  const load: LoadCallback = useCallback(promise => {
    setLoading(true);
    return promise.finally(() => setLoading(false));
  }, []);
  const startLoading = useCallback(() => {
    setLoading(true);
  }, [setLoading]);

  const endLoading = useCallback(() => {
    setLoading(false);
  }, [setLoading]);
  return [isLoading, load, startLoading, endLoading];
};

export const useDisableBodyScroll = (open: boolean) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [open]);
};

export const useToggleGeneric = <STATE1 extends string, STATE2 extends string>(
  [state1, state2]: [STATE1, STATE2],
  initialValue: STATE1 | STATE2 = state1
): [STATE1 | STATE2, () => void, () => void, () => void] => {
  type MyType = STATE1 | STATE2;
  const [value, setValue] = useState<MyType>(initialValue);
  const setState1 = useCallback(() => setValue(state1), [state1]);
  const setState2 = useCallback(() => setValue(state2), [state2]);
  const toggle = useCallback(() => setValue(value === state1 ? state2 : state1), [state1, state2, value]);
  return [value, setState1, setState2, toggle];
};
