import React from 'react';

import { Textarea, TextareaProps } from '@just-ai/just-ui/dist/Textarea';
import { Theme } from '@just-ai/just-ui/dist/Common';

import './style.scss';

interface TextAreaInterface extends TextareaProps {
  value: string;
  onChange: (text: string) => void;
  title?: string;
  placeholder?: string;
}
const TextArea = function ({ title, value, onChange, type, placeholder, ...props }: TextAreaInterface) {
  return (
    <label className='cmp-textarea'>
      {title}
      <Textarea
        {...props}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        inputState={undefined}
        theme={Theme.light}
      />
    </label>
  );
};

export default React.memo(TextArea) as typeof TextArea;
