import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import cn from 'classnames';

import { Spoiler } from '@just-ai/just-ui/dist/Spoiler';
import ConfigHead from './components/ConfigHead';
import ConfigurationMode from './components/ConfigurationMode';
import ConfigEdit from './components/ConfigEdit';

import { ConfigListItemType } from '../../../TestingForm/types';
import { ConfigSelectFieldsType } from './types';

import { useToggle } from 'utils/hooks';

import styles from './styles.module.scss';

interface ConfigurationSelectInterface {
  configList?: ConfigListItemType[];
  setIsEditing?: (isEditing: boolean) => void;
  withSpoiler?: boolean;
  className?: string;
}

const ConfigurationSelect = ({
  configList,
  setIsEditing,
  className,
  withSpoiler = false,
}: ConfigurationSelectInterface) => {
  const { watch } = useFormContext<ConfigSelectFieldsType>();
  const watchShouldSetConfig = watch('shouldSetConfig');

  const [isOpen, open, close, toggleOpened] = useToggle();
  const forceOpen = withSpoiler && isOpen;

  useEffect(() => {
    if (watchShouldSetConfig) {
      open();
    } else {
      close();
    }
  }, [close, open, watchShouldSetConfig]);

  const content = useMemo(
    () => (
      <div className={styles.configurationSelect__content} hidden={!watchShouldSetConfig && !forceOpen}>
        <ConfigurationMode />
        <ConfigEdit configList={configList} setIsEditing={setIsEditing} />
      </div>
    ),
    [configList, forceOpen, setIsEditing, watchShouldSetConfig]
  );

  if (withSpoiler) {
    return (
      <div className={cn(styles.configurationSelect__spoiler, className)}>
        <Spoiler
          controlled
          withWrapper
          fullWidthCollapse
          openProp={isOpen}
          toggleProp={toggleOpened}
          key='config-select'
          Header={ConfigHead}
        >
          <div className={styles.configurationSelect}>{content}</div>
        </Spoiler>
      </div>
    );
  }

  return (
    <div className={cn(styles.configurationSelect__wrap, className)}>
      <ConfigHead />
      {content}
    </div>
  );
};

export default React.memo(ConfigurationSelect);
