import React, { useMemo } from 'react';

import { FilterManagementInterface } from 'components/Filter/reducer';
import { BaseFilterType } from 'components/Filter/types';

import { FilterOptions, getFilterModalComponents } from './types';
import { t } from 'localization';
import { useServicesContext } from 'contexts/ServicesContext';
import ModalFiltersContainer from 'components/ModalFiltersContainer';

interface FiltersContainerInterface extends FilterManagementInterface<FilterOptions> {
  filterData: BaseFilterType<FilterOptions>[];
  isInitialized: boolean;
}

const FiltersContainer = ({
  filterData,
  addNewFilter,
  deleteFilter,
  updateFilter,
  isInitialized,
}: FiltersContainerInterface) => {
  const options = useMemo<{ label: string; value: FilterOptions }[]>(() => {
    const defaultOptions = [
      { label: t(`Filter:SelectFilter:TaskType`), value: FilterOptions.TaskType },
      //TODO: add when backend will be ready
      // { label: t(`Filter:SelectFilter:Language`), value: FilterOptions.Language },
    ];
    return defaultOptions.filter(defaultOption => !filterData.find(filter => defaultOption.value === filter.key));
  }, [filterData]);
  const { taskTypes } = useServicesContext();

  const filterProps = useMemo(
    () => ({
      [FilterOptions.TaskType]: {
        options: taskTypes?.map(taskType => ({ label: taskType.displayName, value: taskType.name })) || [],
      },
    }),
    [taskTypes]
  );
  const FILTER_MODAL_COMPONENTS = useMemo(() => getFilterModalComponents(filterProps), [filterProps]);

  return (
    <ModalFiltersContainer
      options={options}
      addNewFilter={addNewFilter}
      updateFilter={updateFilter}
      deleteFilter={deleteFilter}
      filterData={filterData}
      filterComponents={FILTER_MODAL_COMPONENTS}
      isInitialized={isInitialized}
    />
  );
};

export default React.memo(FiltersContainer);
