import { ConfigDefinitionMode } from 'services/PredictService';
export { ConfigDefinitionMode };

export enum ConfigSelectFieldsNames {
  shouldSetConfig = 'shouldSetConfig',
  config = 'config',
  configId = 'configId',
  configMode = 'configMode',
}

export type ConfigSelectFieldsType = {
  [ConfigSelectFieldsNames.configMode]: ConfigDefinitionMode;
  [ConfigSelectFieldsNames.shouldSetConfig]: boolean;
  [ConfigSelectFieldsNames.configId]?: string;
  [ConfigSelectFieldsNames.config]: string;
};

export type ConfigListItemType = {
  label: string;
  value: string;
};
