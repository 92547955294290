import { UseFormReturn } from 'react-hook-form';

export type BalanceRefillFormMethodsType = UseFormReturn<BalanceRefillFormFieldsType, any>;

export type BalanceRefillFormFieldsNames = 'method' | 'sum';
export type MethodsType = 'fromIncomeBalance' | 'fromCard';

export const BalanceRefillFormFieldsNamesList: BalanceRefillFormFieldsNames[] = ['method', 'sum'];

export type BalanceRefillFormFieldsType = {
  method: MethodsType;
  sum: string;
  package: string;
  commonError: any;
};
export const defaultFormValues: BalanceRefillFormFieldsType = {
  method: 'fromCard',
  sum: '',
  package: '',
  commonError: undefined,
};
