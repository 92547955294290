import { createContext, FunctionComponent, useContext } from 'react';

import { useAccessRequestModal } from 'components/Modals/AccessRequestModal';
import { RequestType } from 'components/Modals/AccessRequestModal/types';

export type ModalsContextType = {
  openAccessRequestModal: (
    data?:
      | {
          requestType?: RequestType | undefined;
        }
      | undefined
  ) => void;
};

export const ModalsContext = createContext({} as ModalsContextType);

interface ModalsContextProviderProps {}

export const ModalsContextProviderComponent: FunctionComponent<ModalsContextProviderProps> = ({ children }) => {
  const [AccessRequestModalNode, openAccessRequestModal] = useAccessRequestModal();

  return (
    <ModalsContext.Provider
      value={{
        openAccessRequestModal: openAccessRequestModal,
      }}
    >
      {children}
      {AccessRequestModalNode}
    </ModalsContext.Provider>
  );
};

export const useModalsContext = () => useContext(ModalsContext);
