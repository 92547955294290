export interface MessageType {
  data: MessageDataType;
  timestamp?: Date;
  operator?: string;
  source: MessageSourceType;
  id: string;
}

export enum MessageSourceType {
  CLIENTMESSAGE = 'CLIENT_MESSAGE',
  BOTMESSAGE = 'BOT_MESSAGE',
}

export type RequestCallbacks = {
  success?: () => void;
  error?: () => void;
};

export interface ResponseData {
  replies?: MessageDataType[];
}

export type MessageDataType = ReplyTextDto | ReplyInlineButtonsDto | ReplyImageDto;

type ReplyTextDto = {
  type: 'text';
  errorStacktrace?: string;
  text?: string;
};

type ReplyInlineButtonsDto = {
  type: 'inlineButtons';
  buttons: {
    callback_data: string;
    text: string;
  }[];
};

type ReplyImageDto = {
  type: 'image';
  imageUrl?: string;
  text?: string;
};
