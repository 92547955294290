import React, { ComponentType } from 'react';
import { Route, RouteProps } from 'react-router';

interface RouteWithLayoutInterface extends RouteProps {
  layout: ComponentType<any>;
  component: ComponentType<RouteProps>;
}

const RouteWithLayout = ({ component: Component, layout: Layout, ...rest }: RouteWithLayoutInterface) => (
  <Route
    {...rest}
    render={() => (
      <Layout>
        <Component />
      </Layout>
    )}
  />
);

export default RouteWithLayout;
