import { Link, Route } from 'react-router-dom';

import Button from 'components/Button';
import Logo from './components/Logo';
import AccountMenu from './components/AccountMenu';
import BuildInfo from './components/BuildInfo';
import TabsNavigate from 'modules/Account/components/TabsNavigate';
import AccountsSelector from './components/AccountsSelector';
import BalanceInfo from './components/BalanceInfo';
import BannerContainer from './components/BannerContainer';

import { useAuthContext } from 'contexts/AuthContext';

import { TabValue } from 'modules/Account/pages/PrivateServicePage/types';

import './style.scss';
import { useMediaQuery } from 'hooks/useMediaQuery';
import MobileScreenMenu from './components/MobileScreenMenu';

export default function Header() {
  const { loginWithAccountPageRedirect, logout, user, isAuthenticated, isUserSuperadmin } = useAuthContext();

  const tabs: TabValue[] = user
    ? [
        {
          name: 'PagesTabs:Workspace',
          value: 'workspace',
          dataTestId: 'PagesTabs:Workspace',
        },
        {
          name: 'PagesTabs:Catalog',
          value: 'catalog',
          dataTestId: 'PagesTabs:Catalog',
        },
      ]
    : [
        {
          name: 'PagesTabs:Catalog',
          value: 'catalog',
          dataTestId: 'PagesTabs:Catalog',
        },
      ];
  const isMobileScreen = useMediaQuery('(max-width: 640px)');

  return (
    <>
      <header className='caila-header'>
        <BannerContainer />
        <div className='caila-header__container'>
          <div className='caila-header__block'>
            <Link to='/'>
              <Logo />
            </Link>
            <AccountsSelector />
          </div>
          <BuildInfo />
          <div className='caila-header__btn-block'>
            {!isMobileScreen && (
              <>
                {isAuthenticated ? (
                  <>
                    {!isUserSuperadmin && (
                      <>
                        <BalanceInfo />
                        <Route path='/:modulePage'>
                          <TabsNavigate className='headerNavigationTabs' tabs={tabs} path='' paramName='modulePage' />
                        </Route>
                      </>
                    )}
                    <AccountMenu logout={logout} userName={user?.shortName || ''} />
                  </>
                ) : (
                  <Route path='/:modulePage?'>
                    <TabsNavigate className='headerNavigationTabs' tabs={tabs} path='' paramName='modulePage' />
                  </Route>
                )}
                {!isAuthenticated && (
                  <Button color='primary' onClick={loginWithAccountPageRedirect}>
                    Войти
                  </Button>
                )}
              </>
            )}
            {isMobileScreen && <MobileScreenMenu />}
          </div>
        </div>
      </header>
    </>
  );
}
