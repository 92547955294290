import { useAuthContext } from 'contexts/AuthContext';
import { useServicesContext } from 'contexts/ServicesContext';
import { useCaptcha } from 'hooks/useCaptcha';
import React, { createContext, FunctionComponent, useCallback, useContext } from 'react';

export type CaptchaContextType = {
  recaptchaInstance: React.MutableRefObject<any>;
  verifyCallback: (response: any) => Promise<void>;
  executeCaptcha: () => Promise<any>;
  resetCaptcha: () => Promise<void>;
  onloadCallback: () => void;
  captchaState:
    | {
        gRecaptchaResponse: any;
        gRecaptchaError?: boolean | undefined;
      }
    | {
        gRecaptchaError: boolean;
        gRecaptchaResponse?: any;
      };
  executeCaptchaAndGetHeader: () => Promise<
    | {
        'g-recaptcha-response': any;
      }
    | undefined
  >;
  siteKey?: string;
};

export const CaptchaContext = createContext({} as CaptchaContextType);

export const CaptchaContextProviderComponent: FunctionComponent = ({ children }) => {
  const { recaptchaInstance, verifyCallback, executeCaptcha, captchaState, onloadCallback, resetCaptcha } =
    useCaptcha();
  const { user } = useAuthContext();
  const { captchaConfig } = useServicesContext();

  const executeCaptchaAndGetHeader = useCallback(async () => {
    if (user || !captchaConfig?.enabled) return undefined;
    const token = await executeCaptcha();
    return {
      'g-recaptcha-response': token,
    };
  }, [captchaConfig?.enabled, executeCaptcha, user]);

  return (
    <CaptchaContext.Provider
      value={{
        recaptchaInstance,
        verifyCallback,
        executeCaptcha,
        captchaState,
        onloadCallback,
        resetCaptcha,
        executeCaptchaAndGetHeader,
        siteKey: captchaConfig?.siteKey,
      }}
    >
      {children}
    </CaptchaContext.Provider>
  );
};

export const useCaptchaContext = () => useContext(CaptchaContext);
