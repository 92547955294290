import { AxiosPromise, AxiosResponse } from 'axios';
import { Service } from 'typedi';

interface CacheDataInterface {
  params: object;
  result: AxiosPromise;
}

@Service()
export class CacheRequestsService {
  cache: Map<string, CacheDataInterface>;

  constructor() {
    this.cache = new Map<string, CacheDataInterface>();
  }

  checkCache<REQUEST_PARAMS extends object>(
    id: string,
    requestParams: REQUEST_PARAMS
  ): Promise<AxiosResponse> | undefined {
    if (!this.cache.has(id)) {
      return undefined;
    } else {
      const cachedData = this.cache.get(id);
      if (cachedData === undefined) return undefined;
      const { params: cachedParams, result: cachedResult } = cachedData;
      let canUseCachedResult = true;
      Object.entries(requestParams).forEach(([key, value]) => {
        if ((cachedParams as REQUEST_PARAMS)?.[key as keyof REQUEST_PARAMS] !== value) {
          canUseCachedResult = false;
        }
      });
      return canUseCachedResult ? cachedResult : undefined;
    }
  }
  public async cacheRequest<REQUEST_PARAMS extends any[], RESPONSE_DATA>(
    id: string,
    request: (...params: REQUEST_PARAMS) => AxiosPromise<RESPONSE_DATA>,
    ...requestParams: REQUEST_PARAMS
  ): Promise<AxiosResponse<RESPONSE_DATA>> {
    const cachedRequest = this.checkCache(id, requestParams);
    if (!cachedRequest) {
      const requestPromise = request(...requestParams).catch(error => {
        this.cache.delete(id);
        throw error;
      });
      this.cache.set(id, { params: requestParams, result: requestPromise });
      return requestPromise;
    } else {
      return cachedRequest;
    }
  }

  clear() {
    this.cache.clear();
  }
}
