import SelectFilter from 'components/Filter/components/SelectFilter';
import { BaseModalFilterInterface } from 'components/Filter/types';

export enum FilterOptions {
  TaskType = 'TaskType',
  Language = 'Language',
}

export const FilterOptionsList = Object.values(FilterOptions);

export interface FilterComponentsPropsType extends Partial<Record<FilterOptions, Record<string, any>>> {
  TaskType: {
    options: {
      label: string;
      value: string;
    }[];
  };
}

export const getFilterModalComponents = (propsMap: FilterComponentsPropsType) => {
  const FilterModalComponents: Record<
    FilterOptions,
    React.FC<BaseModalFilterInterface<FilterOptions> & typeof propsMap[FilterOptions]>
  > = {
    Language: () => null,
    TaskType: props => (
      <SelectFilter
        {...props}
        title='CatalogPage:Filter:TaskType:Title'
        placeholder='CatalogPage:Filter:TaskType:Placeholder'
        options={propsMap[FilterOptions.TaskType].options}
      />
    ),
  };
  return FilterModalComponents;
};
