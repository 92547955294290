import styles from './styles.module.scss';

export enum StatusType {
  WARNING = 'WARNING',
  DANGER = 'DANGER',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  SECONDARY = 'SECONDARY',
}

export const StatusIndicatorMap: Record<StatusType, string> = {
  [StatusType.WARNING]: styles.status__warning,
  [StatusType.DANGER]: styles.status__danger,
  [StatusType.SUCCESS]: styles.status__success,
  [StatusType.SECONDARY]: styles.status__secondary,
  [StatusType.INFO]: styles.status__info,
};
