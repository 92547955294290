import React, { useMemo } from 'react';
import { AudioPlayer } from '@just-ai/just-ui/dist/AudioPlayer';
import { useRecorderContext } from '../../contexts/RecorderContext';

const Player = () => {
  const { record, isRecording } = useRecorderContext();
  const audioToPlay = useMemo(() => (record ? URL.createObjectURL(record) : ''), [record]);

  if (isRecording || !record) return null;
  return <AudioPlayer url={audioToPlay} />;
};

export default React.memo(Player);
