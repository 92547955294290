import React, { useCallback, useMemo } from 'react';
import { Tabs } from '@just-ai/just-ui/dist/Tabs';

import MarkdownContainer, { MarkdownContainerInterface } from 'components/MarkdownContainer';

import { getH2Headings } from './utils';

import styles from './styles.module.scss';

interface MarkdownContainerWithTabsInterface extends MarkdownContainerInterface {
  withTabs?: boolean;
}

const MarkdownContainerWithTabs = function (props: MarkdownContainerWithTabsInterface) {
  const [headings, tabs] = useMemo(() => {
    const headings = getH2Headings(props.children);
    return [
      headings.map(item => ({
        text: item.text,
        link: item.link,
      })),
      headings.map(item => ({
        name: item.text,
        value: item.text,
      })),
    ];
  }, [props.children]);

  const onTabChanged = useCallback(
    (tab: string) => {
      const headingElement = headings.find(item => item.text === tab);
      if (!headingElement) return;
      const element = document.getElementById(headingElement.link);
      element?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
      });
    },
    [headings]
  );

  return (
    <div>
      {!!tabs.length && (
        <Tabs
          className={styles.markdownContainer__navigation}
          name='tabs'
          tabs={tabs}
          activeTab=''
          onChange={onTabChanged}
        />
      )}

      <MarkdownContainer
        {...props}
        components={{
          h2: props => {
            //@ts-ignore
            const value = props.node.children[0]?.value;
            const headingElement = headings.find(item => item.text === value);
            return <h2 id={headingElement?.link}>{props.children}</h2>;
          },
        }}
      />
    </div>
  );
};

export default React.memo(MarkdownContainerWithTabs);
