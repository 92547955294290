import { useEffect } from 'react';
import { ConfigDefinitionMode, ConfigSelectFieldsType } from './types';
import { UseFormResetField } from 'react-hook-form';
import { PredictConfigData } from 'api/CailagateApi/api/client';
import { getConfigIdToSet } from 'services/PredictService/utils';

export const useDefaultPredictConfig = (
  resetField: UseFormResetField<ConfigSelectFieldsType>,
  predictConfigs?: PredictConfigData[],
  preservedConfigId?: string
) => {
  useEffect(() => {
    if (predictConfigs && predictConfigs.length) {
      const configIdToSet = getConfigIdToSet(predictConfigs, preservedConfigId);
      resetField('configMode', { defaultValue: ConfigDefinitionMode.selectExistingConfig });
      window.requestAnimationFrame(() => {
        resetField('configId', { defaultValue: configIdToSet });
      });
    }
  }, [predictConfigs, preservedConfigId, resetField]);
};
