import React, { useCallback, useState } from 'react';
import axios from 'axios';

import RecordTestWidgetHeader from './components/RecordTestWidgetHeader';
import ProgressBar from 'components/ProgressBar';
import Button from 'components/Button';

import { ModelInfoData } from 'api/CailagateApi/api/client';

import ASRTestingForm, { useASRTestingForm } from './components/ASRTestingForm';
import { WidgetComponentProps, WidgetConfigProps, withWidgetLayout } from 'HOC/withWidgetLayout';
import { useAuthContext } from 'contexts/AuthContext';
import { useAppContext } from 'contexts/AppContext';
import { ASRTestingFormService } from './components/ASRTestingForm/ASRTestingFormService';
import { ASRTestingFormFieldsType } from './components/ASRTestingForm/types';
import PredictResult from '../PredictResult';
import Result from './components/Result';
import { PredictResultType } from '../PredictResult/types';

import { useLoading } from 'utils/hooks';
import { t } from 'localization';
import { parseError } from 'utils';

import styles from './styles.module.scss';
import { withCaptcha } from 'HOC/withCaptcha';
import { useCaptchaContext } from 'HOC/withCaptcha/contexts/CaptchaContext';

interface RecordTestComponentInterface {
  serviceData: ModelInfoData;
  developerMode?: boolean;
}

const RecordTestComponent: React.FC<RecordTestComponentInterface & WidgetComponentProps> = ({
  isFullScreen,
  serviceData,
  developerMode = false,
}) => {
  const [predictResult, setPredictResult] = useState<PredictResultType | undefined>();
  const { user } = useAuthContext();
  const [isLoading, , startLoading, endLoading] = useLoading();
  const [isCaptchaLoading, , startCaptchaLoading, endCaptchaLoading] = useLoading();
  const testingFormService = useAppContext().diContainer.get(ASRTestingFormService);
  const { executeCaptchaAndGetHeader } = useCaptchaContext();

  const [formMethods, isLoadingFormData, predictConfigs, hasDataToSubmit] = useASRTestingForm(serviceData);

  const handleSubmit = useCallback(async () => {
    if (!user || !serviceData?.id) return;
    const {
      id: { modelId, accountId },
      timeouts: { predictTimeoutSec },
    } = serviceData;
    try {
      startCaptchaLoading();
      const headers = await executeCaptchaAndGetHeader();
      endCaptchaLoading();
      startLoading();
      formMethods.clearErrors('commonError');
      const result = await testingFormService.submitTest(
        accountId,
        modelId,
        formMethods.getValues() as ASRTestingFormFieldsType,
        predictTimeoutSec,
        {
          headers,
        }
      );
      setPredictResult({ data: JSON.stringify(result.data) });
    } catch (error: any) {
      if (axios.isAxiosError(error) && error?.response?.status === 500) {
        setPredictResult({ data: JSON.stringify(error.response.data), withError: true });
      } else {
        formMethods.setError('commonError', { message: parseError(error) });
      }
    }
    endLoading();
  }, [
    endCaptchaLoading,
    endLoading,
    executeCaptchaAndGetHeader,
    formMethods,
    serviceData,
    startCaptchaLoading,
    startLoading,
    testingFormService,
    user,
  ]);

  return (
    <>
      <RecordTestWidgetHeader />
      <ASRTestingForm formMethods={formMethods} predictConfigs={predictConfigs} developerMode={developerMode} />
      {isLoading ? (
        <ProgressBar className={styles[`recordTestWidget__submitButton${isFullScreen ? '-fullScreen' : ''}`]} />
      ) : (
        <Button
          color='primary'
          type='submit'
          className={styles[`recordTestWidget__submitButton${isFullScreen ? '-fullScreen' : ''}`]}
          onClick={handleSubmit}
          data-test-id='RecordTestWidget:RunButton'
          disabled={isLoadingFormData || !hasDataToSubmit}
          isLoading={isCaptchaLoading}
        >
          {t('RecordTestWidget:RecognizeSpeech')}
        </Button>
      )}
      <PredictResult
        resultRender={resultData => <Result result={resultData} />}
        result={predictResult?.data}
        withError={predictResult?.withError}
      />
    </>
  );
};

const RecordTestWidget = withCaptcha(withWidgetLayout(RecordTestComponent));

const RecordTestWidgetComponent = React.memo(
  ({ serviceData, isRelative, developerMode }: RecordTestComponentInterface & WidgetConfigProps) => {
    if (!serviceData) return null;
    return <RecordTestWidget serviceData={serviceData} isRelative={isRelative} developerMode={developerMode} />;
  }
);

RecordTestWidgetComponent.displayName = 'RecordTestWidgetComponent';

export default RecordTestWidgetComponent;
