import { useReducer, useRef } from 'react';

type RecaptchaState = {
  gRecaptchaResponse?: any;
  gRecaptchaError?: boolean;
};

type RecaptchaAction = { type: 'SET_RESPONSE'; payload: any } | { type: 'SET_ERROR'; payload: boolean };

function recaptchaReducer(state: RecaptchaState, action: RecaptchaAction) {
  switch (action.type) {
    case 'SET_RESPONSE':
      return { ...state, gRecaptchaResponse: action.payload };
    case 'SET_ERROR':
      return { ...state, gRecaptchaError: action.payload };
    default:
      throw new Error('Error in recaptcha reducer');
  }
}

export function useCaptcha() {
  const [captchaState, dispatch] = useReducer(recaptchaReducer, { gRecaptchaResponse: '', gRecaptchaError: false });
  const recaptchaInstance = useRef<any>();

  const verifyCallback = async (response: any) => {
    dispatch({ type: 'SET_RESPONSE', payload: response });
    dispatch({ type: 'SET_ERROR', payload: false });
  };

  const executeCaptcha = async () => {
    if (recaptchaInstance.current) {
      recaptchaInstance.current.reset();
      let token = recaptchaInstance.current.getValue();
      if (!token) {
        token = await recaptchaInstance.current.executeAsync();
      }
      return token;
    }
  };

  const resetCaptcha = async () => {
    if (Boolean(recaptchaInstance.current)) {
      recaptchaInstance.current.reset();
      dispatch({ type: 'SET_RESPONSE', payload: null });
    }
  };

  const onloadCallback = () => {
    setTimeout(executeCaptcha, 0);
  };

  return {
    recaptchaInstance,
    executeCaptcha,
    resetCaptcha,
    verifyCallback,
    onloadCallback,
    captchaState,
  };
}
