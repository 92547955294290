import { Inject, Service } from 'typedi';
import pick from 'ramda/src/pick';

import { ProcessApiService } from 'services/ApiServices/ProcessApiService';
import { PredictService } from 'services/PredictService';
import { formDefaulValues, RequestBodyMode, TestingFormFieldsType } from './types';

@Service()
export class TestingFormService {
  @Inject(() => ProcessApiService) processApi!: ProcessApiService;
  @Inject(() => PredictService) predictService!: PredictService;
  static storageKey = 'testing-form-data';

  public async submitTest(
    accountId: number,
    modelId: number,
    formValues: TestingFormFieldsType,
    timeoutSec?: number,
    isTTS: boolean = false,
    options?: any
  ) {
    const { config, request, configId, configMode, requestBodyMode, shouldSetConfig: shouldUseConfig } = formValues;
    const dataToSend =
      requestBodyMode === RequestBodyMode.json
        ? JSON.parse(request)
        : !isTTS
        ? JSON.parse(`{"texts": [${JSON.stringify(request)}]}`)
        : JSON.parse(`{"text": ${JSON.stringify(request)}}`);
    return this.predictService.predict(
      accountId,
      modelId,
      dataToSend,
      shouldUseConfig,
      configMode,
      config,
      configId,
      timeoutSec,
      options
    );
  }

  public getSavedFieldsFromLocalStorage(): TestingFormFieldsType | null {
    const storedData = localStorage.getItem(TestingFormService.storageKey);
    if (!storedData) return null;
    try {
      return {
        ...formDefaulValues,
        ...pick(['config', 'request', 'method'], JSON.parse(storedData)),
      };
    } catch (e) {
      localStorage.removeItem(TestingFormService.storageKey);
    }
    return null;
  }

  public saveFieldsLocalStorage(values: TestingFormFieldsType) {
    localStorage.setItem(TestingFormService.storageKey, JSON.stringify(values) || '{}');
  }
}
