import { useEffect } from 'react';

const useScrollToBottom = (
  wrapperRef: React.RefObject<HTMLElement>,
  changesToWatch: any,
  shouldScroll: boolean = true
) => {
  useEffect(() => {
    if (!wrapperRef.current) return;
    if (shouldScroll) {
      wrapperRef.current.scrollTop = wrapperRef.current.scrollHeight;
    }
  }, [changesToWatch, shouldScroll, wrapperRef]);
};

export default useScrollToBottom;
