import React, { useCallback } from 'react';
import IconButton from 'components/IconButton';
import StatusIndicator from 'components/StatusIndicator';
import { StatusType } from 'components/StatusIndicator/types';

import styles from './styles.module.scss';

type CardItemHeadProps = {
  serviceId: string;
  isFavorite: boolean;
  toggleFavorite: () => Promise<void>;
  showFavoriteToggle?: boolean;
  status?: StatusType;
};

const CardItemHead = ({
  serviceId,
  isFavorite,
  toggleFavorite,
  status,
  showFavoriteToggle = false,
}: CardItemHeadProps) => {
  const handleToggle = useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      await toggleFavorite();
    },
    [toggleFavorite]
  );

  return (
    <div className={styles.title}>
      <div className={styles.title__name}>
        <span>{serviceId}</span>
        <StatusIndicator className={styles.title__indicator} status={status} />
      </div>
      {showFavoriteToggle && (
        <IconButton
          className={styles.title__favoriteIconButton}
          data-test-id='TokensPage:Token:Delete'
          name={isFavorite ? 'faStar' : 'farStar'}
          type='button'
          onClick={handleToggle}
        />
      )}
    </div>
  );
};

export default React.memo(CardItemHead);
