import { KeyEvent } from 'keyboardjs';

export const isKeyEvent = (event?: KeyEvent | React.SyntheticEvent): event is KeyEvent => {
  return (event as KeyEvent)?.pressedKeys !== undefined;
};

export function isBlob(value: any): value is Blob {
  return value instanceof Blob;
}

export function isReadableStream(value: any): value is ReadableStream<any> {
  return typeof value === 'object' && 'getReader' in value && typeof value.getReader === 'function';
}
