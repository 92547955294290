import React, { Ref } from 'react';
import { useIMask } from 'react-imask';
import { AnyMaskedOptions } from 'imask';

type WithMaskInputProps = { mask?: AnyMaskedOptions['mask']; maskConfig?: AnyMaskedOptions };

const globalBlocks = {
  '0+': {
    mask: /\d+/,
  },
  'a+': {
    mask: /\w+/,
  },
};

export function withMaskInput<PROPS extends object>(Cmp: React.ComponentType<PROPS>) {
  return React.forwardRef(function (
    { mask, maskConfig, ...props }: PROPS & { ref?: Ref<HTMLInputElement> } & WithMaskInputProps,
    outerRef: Ref<HTMLInputElement>
  ) {
    const { ref } = useIMask<any>({
      mask: mask,
      blocks: globalBlocks,
      ...maskConfig,
    });
    return (
      <Cmp
        ref={(cmpRef: any) => {
          props.ref = cmpRef;
          if (outerRef) {
            // @ts-ignore
            outerRef(cmpRef);
          }
          if (!cmpRef) return;
          const input = cmpRef.getElementsByTagName('INPUT')[0] as HTMLInputElement;
          if (input) ref.current = input;
        }}
        {...(props as PROPS)}
      />
    );
  });
}
