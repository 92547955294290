import React from 'react';

import CardItemHead from './components/CardItemHead';
import CardItemFooter from './components/CardItemFooter';
import TagsChipContainer from 'components/TagsChipContainer';

import { ModelInfoData, TrainingTypeEnum } from 'api/CailagateApi/api/client';
import { useServicesContext } from 'contexts/ServicesContext';
import { useAuthContext } from 'contexts/AuthContext';
import { StateToStatusMap } from 'types/models';

import styles from './styles.module.scss';

interface CardItemComponentInterface {
  service: ModelInfoData;
  toggleFavorite: () => Promise<void>;
}

function CardItemComponent({ service, toggleFavorite }: CardItemComponentInterface) {
  const {
    modelAccountDisplayName,
    fittable,
    languages,
    shortDescription,
    modelName,
    taskType: taskTypeName,
    publicSettings: { availableInJaicp },
    favorite,
    state,
    trainingType,
    billingSettings: { isBillingEnabled },
  } = service;

  const { getTaskTypeData } = useServicesContext();
  const showServiceStatus = !(fittable && trainingType === TrainingTypeEnum.SingleContainer);
  const taskTypeObject = getTaskTypeData(taskTypeName);
  const { user } = useAuthContext();
  const { status } = state && showServiceStatus ? StateToStatusMap[state] : { status: undefined };

  return (
    <div className={styles.catalogItem__container}>
      <CardItemHead
        serviceId={modelName}
        showFavoriteToggle={!!user}
        isFavorite={favorite}
        toggleFavorite={toggleFavorite}
        status={status}
      />
      <div className={styles.catalogItem__text}>
        <p>{shortDescription}</p>
      </div>
      <TagsChipContainer
        taskType={taskTypeObject}
        languages={languages}
        availableInJaicp={availableInJaicp}
        shownLanguagesLimit={2}
      />
      <CardItemFooter owner={modelAccountDisplayName} fittable={fittable} isBillingEnabled={isBillingEnabled} />
    </div>
  );
}
export const CardItem = React.memo(CardItemComponent);
