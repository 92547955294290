import React from 'react';
import Recaptcha from 'react-google-recaptcha';

import { CaptchaContextProviderComponent, useCaptchaContext } from 'HOC/withCaptcha/contexts/CaptchaContext';
import { useAppContext } from 'contexts/AppContext';
import { useAuthContext } from 'contexts/AuthContext';

const CaptchaContainer = ({ children }: { children: React.ReactNode }) => {
  const { language } = useAppContext();
  const { recaptchaInstance, verifyCallback, siteKey } = useCaptchaContext();
  const { user } = useAuthContext();

  if (user || siteKey === undefined) return <>{children}</>;

  return (
    <>
      {children}
      <Recaptcha
        data-test-id='Recaptcha'
        ref={recaptchaInstance}
        sitekey={siteKey}
        className='captcha'
        size='invisible'
        badge='bottomright'
        onChange={verifyCallback}
        hl={language}
      />
    </>
  );
};

const CaptchaContainerComponent = ({ children }: { children: React.ReactNode }) => (
  <CaptchaContextProviderComponent>
    <CaptchaContainer>{children}</CaptchaContainer>
  </CaptchaContextProviderComponent>
);

export default CaptchaContainerComponent;
