import { Service } from 'typedi';

import { BalanceInfoEndpointApi } from 'api/CailaBillingApi/api/client';

@Service()
export class BalanceInfoApiService extends BalanceInfoEndpointApi {
  constructor() {
    super({ baseOptions: { timeout: 30000 } }, '');
  }
}
