import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';

import './App.scss';
import { AppRouter } from './routes';
import { AppContextProviderComponent } from './contexts/AppContext';
import AuthContextProviderComponent from './contexts/AuthContext';

import Header from './modules/Header';
import { ModalsContextProviderComponent } from 'contexts/ModalsContext';
import { ServicesContextProviderComponent } from 'contexts/ServicesContext';
import { BalanceContextProviderComponent } from 'contexts/BalanceContext';

export const history = createBrowserHistory();

export default function App() {
  return (
    <AppContextProviderComponent>
      <AuthContextProviderComponent>
        <BalanceContextProviderComponent>
          <ModalsContextProviderComponent>
            <ServicesContextProviderComponent>
              <Router history={history}>
                <main className='page-container'>
                  <Header />
                  <AppRouter />
                </main>
              </Router>
            </ServicesContextProviderComponent>
          </ModalsContextProviderComponent>
        </BalanceContextProviderComponent>
      </AuthContextProviderComponent>
    </AppContextProviderComponent>
  );
}
