import React, { useEffect, useState } from 'react';
import { FilterManagementInterface } from 'components/Filter/reducer';
import { BaseFilterType } from 'components/Filter/types';
import Filter from 'components/Filter';
import styles from './styles.module.scss';

interface ModalFiltersContainerInterface<FILTER_OPTIONS extends string>
  extends FilterManagementInterface<FILTER_OPTIONS> {
  filterData: BaseFilterType<FILTER_OPTIONS>[];
  options: {
    label: string;
    value: FILTER_OPTIONS;
  }[];
  filterComponents: Record<FILTER_OPTIONS, React.FC<any>>;
  labelPrefix?: string;
  isInitialized?: boolean;
}

const ModalFiltersContainer = <FILTER_OPTIONS extends string>({
  filterData,
  addNewFilter,
  deleteFilter,
  updateFilter,
  options,
  filterComponents,
  labelPrefix,
  isInitialized: isInitializedParent = true,
}: ModalFiltersContainerInterface<FILTER_OPTIONS>) => {
  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    if (isInitializedParent) {
      window.requestAnimationFrame(() => {
        setIsInitialized(true);
      });
    }
  }, [isInitializedParent]);

  return (
    <div className={styles.filtersContainer}>
      {filterData?.map((filter, index) => (
        <Filter
          filter={filter}
          key={index}
          options={options}
          onChange={(value, label) => updateFilter({ key: filter.key, value, label })}
          onChoose={key => addNewFilter({ key })}
          onDelete={deleteFilter}
          filterComponents={filterComponents}
          labelPrefix={labelPrefix}
          stayClosed={!isInitialized}
        />
      ))}
      <Filter
        options={options}
        key={filterData?.length + 1}
        onChoose={key => addNewFilter({ key })}
        hidden={options.length < 1}
        filterComponents={filterComponents}
        labelPrefix={labelPrefix}
        stayClosed={!isInitialized}
      />
    </div>
  );
};

export default React.memo(ModalFiltersContainer) as typeof ModalFiltersContainer;
