import React from 'react';

import { t } from 'localization';
import styles from './styles.module.scss';

const TesterHeader = () => {
  return (
    <div className={styles.testerHeader}>
      <h3>{t('ServicePage:Tester:TestService')}</h3>
    </div>
  );
};

export default React.memo(TesterHeader);
