import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useToggle } from '@just-ai/just-ui';

import IconButton from 'components/IconButton';
import IconSelectList from './components/IconSelectList';

import { SelectOption } from './types';
import { debounce } from 'lodash';
import cn from 'classnames';

import styles from './styles.module.scss';

export type ListPositionsType = 'absolute' | 'fixed';

interface IconSelectInterface {
  value?: string;
  options: SelectOption[];
  onChange: (newSelectedValue: string) => void;
  className?: string;
  backdropClassName?: string;
  listClassName?: string;
  position?: ListPositionsType;
}

const IconSelect = ({
  value,
  options,
  backdropClassName,
  listClassName,
  onChange,
  className,
  position = 'absolute',
}: IconSelectInterface) => {
  const [isSelectListOpened, open, close] = useToggle();

  const containerRef = useRef<HTMLDivElement>(null);
  const [listPosition, setListPosition] = useState<CSSProperties | undefined>();
  const updatelistPositionRef = useRef<ReturnType<typeof debounce>>();

  const handleSelect = useCallback(
    (newSelectedOption: SelectOption) => {
      onChange(newSelectedOption.value);
      close();
    },
    [close, onChange]
  );

  const updateListPosition = useCallback(() => {
    if (!containerRef.current) return;
    const rect = containerRef.current.getBoundingClientRect();
    setListPosition({
      position: 'fixed',
      top: rect.top + rect.height,
      width: rect.width,
      left: rect.left,
    });
  }, []);

  useEffect(() => {
    if (position === 'absolute') {
      setListPosition({ position: 'absolute' });
      return;
    }
    updatelistPositionRef.current = debounce(() => {
      updateListPosition();
    }, 200);

    const resizeObserver = new ResizeObserver(() => {
      updatelistPositionRef?.current && updatelistPositionRef.current();
    });
    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.unobserve(document.body);
    };
  }, [position, updateListPosition]);

  const handleOpen = useCallback(() => {
    updateListPosition();
    open();
  }, [open, updateListPosition]);

  const selected = value !== undefined ? options.find(option => option.value === value)?.label : undefined;
  if (selected === undefined) return null;
  return (
    <div className={styles.iconSelect__container} ref={containerRef}>
      <IconButton className={className} color='ghost' outline name={selected} type='button' onClick={handleOpen} />
      <IconSelectList
        isOpen={isSelectListOpened}
        options={options}
        handleSelect={handleSelect}
        listPositionStyle={listPosition}
        className={listClassName}
        position={position}
      />
      {isSelectListOpened &&
        ReactDOM.createPortal(
          <div className={cn(styles.iconSelect__backdrop, backdropClassName)} onClick={close} />,
          document.body
        )}
    </div>
  );
};

export default React.memo(IconSelect);
