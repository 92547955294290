import { Inject, Service } from 'typedi';

import { ProcessApiService } from 'services/ApiServices/ProcessApiService';

const DEFAULT_PREDICT_TIMEOUT = 30000;
const PREDICT_TIMEOUT_MARGIN = 10000;

export enum ConfigDefinitionMode {
  selectExistingConfig = 'selectExistingConfig',
  writeConfig = 'writeConfig',
}

@Service()
export class PredictService {
  @Inject(() => ProcessApiService) processApi!: ProcessApiService;

  public async predict(
    accountId: number,
    modelId: number,
    dataToSend: any,
    shouldUseConfig: boolean,
    configMode: ConfigDefinitionMode,
    config: string,
    configId?: string,
    timeoutSec?: number,
    options?: any
  ) {
    const timeout = (timeoutSec ? timeoutSec * 1000 : DEFAULT_PREDICT_TIMEOUT) + PREDICT_TIMEOUT_MARGIN;
    if (!shouldUseConfig) {
      return this.processApi.predict(accountId.toString(), modelId.toString(), dataToSend, undefined, undefined, {
        timeout,
        ...options,
      });
    }
    if (configMode === ConfigDefinitionMode.selectExistingConfig) {
      const configIdToSend = configId !== undefined ? parseInt(configId) : undefined;
      return this.processApi.predict(accountId.toString(), modelId.toString(), dataToSend, configIdToSend, undefined, {
        timeout,
        ...options,
      });
    } else {
      const parsedConfig = this.parseFormConfig(config);
      return this.processApi.predictWithConfig(
        accountId.toString(),
        modelId.toString(),
        { config: parsedConfig, data: dataToSend },
        undefined,
        {
          timeout,
          ...options,
        }
      );
    }
  }

  private parseFormConfig(config: string) {
    let trimmedConfig = config.trim();
    return trimmedConfig ? JSON.parse(trimmedConfig) : null;
  }
}
