import React, { useCallback } from 'react';
import { AudioPlayer } from '@just-ai/just-ui';

import Divider from 'modules/Account/components/Divider';
import IconButton from 'components/IconButton';
import PredictResult from '../../../PredictResult';

import { downloadFileFromUrl } from 'utils';
import { t } from 'localization';

import { useTestWidgetContext } from 'modules/ServiceTestComponents/contexts/TestWidgetContext';

import styles from './styles.module.scss';

interface TesterResponseInterface {
  result?: string;
  withError?: boolean;
  isTTS?: boolean;
}

const TesterResponse = ({ result, withError, isTTS = false }: TesterResponseInterface) => {
  const { serviceData } = useTestWidgetContext();
  const hasAudioResult = isTTS && result && !withError;
  const handleDownloadAudio = useCallback(() => {
    if (hasAudioResult) {
      downloadFileFromUrl(result, `${serviceData.modelName}.wav`);
    }
  }, [hasAudioResult, result, serviceData.modelName]);

  if (hasAudioResult) {
    return (
      <>
        <Divider />
        <div className={styles.testerResponse__responseHead}>
          <h3>{t('ServicePage:TestingForm:Result')}</h3>
        </div>
        <div className={styles.testerResponse__audio} data-test-id='Tester:PredictResultAudio'>
          <AudioPlayer
            url={result}
            autoPlay
            dataTestId={{
              menuButton: 'Tester:PredictResultAudio:Menu',
              playButton: 'Tester:PredictResultAudio:Play',
              volumeButton: 'Tester:PredictResultAudio:Volume',
            }}
          />
          <IconButton
            className={styles.testerResponse__downloadButton}
            name='farArrowToBottom'
            onClick={handleDownloadAudio}
          />
        </div>
      </>
    );
  }
  return <PredictResult result={result} withError={withError} />;
};

export default React.memo(TesterResponse);
