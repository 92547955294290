import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import ResizableTextArea from 'components/ResizableTextArea';
import SimpleRadioButtonsGroup from 'components/SimpleRadioButtonsGroup';

import { useTestingFormContext } from '../..';
import { EditRequestFieldsNames, RequestBodyMode } from '../../types';

import { t } from 'localization';
import styles from './styles.module.scss';

interface EditRequestBodyInterface {
  setIsEditing?: (isEditing: boolean) => void;
  forceRequestBodyMode?: RequestBodyMode;
  editRequestBodyTitle?: string;
  hideRequestBodyInput?: boolean;
  isTTS?: boolean;
}

const EditRequestBody = ({
  setIsEditing,
  forceRequestBodyMode,
  editRequestBodyTitle,
  hideRequestBodyInput = false,
  isTTS = false,
}: EditRequestBodyInterface) => {
  const { control, watch, register, setValue } = useTestingFormContext();
  const handleOnFocus = useCallback(() => {
    setIsEditing && setIsEditing(true);
  }, [setIsEditing]);

  const handleOnBlur = useCallback(() => {
    setIsEditing && setIsEditing(false);
  }, [setIsEditing]);

  const requestBodyModeOptions = useMemo(
    () => [
      {
        label: t(`ServicePage:TestingForm:${RequestBodyMode.text}`),
        name: RequestBodyMode.text,
        value: RequestBodyMode.text,
        disabled: forceRequestBodyMode && forceRequestBodyMode !== RequestBodyMode.text,
      },
      {
        label: t(`ServicePage:TestingForm:${RequestBodyMode.json}`),
        name: RequestBodyMode.json,
        value: RequestBodyMode.json,
        disabled: forceRequestBodyMode && forceRequestBodyMode !== RequestBodyMode.json,
      },
    ],
    [forceRequestBodyMode]
  );

  useEffect(() => {
    if (isTTS) {
      setValue(EditRequestFieldsNames.requestBodyMode, RequestBodyMode.text);
    } else if (!!forceRequestBodyMode) {
      setValue(EditRequestFieldsNames.requestBodyMode, forceRequestBodyMode);
    }
  }, [forceRequestBodyMode, isTTS, setValue]);

  return (
    <>
      <div className={styles.editRequestBody}>
        {editRequestBodyTitle && <h3>{editRequestBodyTitle}</h3>}
        <div>
          <input type='hidden' {...register('requestBodyMode')} />
          {!isTTS && (
            <Controller
              name='requestBodyMode'
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <SimpleRadioButtonsGroup
                  value={value}
                  onChange={onChange}
                  containerClassName={styles.editRequestBody__radioButtons}
                  list={requestBodyModeOptions}
                  dataTestIdPrefix='RequestBodyMode:'
                />
              )}
            />
          )}
        </div>
        {!hideRequestBodyInput && (
          <Controller
            name='request'
            control={control}
            rules={{ required: false }}
            render={({ field: { value, onChange, name }, fieldState: { invalid, error } }) => (
              <ResizableTextArea
                name={name}
                value={value}
                onChange={onChange}
                placeholder={t('Model:TestingForm:RequestBody')}
                className={styles.editRequestBody__textarea}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                errorText={invalid ? error?.message : ''}
                hasError={invalid}
              />
            )}
          />
        )}
      </div>
    </>
  );
};

export default React.memo(EditRequestBody);
