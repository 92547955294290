import { BaseFilterType } from 'components/Filter/types';
import { TaskTypeData } from 'api/CailagateApi/api/client';
import { FilterOptions } from '../components/FiltersContainer/types';

export const initializeCatalogFilter = (
  { name, page, ...initFilterValues }: Partial<Record<FilterOptions | 'name' | 'page', string>>,
  taskTypes?: TaskTypeData[]
) => {
  const newFilterData = Object.entries(initFilterValues).reduce((filterData, [option, value]) => {
    const key = option as FilterOptions;
    switch (key) {
      case FilterOptions.TaskType: {
        const taskType = taskTypes?.find(taskType => taskType.name === value);
        return taskType ? [...filterData, { key, value, label: taskType.displayName }] : filterData;
      }
      default: {
        return [...filterData, { key, value }];
      }
    }
  }, [] as BaseFilterType<FilterOptions>[]);
  return newFilterData;
};
