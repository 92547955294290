import { useMemo } from 'react';
import { FormProvider, useFormContext } from 'react-hook-form';

import AudioEdit from './components/AudioEdit';
import FormContainer from 'modules/Account/components/FormLayout/FormContainer';
import ConfigurationSelect from '../../../Forms/components/ConfigurationSelect';

import { usePredictConfigs } from 'services/PredictService/hook';
import { useDefaultPredictConfig } from '../../../Forms/components/ConfigurationSelect/hooks';

import { ModelInfoData, PredictConfigData } from 'api/CailagateApi/api/client';
import {
  ASRTestingFormFieldsType,
  ASRTestingFormMethodsType,
  AudioCreationMode,
  AudioEditFieldsNames,
  formDefaulValues,
} from './types';

import { getConfigsSelectList } from 'services/PredictService/utils';

import styles from './styles.module.scss';
import { useDefaultForm } from 'utils/form/hooks';

interface ASRTestingFormInterface {
  formMethods: ASRTestingFormMethodsType;
  predictConfigs?: PredictConfigData[];
  setIsEditing?: (isEditing: boolean) => void;
  configWithSpoiler?: boolean;
  developerMode?: boolean;
}

export const useASRTestingForm = (
  serviceData: ModelInfoData,
  preservedFormValues?: ASRTestingFormFieldsType
): [ASRTestingFormMethodsType, boolean, PredictConfigData[] | undefined, boolean] => {
  const { commonError, config, ...inputDefaultValues } = preservedFormValues || formDefaulValues;
  const formMethods = useDefaultForm<ASRTestingFormFieldsType>({ ...formDefaulValues, ...inputDefaultValues });

  const [predictConfigs, isLoading] = usePredictConfigs(serviceData);
  useDefaultPredictConfig(formMethods.resetField, predictConfigs, preservedFormValues?.configId);

  const watchAudio = formMethods.watch(AudioEditFieldsNames.audio);
  const watchAudioFile = formMethods.watch(AudioEditFieldsNames.audioFile);
  const watchRequestJson = formMethods.watch(AudioEditFieldsNames.requestJson);
  const watchAudioCreationMode = formMethods.watch(AudioEditFieldsNames.audioCreationMode);
  const hasDataToSubmit =
    (watchAudioCreationMode === AudioCreationMode.upload && !!watchAudioFile) ||
    (watchAudioCreationMode === AudioCreationMode.record && !!watchAudio) ||
    (watchAudioCreationMode === AudioCreationMode.json && !!watchRequestJson);

  return [formMethods, isLoading, predictConfigs, hasDataToSubmit];
};

export const useASRTestingFormContext = () => useFormContext<ASRTestingFormFieldsType>();

export default function ASRTestingForm({
  formMethods,
  predictConfigs,
  setIsEditing,
  configWithSpoiler,
  developerMode,
}: ASRTestingFormInterface) {
  const configList = useMemo(() => getConfigsSelectList(predictConfigs), [predictConfigs]);

  return (
    <FormProvider {...formMethods}>
      <FormContainer
        className={styles.testingForm}
        showError={formMethods.formState.errors?.commonError}
        errorMessage={formMethods.formState.errors.commonError?.message}
      >
        <ConfigurationSelect setIsEditing={setIsEditing} configList={configList} withSpoiler={configWithSpoiler} />
        <AudioEdit developerMode={developerMode} />
      </FormContainer>
    </FormProvider>
  );
}
