import React, { Ref } from 'react';

import { useDisableBodyScroll, useToggle } from 'utils/hooks';

import WidgetLayout from './components/WidgetLayout';
import RelativeWidgetLayout from './components/RelativeWidgetLayout';

export interface WidgetComponentProps {
  isFullScreen: boolean;
  toggleFullScreen: () => void;
}
export interface WidgetConfigProps {
  isRelative?: boolean;
}

const toggleStub = () => {};

export function withWidgetLayout<PROPS extends object>(Cmp: React.ComponentType<PROPS & WidgetComponentProps>) {
  return React.forwardRef(function (props: PROPS & WidgetConfigProps, ref: Ref<React.ComponentType<PROPS>>) {
    const [isFullScreen, , , toggleFullScreen] = useToggle(false);
    useDisableBodyScroll(isFullScreen && !props.isRelative);
    if (props.isRelative) {
      return (
        <RelativeWidgetLayout>
          <Cmp {...props} ref={ref} isFullScreen={true} toggleFullScreen={toggleStub} />
        </RelativeWidgetLayout>
      );
    }
    return (
      <WidgetLayout isFullScreen={isFullScreen} toggleFullScreen={toggleFullScreen}>
        <Cmp {...props} ref={ref} isFullScreen={isFullScreen} toggleFullScreen={toggleFullScreen} />
      </WidgetLayout>
    );
  });
}
