import { UserRoles } from 'contexts/AuthContext/types';
import { ComponentType, useMemo } from 'react';
import { Route, RouteProps, Redirect } from 'react-router';
import { useAuthContext } from '../../contexts/AuthContext';

interface EnhancedRouteInterface extends RouteProps {
  layout?: ComponentType<any>;
  isPrivate?: boolean;
  component: ComponentType<RouteProps>;
  accesFor?: UserRoles[];
  [key: string]: any;
}

const EnhancedRoute = ({
  component: Component,
  layout: Layout,
  isPrivate,
  accesFor,
  ...rest
}: EnhancedRouteInterface) => {
  const { isAuthenticated, userRole: currentUserRole } = useAuthContext();
  const userHasAccess = useMemo(
    () => !(isPrivate && !isAuthenticated) && (!accesFor || accesFor.some(userRole => userRole === currentUserRole)),
    [accesFor, currentUserRole, isAuthenticated, isPrivate]
  );
  return (
    <Route
      {...rest}
      render={props => {
        if (!userHasAccess) {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          );
        } else {
          return Layout ? (
            <Layout>
              <Component {...props} />
            </Layout>
          ) : (
            <Component {...props} />
          );
        }
      }}
    />
  );
};

export default EnhancedRoute;
