import React from 'react';
import cn from 'classnames';

import Divider from 'modules/Account/components/Divider';
import Button from 'components/Button';

import { t } from 'localization';
import styles from './styles.module.scss';

interface TrainingResultInterface {
  redirect: string;
  reset: () => void;
  fitIsRequested?: boolean;
  isFullScreen?: boolean;
}
const dummyFunction = () => {};

const TrainingResult = ({ redirect, reset, fitIsRequested = false, isFullScreen = false }: TrainingResultInterface) => {
  if (!fitIsRequested) return null;

  return (
    <div className={styles.trainingResult__wrapper}>
      <Divider />
      <div className={styles.trainingResult}>
        <h3>{t('TrainingWidget:Result')}</h3>
        <span>{t('TrainingWidget:DerivedServiceWasCreated')}</span>
        <a href={redirect} target='_blank' rel='noreferrer'>
          <Button
            color='primary'
            className={styles[`trainingResult__button${isFullScreen ? '-fullScreen' : ''}`]}
            data-test-id='trainingWidgetRedirectToNewService'
            onClick={dummyFunction}
          >
            {t('TrainingWidget:NewServiceRedirect')}
          </Button>
        </a>
        <Button
          color='ghost'
          className={cn(
            styles[`trainingResult__button${isFullScreen ? '-fullScreen' : ''}`],
            styles.trainingResult__button_reset
          )}
          onClick={reset}
          data-test-id='trainingWidgetReset'
        >
          {t('TrainingWidget:FitWithAnotherDataset')}
        </Button>
      </div>
    </div>
  );
};

export default React.memo(TrainingResult);
