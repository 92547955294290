import { v4 as uuidv4 } from 'uuid';

const h1Regex = /^\s{0,3}#\s+(.+?)\s*$/gm;
const h2Regex = /^\s{0,3}##\s+(.+?)\s*$/gm;

export const getH1Headings = (markdownText: string = '') => {
  const matches: {
    text: string;
    link: string;
  }[] = [];
  let match;
  while ((match = h1Regex.exec(markdownText)) !== null) {
    match[1] && matches.push({ text: match[1], link: uuidv4() });
  }

  return matches;
};

export const getH2Headings = (markdownText: string = '') => {
  const matches: {
    text: string;
    link: string;
  }[] = [];
  let match;
  while ((match = h2Regex.exec(markdownText)) !== null) {
    match[1] && matches.push({ text: match[1], link: uuidv4() });
  }

  return matches;
};
