import { FunctionComponent } from 'react';
import WSContextProviderComponent from 'contexts/WSContext';
import NotificationContextProviderComponent from 'contexts/NotificationContext';
import { useAuthContext } from 'contexts/AuthContext';

interface PrivateContextsProps {}

export const PrivateContexts: FunctionComponent<PrivateContextsProps> = ({ children }) => {
  const { user } = useAuthContext();
  return (
    <WSContextProviderComponent>
      <NotificationContextProviderComponent user={user}>{children}</NotificationContextProviderComponent>
    </WSContextProviderComponent>
  );
};
