import React from 'react';
import styles from './styles.module.scss';
import cn from 'classnames';
interface DividerInterface {
  margin?: string | number;
  noMargin?: boolean;
  className?: string;
}

const Divider = ({ margin, noMargin, className }: DividerInterface) => {
  const marginToSet = noMargin ? 0 : margin;
  return <hr className={cn(styles.divider, className)} style={{ margin: marginToSet }} />;
};

export default React.memo(Divider);
