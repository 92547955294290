import { UseFormReturn } from 'react-hook-form';

export type AccessRequestFormMethodsType = UseFormReturn<AccessRequestFormFieldsType, any>;

export enum AccessRequestFormFieldsNames {
  contacts = 'contacts',
  comment = 'comment',
}

export const AccessRequestFormFieldsNamesList = Object.values(AccessRequestFormFieldsNames);

export type AccessRequestFormFieldsType = {
  [AccessRequestFormFieldsNames.contacts]: string;
  [AccessRequestFormFieldsNames.comment]: string;
  commonError: any;
};
export const defaultFormValues: AccessRequestFormFieldsType = {
  [AccessRequestFormFieldsNames.contacts]: '',
  [AccessRequestFormFieldsNames.comment]: '',
  commonError: undefined,
};
