import React from 'react';

import { t } from 'localization';
import styles from './styles.module.scss';

const RecordTestWidgetHeader = () => {
  return (
    <div className={styles.recordTestWidgetHeader}>
      <div className={styles.recordTestWidgetHeader__row}>
        <h3>{t('RecordTestWidget:Header:Title')}</h3>
      </div>
    </div>
  );
};

export default React.memo(RecordTestWidgetHeader);
