import React from 'react';
import cn from 'classnames';

import Button, { ButtonInterface } from 'components/Button';

import './style.scss';

const HeaderButton = function ({ children, className, ...props }: ButtonInterface) {
  return (
    <Button {...props} className={cn(className, 'header-button')}>
      {children}
    </Button>
  );
};

export default React.memo(HeaderButton);
