import { ConfigDefinitionMode } from 'services/PredictService';
import { ConfigSelectFieldsType } from '../../../Forms/components/ConfigurationSelect/types';
import { UseFormReturn } from 'react-hook-form';
export { ConfigDefinitionMode };

export enum AudioEditFieldsNames {
  audio = 'audio',
  audioFile = 'audioFile',
  requestJson = 'requestJson',
  audioCreationMode = 'audioCreationMode',
}

export enum AudioCreationMode {
  upload = 'upload',
  record = 'record',
  json = 'json',
}

export type AudioEditFieldsType = {
  [AudioEditFieldsNames.audio]?: Blob;
  [AudioEditFieldsNames.audioFile]?: File;
  [AudioEditFieldsNames.requestJson]: string;
  [AudioEditFieldsNames.audioCreationMode]: AudioCreationMode;
};

export type ASRTestingFormFieldsType = ConfigSelectFieldsType &
  AudioEditFieldsType & {
    commonError: any;
  };

export const formDefaulValues: ASRTestingFormFieldsType = {
  shouldSetConfig: false,
  configId: undefined,
  config: '',
  configMode: ConfigDefinitionMode.writeConfig,
  audioCreationMode: AudioCreationMode.upload,
  audio: undefined,
  audioFile: undefined,
  requestJson: '',
  commonError: undefined,
};

export type ASRTestingFormMethodsType = UseFormReturn<ASRTestingFormFieldsType, any>;
