import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import Search, { SearchInterface } from 'components/Search';

import { useToggle } from 'utils/hooks';
import useKeyboardAction from 'utils/hooks/useKeyboardAction';

import styles from './styles.module.scss';

interface SearchFilterInterface extends Omit<SearchInterface, 'value' | 'onChange' | 'onFocus' | 'onBlur'> {
  value?: string;
  setValue: (newValue: string) => void;
}

const SearchFilter = ({
  value: filterValue,
  setValue: setFilterValue,
  className,
  skipSearchIcon = true,
  ...props
}: SearchFilterInterface) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [isFocused, setFocus, setBlur] = useToggle(false);
  useKeyboardAction({ enable: isFocused, hotKeys: ['enter'], action: () => setFilterValue(searchValue) });

  useEffect(() => {
    setSearchValue(filterValue || '');
  }, [filterValue]);

  return (
    <Search
      {...props}
      className={cn(styles.search, className)}
      value={searchValue}
      onChange={setSearchValue}
      onFocus={() => setFocus()}
      onBlur={() => {
        setBlur();
        setFilterValue(searchValue);
      }}
      skipSearchIcon={skipSearchIcon}
    />
  );
};

export default React.memo(SearchFilter);
