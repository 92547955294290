import { Service } from 'typedi';

import { BaseAbstractStorage, StorageResponse } from 'services/Storage/BaseAbstractStorage';

@Service()
export class LocalStorageService extends BaseAbstractStorage {
  set(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value) || '');
    return Promise.resolve(this.createSuccess(null));
  }

  get<ELEMENT>(key: string) {
    return new Promise<StorageResponse<ELEMENT | null>>((resolve, reject) => {
      const storedData = localStorage.getItem(key);
      if (!storedData) return resolve(this.createSuccess(null));
      try {
        resolve(this.createSuccess(JSON.parse(storedData) as ELEMENT));
      } catch (e) {
        if (e instanceof Error) {
          reject(this.createError(`Error in LocalStorageService! => ${e.message}`));
        }
        reject(this.createError(`Error in LocalStorageService!`));
      }
    });
  }
}
