import { FieldError } from 'react-hook-form/dist/types/errors';
import { ConfigDefinitionMode } from 'services/PredictService';
import { ConfigSelectFieldsNames, ConfigSelectFieldsType } from '../Forms/components/ConfigurationSelect/types';
export { ConfigDefinitionMode };

export enum EditRequestFieldsNames {
  request = 'request',
  requestBodyMode = 'requestBodyMode',
}

export enum RequestBodyMode {
  text = 'text',
  json = 'json',
}

export const formDefaulValues: TestingFormFieldsType = {
  shouldSetConfig: false,
  configId: undefined,
  config: '',
  configMode: ConfigDefinitionMode.selectExistingConfig,
  requestBodyMode: RequestBodyMode.text,
  request: '',
  commonError: undefined,
};

export const TestingFormFieldsNamesList = [
  ...Object.values(EditRequestFieldsNames),
  ...Object.values(ConfigSelectFieldsNames),
  `commonError`,
] as (EditRequestFieldsNames | ConfigSelectFieldsNames | `commonError.${string}`)[];

export type ConfigListItemType = {
  label: string;
  value: string;
};

export type EditRequestFieldsType = {
  [EditRequestFieldsNames.request]: string;
  [EditRequestFieldsNames.requestBodyMode]: RequestBodyMode;
};

export type TestingFormFieldsType = ConfigSelectFieldsType &
  EditRequestFieldsType & {
    commonError: any;
  };

export type TestingFormErrorsType = {
  commonError?: any;
  config?: FieldError | undefined;
  request?: FieldError | undefined;
};
