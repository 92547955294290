export const getStartTimeStatus = (startTimeSeconds?: number): [string | undefined, number | undefined] => {
  if (startTimeSeconds === undefined) return [undefined, undefined];
  switch (true) {
    case startTimeSeconds < 60: {
      return ['Catalog:ServicePage:StartTimeInSeconds', startTimeSeconds];
    }
    case startTimeSeconds >= 60 && startTimeSeconds < 3600: {
      return ['Catalog:ServicePage:StartTimeInMinutes', startTimeSeconds / 60];
    }
    case startTimeSeconds >= 3600: {
      return ['Catalog:ServicePage:StartTimeInHours', startTimeSeconds / 3600];
    }
    default:
      return ['Catalog:ServicePage:StartTimeInSeconds', startTimeSeconds];
  }
};
