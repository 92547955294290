import { useMemo } from 'react';
import { FormProvider, useFormContext, UseFormReturn } from 'react-hook-form';

import FormContainer from 'modules/Account/components/FormLayout/FormContainer';
import ConfigurationSelect from '../Forms/components/ConfigurationSelect';
import EditRequestBody from './components/EditRequestBody';

import { formDefaulValues, RequestBodyMode, TestingFormErrorsType, TestingFormFieldsType } from './types';

import { ModelInfoData, PredictConfigData } from 'api/CailagateApi/api/client';
import { getConfigsSelectList } from 'services/PredictService/utils';

import { usePredictConfigs } from 'services/PredictService/hook';
import { useDefaultPredictConfig } from '../Forms/components/ConfigurationSelect/hooks';
import { useDefaultForm } from 'utils/form/hooks';

import styles from './styles.module.scss';

export type TestingFormMethodsType = UseFormReturn<TestingFormFieldsType, any>;

interface TestingFormInterface {
  formMethods: TestingFormMethodsType;
  errors: TestingFormErrorsType;
  predictConfigs?: PredictConfigData[];
  setIsEditing?: (isEditing: boolean) => void;
  forceRequestBodyMode?: RequestBodyMode;
  onlyConfig?: boolean;
  configWithSpoiler?: boolean;
  editRequestBodyTitle?: string;
  hideRequestBodyInput?: boolean;
  classNameConfigSelect?: string;
  isTTS?: boolean;
}

export const useTestingForm = (
  serviceData: ModelInfoData,
  preservedFormValues?: TestingFormFieldsType,
  defaultRequestBodyMode: RequestBodyMode = formDefaulValues.requestBodyMode
): [TestingFormMethodsType, boolean, PredictConfigData[] | undefined] => {
  const { commonError, config, ...inputDefaultValues } = preservedFormValues || {
    ...formDefaulValues,
    requestBodyMode: defaultRequestBodyMode,
  };

  const formMethods = useDefaultForm<TestingFormFieldsType>({ ...formDefaulValues, ...inputDefaultValues });

  const [predictConfigs, isLoading] = usePredictConfigs(serviceData);
  useDefaultPredictConfig(formMethods.resetField, predictConfigs, preservedFormValues?.configId);

  return [formMethods, isLoading, predictConfigs];
};

export const useTestingFormContext = () => useFormContext<TestingFormFieldsType>();

export default function TestingForm({
  formMethods,
  predictConfigs,
  errors,
  setIsEditing,
  forceRequestBodyMode,
  onlyConfig,
  configWithSpoiler,
  editRequestBodyTitle,
  hideRequestBodyInput,
  classNameConfigSelect,
  isTTS,
}: TestingFormInterface) {
  const configList = useMemo(() => getConfigsSelectList(predictConfigs), [predictConfigs]);

  return (
    <FormProvider {...formMethods}>
      <FormContainer
        className={styles.testingForm}
        showError={errors?.commonError}
        errorMessage={errors.commonError?.message}
      >
        <ConfigurationSelect
          setIsEditing={setIsEditing}
          configList={configList}
          withSpoiler={configWithSpoiler}
          className={classNameConfigSelect}
        />
        {!onlyConfig && (
          <EditRequestBody
            editRequestBodyTitle={editRequestBodyTitle}
            setIsEditing={setIsEditing}
            forceRequestBodyMode={forceRequestBodyMode}
            hideRequestBodyInput={hideRequestBodyInput}
            isTTS={isTTS}
          />
        )}
      </FormContainer>
    </FormProvider>
  );
}
