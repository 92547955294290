import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { getQueryMap, saveDataAsQuery } from './utils';
import { usePrevious } from '@just-ai/just-ui/dist/utils';
import equals from 'ramda/src/equals';
import { QueryStorageKeeper } from './QueryStorageKeeper';

export function useQuery<QUERY_NAMES extends string>(queryNames: QUERY_NAMES[] = []) {
  const { search } = useLocation();
  return useMemo(() => getQueryMap(search, queryNames), [queryNames, search]);
}

const isQueryEmpty = <VALUE_TYPE extends string>(queryData: Record<VALUE_TYPE, string>) => {
  return !Object.keys(queryData).length;
};

export const useQueryState = <VALUE_TYPE extends string>(
  values: Record<VALUE_TYPE, string>,
  queryParams: VALUE_TYPE[],
  initialize?: (initValue: Partial<Record<VALUE_TYPE, string>>) => void,
  storageKey?: string
) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const queryData = useQuery(queryParams);
  const prevValues = usePrevious(values);

  const storageKeeper = useMemo(() => (storageKey ? new QueryStorageKeeper(storageKey) : undefined), [storageKey]);

  const handleInit = useCallback(async () => {
    if (isInitialized) return;
    if (!initialize) {
      setIsInitialized(true);
      return;
    }
    if (isQueryEmpty(queryData) && storageKeeper) {
      const preservedData = await storageKeeper.get();
      if (!preservedData) {
        initialize({});
        setIsInitialized(true);
        return;
      }
      initialize(preservedData);
      saveDataAsQuery(preservedData);
    } else {
      initialize(queryData);
    }
    setIsInitialized(true);
  }, [initialize, isInitialized, queryData, storageKeeper]);

  useEffect(() => {
    handleInit();
  }, [handleInit]);

  useEffect(() => {
    if (!isInitialized) return;
    if (!equals(values, prevValues)) {
      saveDataAsQuery(values);
    }
  }, [isInitialized, prevValues, values]);

  useEffect(() => {
    if (!isInitialized) return;
    storageKeeper && storageKeeper.set(queryData);
  }, [isInitialized, queryData, storageKeeper]);

  return isInitialized;
};
