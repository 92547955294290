import { history } from 'App';
import { isNullOrUndefined } from 'utils';

export const getQueryMap = <QUERY_NAMES extends string>(search: string, queryNames: QUERY_NAMES[] = []) => {
  const query = new URLSearchParams(search);
  return queryNames.reduce((queries, name) => {
    const currentQuery = query.get(name) || undefined;
    if (!currentQuery) {
      return queries;
    } else {
      queries[name] = currentQuery;
      return queries;
    }
  }, {} as Record<QUERY_NAMES, string>);
};

export const saveDataAsQuery = <QueryNames extends string>(dataToSave: Record<QueryNames, string>) => {
  let searchParams = new URLSearchParams('');
  const entries = Object.entries(dataToSave);
  if (!!entries.length) {
    entries.forEach(([key, value]) => {
      if (!isNullOrUndefined(value)) {
        searchParams.set(key as QueryNames, value as string);
      } else {
        searchParams.delete(key);
      }
    });
  }
  history.push({ search: searchParams.toString() });
};
