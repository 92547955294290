import React, { CSSProperties, Ref, useCallback } from 'react';

import cn from 'classnames';
import './style.scss';

export interface ResizableTextAreaInterface
  extends Omit<
    React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
    'onChange' | 'ref'
  > {
  value: string;
  onChange: (text: string) => void;
  title?: string;
  placeholder?: string;
  className?: string;
  errorText?: string;
  hasError?: boolean;
  maxHeight?: number;
  hidden?: boolean;
}
const ResizableTextArea = function (
  {
    title,
    value,
    onChange,
    placeholder,
    className,
    errorText,
    maxHeight,
    hasError = false,
    hidden = false,
    ...props
  }: ResizableTextAreaInterface,
  ref: Ref<HTMLTextAreaElement>
) {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      event.preventDefault();
      onChange(event.target.value);
    },
    [onChange]
  );
  const labelStyle: CSSProperties | undefined = maxHeight !== undefined ? { maxHeight: `${maxHeight}px` } : undefined;

  return (
    <>
      <label
        data-test-id={'textarea' + String(props.name).charAt(0).toUpperCase() + String(props.name).slice(1)}
        className={cn('cmp-textarea--autogrow', className, { 'cmp-textarea--error': hasError })}
        data-replicated-value={value}
        style={labelStyle}
        hidden={hidden}
      >
        {title}
        <textarea
          {...props}
          hidden={hidden}
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
          ref={ref}
        />
      </label>
      {errorText && (
        <span className='cmp-textarea__error-box' hidden={hidden}>
          {errorText}
        </span>
      )}
    </>
  );
};

export default React.memo(React.forwardRef(ResizableTextArea));
