import { Controller, UseFormReturn } from 'react-hook-form';

import ControlledInput from 'components/ControlledInput';
import FormContainer from 'modules/Account/components/FormLayout/FormContainer';

import { BalanceRefillFormFieldsType, BalanceRefillFormMethodsType, defaultFormValues } from './types';

import { t } from 'localization';
import SimpleRadioButtonsGroup from 'components/SimpleRadioButtonsGroup';
import { useEffect, useMemo } from 'react';
import ControlledSelect from 'components/ControlledSelect';
import { useBalanceContext } from 'contexts/BalanceContext';
import { useDefaultForm } from 'utils/form/hooks';

import styles from './styles.module.scss';
import { useAuthContext } from 'contexts/AuthContext';
import { FEATURES } from 'contexts/AuthContext/constants';

interface BalanceRefillFormInterface {
  formMethods: BalanceRefillFormMethodsType;
}

export const useBalanceRefillForm = (formDataDeps: any[] = []): UseFormReturn<BalanceRefillFormFieldsType, any> => {
  const formMethods = useDefaultForm<BalanceRefillFormFieldsType>(defaultFormValues);
  const { refillFromCard, refillFromIncomeBalance, availableRefillPackages } = useBalanceContext();

  useEffect(() => {
    if (availableRefillPackages?.packages?.length) {
      window.requestAnimationFrame(() => {
        formMethods.resetField('package', { defaultValue: availableRefillPackages.packages[0].id });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableRefillPackages?.packages, formMethods, ...formDataDeps]);

  return formMethods;
};

export default function BalanceRefillForm({ formMethods }: BalanceRefillFormInterface) {
  const {
    control,
    formState: { errors },
    watch,
  } = formMethods;
  const { availableRefillPackages } = useBalanceContext();
  const { userHasFeature } = useAuthContext();
  const showFromIncomeBalanceOption = userHasFeature(FEATURES.MLP_PUBLIC_MODEL_ADMIN);

  const methodsList = useMemo(
    () => [
      {
        label: t('BalanceRefillModal:fromCard'),
        name: 'fromCard',
        value: 'fromCard',
      },
      {
        label: t('BalanceRefillModal:fromIncomeBalance'),
        name: 'fromIncomeBalance',
        value: 'fromIncomeBalance',
      },
    ],
    []
  );

  const packagesList = useMemo(
    () =>
      availableRefillPackages?.packages?.map(item => ({
        label: `${item.rubles} ₽`,
        value: item.id,
      })),
    [availableRefillPackages?.packages]
  );
  const watchMethod = watch('method');

  useEffect(() => {
    if (!showFromIncomeBalanceOption) {
      formMethods.setValue('method', 'fromCard');
    }
  }, [formMethods, showFromIncomeBalanceOption]);

  return (
    <FormContainer
      className={styles.balanceRefillForm__container}
      showError={errors?.commonError}
      errorMessage={errors.commonError?.message}
    >
      {showFromIncomeBalanceOption ? (
        <>
          <h3>{t('BalanceRefillModal:BalanceRefillOptions')}</h3>
          <Controller
            name='method'
            control={control}
            render={({ field: { onChange, value } }) => (
              <SimpleRadioButtonsGroup
                name='method'
                value={value || ''}
                onChange={onChange}
                list={methodsList}
                containerClassName={styles.balanceRefillForm__radioButtons}
              />
            )}
          />
        </>
      ) : (
        <h3 style={{ padding: '0' }}>{t('BalanceRefillModal:PayByCard')}</h3>
      )}
      {watchMethod === 'fromIncomeBalance' && showFromIncomeBalanceOption && (
        <ControlledInput
          data-test-id='BalanceRefillForm:method'
          name='sum'
          placeholder={t('BalanceRefillModal:Sum')}
          control={control}
          type='number'
          step='any'
        />
      )}
      {(watchMethod === 'fromCard' || !showFromIncomeBalanceOption) && (
        <ControlledSelect
          name='package'
          control={control}
          rules={{ required: false }}
          defaultValue={packagesList?.[0].value}
          options={packagesList}
          fullWidth
          inputPlaceholder={t('BalanceRefillModal:SelectPackage')}
        />
      )}
    </FormContainer>
  );
}
