import React from 'react';
import cn from 'classnames';

import { Button as JustUiButton, ButtonProps } from '@just-ai/just-ui/dist/Button';

import './style.scss';
import Spinner from 'components/Spinner';

export interface ButtonInterface extends Omit<ButtonProps, 'color'> {
  color?: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'link' | 'none' | 'ghost';
  asLink?: boolean;
  round?: boolean;
  isLoading?: boolean;
}
const Button: React.FC<ButtonInterface> = function ({ color, asLink, round, isLoading = false, children, ...props }) {
  const colorProp = color === 'ghost' ? undefined : color;
  return (
    <JustUiButton
      {...props}
      color={colorProp}
      className={cn(props.className, 'button-cmp', {
        'button-cmp--link': asLink,
        'button-cmp--ghost': color === 'ghost',
        'button-cmp--round': round,
      })}
    >
      {isLoading ? <Spinner centered className='btn-spinner' show /> : children}
    </JustUiButton>
  );
};

export default React.memo(Button) as typeof Button;
