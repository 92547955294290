import { memo, useCallback, useMemo } from 'react';
import { DropdownButton, DropdownItem, DropdownMenu, DropdownToggle, Icon } from '@just-ai/just-ui';
import { history } from 'App';
import { useAuthContext } from 'contexts/AuthContext';
import { AccountDataDto } from 'contexts/AuthContext/types';

import styles from './styles.module.scss';

const AccountsSelector = () => {
  const { changeAccount: changeAccountInContext, user, allowedAccounts } = useAuthContext();

  const changeAccount = useCallback(
    id => () => {
      history.replace('/');
      changeAccountInContext(id);
    },
    [changeAccountInContext]
  );

  const name = useMemo(() => {
    if (!user) return undefined;
    return allowedAccounts?.find(account => account.id === user.accountId)?.name;
  }, [allowedAccounts, user]);

  const ownAccounts = useMemo(() => allowedAccounts?.filter(account => account.owner), [allowedAccounts]);
  const notOwnAccounts = useMemo(() => allowedAccounts?.filter(account => !account.owner), [allowedAccounts]);

  if (!allowedAccounts?.length || allowedAccounts.length < 2) {
    return null;
  }

  return (
    <div className={styles.headerAccount} data-test-id='AccountSelector'>
      <DropdownButton direction='down'>
        <DropdownToggle color='primary' tag='div'>
          <div className={styles.headerAccount__accountSelector} data-test-id='AccountSelector.Toggle'>
            <div className={styles.headerAccount__organization}>{name}</div>
            <Icon name='faCaretDown' />
          </div>
        </DropdownToggle>
        <DropdownMenu className={styles['headerAccount__accountSelector-menu']}>
          <div className={styles['headerAccount__accountSelector-menu-scroll']}>
            {ownAccounts?.map(account => (
              <AccountItem key={`dropdown_account_${account.id}`} account={account} changeAccount={changeAccount} />
            ))}
            {notOwnAccounts && notOwnAccounts?.length > 0 && <DropdownItem divider />}
            {notOwnAccounts?.map(account => (
              <AccountItem key={`dropdown_account_${account.id}`} account={account} changeAccount={changeAccount} />
            ))}
          </div>
        </DropdownMenu>
      </DropdownButton>
    </div>
  );
};

const AccountItem = memo(
  ({ account, changeAccount }: { account: AccountDataDto; changeAccount: (id: number) => () => void }) => (
    <DropdownItem
      key={`dropdown_account_${account.id}`}
      onClick={changeAccount(account.id)}
      data-test-id={`AccountSelector.Group.${account.name}`}
    >
      {account.name}
    </DropdownItem>
  )
);

export default AccountsSelector;
