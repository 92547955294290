import React from 'react';
import cn from 'classnames';

import { useAppContext } from 'contexts/AppContext';

import { HighlightService } from 'services/HighlightService';
import { HighlightLangType } from 'services/HighlightService/types';

import styles from './styles.module.scss';
interface JSONViewInterface {
  json: string;
  className?: string;
}

function JSONView({ json, className }: JSONViewInterface) {
  const highlightService = useAppContext().diContainer.get(HighlightService);
  return (
    <pre
      className={cn(styles.jsonView, className)}
      data-test-id='testerResponseContent'
      dangerouslySetInnerHTML={{
        __html: highlightService.highlight(json, HighlightLangType.JSON),
      }}
    />
  );
}

export default React.memo(JSONView);
