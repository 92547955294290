import React, { Suspense } from 'react';
import remarkGfm from 'remark-gfm';
import { NormalComponents } from 'react-markdown/lib/complex-types';
import { prism as codeTheme } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

import { Icon } from '@just-ai/just-ui/dist/Icon';
import { Spinner } from 'components/Spinner';

import { SpecialComponents } from 'react-markdown/lib/ast-to-react';

import './style.scss';

const ReactMarkdown = React.lazy(() => import('react-markdown'));

export type MardownComponentsType = Partial<Omit<NormalComponents, keyof SpecialComponents> & SpecialComponents>;

export interface MarkdownContainerInterface {
  children: string;
  className?: string;
  components?: MardownComponentsType;
}

const MarkdownContainer = function ({ children, className, components }: MarkdownContainerInterface) {
  return (
    <div className={className}>
      <Suspense fallback={<Spinner />}>
        <ReactMarkdown
          children={children}
          className='markdown-container'
          remarkPlugins={[remarkGfm]}
          linkTarget='_blank'
          components={{
            ...components,
            a: ({ href, children }) => (
              <a href={href} className='markdown-container__link'>
                <span>{children}</span>
                <Icon name='faExternalLink' />
              </a>
            ),
            code(props) {
              const { children, className, node, ...rest } = props;
              const match = /language-(\w+)/.exec(className || '');
              return match ? (
                <SyntaxHighlighter
                  children={String(children).replace(/\n$/, '')}
                  language={match[1] || ''}
                  style={codeTheme}
                />
              ) : (
                <code {...rest} className={className}>
                  {children}
                </code>
              );
            },
          }}
        />
      </Suspense>
    </div>
  );
};

export default React.memo(MarkdownContainer);
