import { FormProvider, useFormContext, UseFormReturn } from 'react-hook-form';

import FormContainer from 'modules/Account/components/FormLayout/FormContainer';
import DatasetSelect from './components/DatasetSelect';
import ControlledInput from 'components/ControlledInput';
import ConfigurationEdit from './components/ConfigurationEdit';

import { formDefaulValues, TrainingFormFieldsType, TrainModeType } from './types';

import { useFormExternalData } from './hooks';
import { useDefaultForm } from 'utils/form/hooks';

import { t } from 'localization';

import styles from './styles.module.scss';

export type TrainingFormMethodsType = UseFormReturn<TrainingFormFieldsType, any>;
interface TrainingFormInterface {
  formMethods: TrainingFormMethodsType;
  mode?: TrainModeType;
}

export const useTrainingForm = ({
  defaultValues,
  formExternalDataDeps,
}: {
  defaultValues?: TrainingFormFieldsType;
  formExternalDataDeps?: any[];
}): TrainingFormMethodsType => {
  const { ...inputDefaultValues } = defaultValues || formDefaulValues;
  const formMethods = useDefaultForm<TrainingFormFieldsType>(inputDefaultValues);

  useFormExternalData(formMethods.resetField, formExternalDataDeps);

  return formMethods;
};

export const useTrainingFormContext = () => useFormContext<TrainingFormFieldsType>();

export default function TrainingForm({ formMethods, mode = 'create' }: TrainingFormInterface) {
  const errors = formMethods.formState.errors;
  return (
    <FormProvider {...formMethods}>
      <FormContainer
        className={styles.testingForm}
        showError={errors?.commonError}
        errorMessage={errors.commonError?.message}
      >
        <DatasetSelect />
        {mode === 'create' ? (
          <>
            <h3>{t('TrainingWidget:TrainingForm:FitServiceName')}</h3>
            <span>{t('TrainingWidget:TrainingForm:NewServiceWillBeCreated')}</span>
            <ControlledInput
              data-test-id='fitServiceName'
              name='name'
              placeholder={t('TrainingWidget:TrainingForm:ServiceNamePlaceholder')}
              control={formMethods.control}
              className={styles.testingForm__nameInput}
            />
          </>
        ) : (
          <div className={styles.testingForm__divider} />
        )}
        <ConfigurationEdit />
      </FormContainer>
    </FormProvider>
  );
}
