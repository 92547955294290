import React, { useRef } from 'react';
import Message from '../Message';
import LoadingBubble from '../LoadingBubble';

import useScrollToBottom from 'utils/hooks/useScrollToBottom';
import { useChatTestWidgetContext } from '../../contexts/ChatTestWidgetContext';

import styles from './styles.module.scss';

const MessagesContainer = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { messages, isLoading } = useChatTestWidgetContext();

  useScrollToBottom(wrapperRef, messages);

  if (!messages) {
    return null;
  }
  return (
    <div ref={wrapperRef} className={styles.messages__content}>
      {messages?.map((message, index) => (
        <Message key={index} message={message} />
      ))}
      {isLoading && <LoadingBubble />}
    </div>
  );
};
export default React.memo(MessagesContainer);
