import React, { useCallback, useEffect, useState } from 'react';

import Button from 'components/Button';
import Select from 'components/Select';
import { BaseModalFilterInterface } from 'components/Filter/types';
import { t } from 'localization';
import styles from './styles.module.scss';

export interface SelectFilterInterface<FILTER_OPTIONS extends string> extends BaseModalFilterInterface<FILTER_OPTIONS> {
  options: {
    label: string;
    value: string;
  }[];
  title?: string;
  placeholder?: string;
}

type SelectedItemType = {
  value?: any;
  label?: string;
};

const SelectFilter = <FILTER_OPTIONS extends string>({
  onClose,
  onResolve,
  filter,
  options,
  title,
  placeholder,
}: SelectFilterInterface<FILTER_OPTIONS>) => {
  const [selectedItem, setSelectedItem] = useState<SelectedItemType | undefined>();
  const handleSelect = useCallback(
    (newSelect: (string | number)[] | null) => {
      const newSelectedItem = options?.find(option => option.value === newSelect?.[0].toString());
      if (newSelectedItem?.value !== undefined) {
        setSelectedItem(newSelectedItem);
      }
    },
    [options]
  );

  useEffect(() => {
    const value = filter.value?.toString() || '';
    const newSelectedItem = options?.find(option => option.value === value);
    newSelectedItem?.value && setSelectedItem(newSelectedItem);
  }, [filter.value, options]);

  const inputPlaceholder = placeholder ? t(placeholder) : undefined;

  return (
    <div className={styles.selectFilterModal}>
      {title && <h3>{t(title)}</h3>}
      <Select
        inputPlaceholder={inputPlaceholder}
        closeable
        onChange={handleSelect}
        value={selectedItem?.value || ''}
        fullWidth
        options={options}
        classNameForList={styles.selectFilterModal__selectList}
      />
      <div className={styles.selectFilterModal__buttonsContainer}>
        <Button
          color='primary'
          onClick={() => {
            onResolve(selectedItem?.value || undefined, selectedItem?.label || undefined);
          }}
        >
          {t('SelectFilter:Save')}
        </Button>
        <Button color='ghost' outline onClick={onClose}>
          {t('SelectFilter:Cancel')}
        </Button>
      </div>
    </div>
  );
};

export default React.memo(SelectFilter);
