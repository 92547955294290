import { Icon } from '@just-ai/just-ui/dist/Icon';
import React from 'react';
import styles from './styles.module.scss';
import Button from 'components/Button';
import { t } from 'localization';
import { useBalanceContext } from 'contexts/BalanceContext';

interface UserInfoBannerInterface {
  type?: 'warning' | 'danger';
}
const UserInfoBanner = ({ type = 'warning' }: UserInfoBannerInterface) => {
  const { openBalanceRefillModal } = useBalanceContext();

  return (
    <div className={styles[`banner-${type}`]}>
      <Icon name='farExclamationCircle' color={type} size='lg' />
      <span>{type === 'danger' ? t('UserInfoBanner:Danger:NoFunds') : t('UserInfoBanner:Warning:NoFunds')}</span>
      <Button color={type} onClick={openBalanceRefillModal}>
        {t('UserInfoBanner:NoFunds')}
      </Button>
    </div>
  );
};

export default React.memo(UserInfoBanner);
