import { MountStateStyleMapType, useDelayedMount } from 'hooks/useDelayMount';
import React, { Ref } from 'react';

const DELAY_TIME = 130;
const style = { transition: `opacity ${DELAY_TIME}ms ease-in` };

const stylesStateMap: MountStateStyleMapType = {
  beforeMount: { opacity: 0, transition: `opacity ${DELAY_TIME}ms ease-in` },
  mount: { opacity: 1, transition: `opacity ${DELAY_TIME}ms ease-in` },
  unmount: { opacity: 0, transition: `opacity ${DELAY_TIME}ms ease-in` },
};

export function withFadeAnimation<PROPS extends unknown>(Cmp: React.ComponentType<PROPS>) {
  return React.forwardRef(function (props: PROPS & { show: boolean }, ref: Ref<React.ComponentType<PROPS>>) {
    const { show } = props;
    const [shouldRenderChild, mountState] = useDelayedMount(show, DELAY_TIME);

    return <>{shouldRenderChild && <Cmp {...props} ref={ref} style={{ ...stylesStateMap[mountState] }} />}</>;
  });
}
