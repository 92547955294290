import React from 'react';
import cn from 'classnames';

import { IconButton as JustUiIconButton, IconButtonProps } from '@just-ai/just-ui/dist/Button';

export interface ButtonInterface extends Omit<IconButtonProps, 'color'> {
  color?: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'link' | 'none' | 'ghost';
  name: string;
  round?: boolean;
}
const IconButton = function ({ color, round, ...props }: ButtonInterface) {
  const colorProp = color === 'ghost' ? undefined : color;
  return (
    <JustUiIconButton
      {...props}
      color={colorProp}
      className={cn(props.className, 'button-cmp', {
        'button-cmp--ghost': color === 'ghost',
        'button-cmp--round': round,
      })}
    />
  );
};

export default React.memo(IconButton) as typeof IconButton;
