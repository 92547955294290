import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

export const scrollIframeToAnchor = (iframe: HTMLIFrameElement | null, hash: string | undefined) => {
  if (iframe && hash) {
    iframe.contentDocument?.getElementById(hash)?.scrollIntoView({ behavior: 'smooth' });
  }
};

export const useScrollIframeToAnchor = () => {
  const location = useLocation();
  const [scrollToAnchor, setScrollToAnchor] = useState<((anchor: string) => void) | undefined>(undefined);

  useEffect(() => {
    const parsedHash = location.hash.replace('#', '');
    scrollToAnchor && scrollToAnchor(parsedHash);
  }, [location, scrollToAnchor]);

  const setIframeOnload = useCallback((iframe: HTMLIFrameElement | null) => {
    setScrollToAnchor(() => (hash: string) => {
      scrollIframeToAnchor(iframe, hash);
    });
  }, []);

  return [setIframeOnload];
};
