import React, { useCallback } from 'react';
import { Modal } from '@just-ai/just-ui/dist/Modal';
import Spinner from 'components/Spinner';

import AccessRequestForm, { useAccessRequestForm } from './components/AccessRequestForm';

import { AccessRequestFormFieldsType, AccessRequestFormFieldsNamesList } from './components/AccessRequestForm/types';
import { AccessRequestFormService } from './components/AccessRequestForm/AccessRequestFormService';

import { useAppContext } from 'contexts/AppContext';
import { useAuthContext } from 'contexts/AuthContext';

import { usePromisifyComponent } from 'hooks/usePromisifyComponent';
import { useLoading } from 'utils/hooks';
import { notifyActionMessage } from 'components/MessageContainer';
import { handleFormErrors } from 'utils/form';
import { getRequestFormMessages, RequestType } from './types';
import { t } from 'localization';

interface AccessRequestModalInterface {
  isOpen: boolean;
  onClose: () => void;
  onResolve: (data?: unknown) => void;
  requestType?: RequestType;
}

const AccessRequestModal = ({
  isOpen,
  onClose,
  onResolve,
  requestType = 'modelAdmin',
}: AccessRequestModalInterface) => {
  const { user } = useAuthContext();
  const formMethods = useAccessRequestForm();
  const accessRequestFormService = useAppContext().diContainer.get(AccessRequestFormService);
  const [isLoading, , startLoading, endLoading] = useLoading();

  const submit = useCallback(
    async (formValues: AccessRequestFormFieldsType) => {
      if (!user) return;
      startLoading();
      try {
        if (requestType === 'modelAdmin') {
          await Promise.resolve(() => {
            setTimeout(() => {}, 15000);
          });
          await accessRequestFormService.submitFormToRequestModelAdmin(user.accountId, formValues);
        } else if (requestType === 'publicModelAdmin') {
          await accessRequestFormService.submitFormToRequestPublicModelAdmin(user.accountId, formValues);
        }
        onResolve();
      } catch (error) {
        handleFormErrors(error, AccessRequestFormFieldsNamesList, formMethods.setError);
      } finally {
        endLoading();
      }
    },
    [accessRequestFormService, endLoading, formMethods, onResolve, requestType, startLoading, user]
  );

  const message = getRequestFormMessages(requestType);

  return (
    <Modal
      title={t('AccessRequestModal:Access')}
      isOpen={isOpen}
      disableActionButtonAutoFocus
      size='md'
      buttonSubmitText={t('AccessRequestModal:RequestAccess')}
      buttonCancelText={t('AccessRequestModal:Cancel')}
      onClickCloseIcon={onClose}
      onActionClick={formMethods.handleSubmit(submit)}
      onCancelClick={onClose}
    >
      <Spinner centered show={isLoading} />
      <AccessRequestForm formMethods={formMethods} message={message} />
    </Modal>
  );
};

export default React.memo(AccessRequestModal);
export const useAccessRequestModal = () => {
  return usePromisifyComponent<unknown, { requestType?: RequestType }>(
    (onResolve, onClose, opened, inputData) => (
      <AccessRequestModal
        isOpen={opened}
        onClose={onClose}
        onResolve={onResolve}
        requestType={inputData?.requestType}
      />
    ),
    {
      onResolve: () =>
        notifyActionMessage(t('AccessRequestModal:RequestAccessSuccessMessage'), { theme: 'dark', type: 'success' }),
    }
  );
};
