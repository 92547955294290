import { TaskTypeData } from 'api/CailagateApi/api/client';

export const TaskColorsMap: Record<string, string> = {
  'yellow-200': '#FEDF78',
  'orange-200': '#FDDAC8',
  'green-200': '#D9E4D9',
  'blue-200': '#D6E2F2',
  'red-200': '#FBDAD5',
  'indigo-200': '#E3DEFB',
  'gray-200': '#E0E1E3',
};

export interface TaskTypeDataMap {
  [key: string]: TaskTypeData;
}
