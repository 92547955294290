import { Icon } from '@just-ai/just-ui';
import { withWidgetLayout } from 'HOC/withWidgetLayout';
import Button from 'components/Button';
import { useAuthContext } from 'contexts/AuthContext';
import React from 'react';
import styles from './styles.module.scss';
import { t } from 'localization';

interface WidgetStubInterface {
  type?: 'test' | 'fit';
}

const WidgetStub = ({ type = 'test' }: WidgetStubInterface) => {
  const { user, loginWithAccountPageRedirect } = useAuthContext();
  return (
    <>
      <div className={styles.widgetStub}>
        <Icon name='farExclamationCircle' color='primary' size='lg' />
        <p>
          {type === 'test'
            ? t('TestingWidget:TestingNotAvailableForUnauthorized')
            : t('TestingWidget:FitIsNotAvailableForUnauthorized')}
        </p>
      </div>
      <Button color='primary' onClick={loginWithAccountPageRedirect}>
        {t('TestingWidget:LoginButton')}
      </Button>
    </>
  );
};

const WidgetStubWithLayout = withWidgetLayout(WidgetStub);

export default React.memo(WidgetStubWithLayout);
