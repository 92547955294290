import React from 'react';

import styles from './styles.module.scss';

const RelativeWidgetLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className={styles[`widgetLayout__wrapper_fullScreen`]}>
      <div className={styles.widgetLayout__content}>{children}</div>
    </div>
  );
};

export default React.memo(RelativeWidgetLayout);
