import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import langRu from './langs/ru.json';
import langEn from './langs/en.json';

const resources = {
  ru: {
    translation: langRu,
  },
  en: {
    translation: langEn,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: 'ru',
  resources,
  interpolation: {
    escapeValue: false,
  },
  nsSeparator: false,
  parseMissingKeyHandler(key: string): any {
    return `%${key}%`;
  },
});

export default i18n;

export const t = i18n.t;
