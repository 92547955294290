import React from 'react';
import cn from 'classnames';
import { RadioButton } from '@just-ai/just-ui/dist';

import './style.scss';

interface RadioButtonInterface {
  name: string;
  value?: string;
  label?: string;
  dataTestId?: string;
  id?: string;
  disabled?: boolean;
}
export interface SimpleRadioButtonsGroupInterface {
  list: RadioButtonInterface[];
  value: string;
  onChange: (value?: string, name?: string) => any;
  title?: string;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  containerClassName?: string;
  dataTestIdPrefix?: string;
  name?: string;
}
function SimpleRadioButtonsGroup({
  list,
  title,
  value,
  className,
  containerClassName,
  onChange,
  disabled,
  readOnly,
  dataTestIdPrefix = '',
}: SimpleRadioButtonsGroupInterface) {
  return (
    <div
      className={cn(
        'cmp-radioButtons',
        'cmp-simpleRadioButtons',
        {
          disabled: disabled || readOnly,
        },
        className
      )}
    >
      {title && <div>{title}</div>}

      <div className={cn('cmp-simpleRadioButtons__container', containerClassName)}>
        {list.map(el => (
          <React.Fragment key={el.name || ''}>
            <RadioButton
              name={el.name}
              data-test-id={`${dataTestIdPrefix}${el.name}`}
              label={el?.label || el.name}
              id={el?.id || el.name}
              value={el.value}
              checked={value === el.value}
              onChange={() => onChange(el?.value)}
              disabled={disabled || el.disabled}
              readOnly={readOnly}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default React.memo(SimpleRadioButtonsGroup);
