import { FieldError } from 'react-hook-form/dist/types/errors';

export type TrainModeType = 'create' | 'update';

export enum ResponseErrorFieldNames {
  datasetSelectMode = 'datasetSelectMode',
  datasetId = 'datasetId',
  datasetDataType = 'datasetDataType',
  datasetContent = 'datasetContent',
  name = 'name',
}

export enum TrainingFormFieldsNames {
  datasetSelectMode = 'datasetSelectMode',
  datasetId = 'datasetId',
  datasetDataType = 'datasetDataType',
  datasetName = 'datasetName',
  datasetContent = 'datasetContent',
  shouldEditConfig = 'shouldEditConfig',
  configMode = 'configMode',
  config = 'config',
  configId = 'configId',
  name = 'name',
}

export const TrainingFormFieldsNamesList = Object.values(TrainingFormFieldsNames);

export enum DatasetSelectMode {
  fromList = 'fromList',
  upload = 'upload',
}

export enum ConfigDefinitionMode {
  selectExistingConfig = 'selectExistingConfig',
  writeConfig = 'writeConfig',
}

export const formDefaulValues: TrainingFormFieldsType = {
  datasetSelectMode: DatasetSelectMode.fromList,
  datasetId: undefined,
  datasetDataType: '',
  datasetName: '',
  datasetContent: '',
  name: '',
  config: '',
  configId: undefined,
  configMode: ConfigDefinitionMode.selectExistingConfig,
  shouldEditConfig: false,
  commonError: undefined,
};

export type TrainingFormFieldsType = {
  [TrainingFormFieldsNames.datasetSelectMode]: DatasetSelectMode;
  [TrainingFormFieldsNames.datasetId]?: string;
  [TrainingFormFieldsNames.datasetDataType]: string;
  [TrainingFormFieldsNames.datasetName]: string;
  [TrainingFormFieldsNames.datasetContent]: string;
  [TrainingFormFieldsNames.name]: string;
  [TrainingFormFieldsNames.shouldEditConfig]: boolean;
  [TrainingFormFieldsNames.configMode]: ConfigDefinitionMode;
  [TrainingFormFieldsNames.config]: string;
  [TrainingFormFieldsNames.configId]?: string;
  commonError: any;
};

export type TrainingFormErrorsType = {
  commonError?: any;
  config?: FieldError | undefined;
  request?: FieldError | undefined;
};
