import React from 'react';
import cn from 'classnames';
import { MessageType, MessageSourceType, MessageDataType } from '../../types';
import styles from './styles.module.scss';
import MessageLayout from '../../layouts/MessageLayout';

interface MessageInterface {
  message: MessageType;
}

const MessageClasses: Record<string, string> = {
  [MessageSourceType.BOTMESSAGE]: styles.message__wrap_BOTMESSAGE,
  [MessageSourceType.CLIENTMESSAGE]: styles.message__wrap_CLIENTMESSAGE,
};

const Message = ({ message }: MessageInterface) => {
  const creationTimestamp = message.timestamp;
  const minutes = creationTimestamp ? new Date(creationTimestamp).getMinutes().toString().padStart(2, '0') : '';
  const hours = creationTimestamp ? new Date(creationTimestamp).getHours().toString().padStart(2, '0') : '';

  const renderMessageData = (data: MessageDataType) => {
    switch (data.type) {
      case 'text': {
        return <>{data.text}</>;
      }
      default:
        return null;
    }
  };

  return (
    <MessageLayout messageSource={message.source}>
      <div className={cn(styles.message__text)}>{renderMessageData(message.data)}</div>
      {message.timestamp && (
        <div className={styles.message__date}>
          <span>{`${hours}:${minutes}`}</span>
        </div>
      )}
    </MessageLayout>
  );
};

export default React.memo(Message);
