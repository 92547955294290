import { PredictConfigData } from 'api/CailagateApi/api/client';

export const getConfigIdToSet = (predictConfigs: PredictConfigData[], savedConfigId?: string) => {
  const defaultConfigId =
    predictConfigs.find(config => config.isDefault)?.id?.configId.toString() ||
    predictConfigs[0].id?.configId.toString();
  const configIdToSet = savedConfigId
    ? predictConfigs.find(configItem => configItem.id.configId.toString() === savedConfigId)?.id.configId.toString() ||
      defaultConfigId
    : defaultConfigId;
  return configIdToSet;
};

const getConfigsSelectItem = (config: PredictConfigData) => {
  return {
    label: config.name,
    value: config?.id?.configId.toString() || '0',
  };
};

export const getConfigsSelectList = (configs?: PredictConfigData[]) => {
  if (!configs?.length) return undefined;
  return configs.map(getConfigsSelectItem);
};
