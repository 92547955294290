import { Service } from 'typedi';

import { RefillEndpointApi } from 'api/CailaBillingApi/api/client';

@Service()
export class RefillApiService extends RefillEndpointApi {
  constructor() {
    super({ baseOptions: { timeout: 30000 } }, '');
  }
}
