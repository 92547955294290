import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { DatasetInfoData, FitConfigData, ModelInfoData } from 'api/CailagateApi/api/client';

import { useDI } from 'contexts/AppContext';
import { useAuthContext } from 'contexts/AuthContext';
import { useLoading } from 'utils/hooks';
import { DatasetApiService } from 'services/ApiServices/DatasetApiService';
import { FitConfigApiService } from 'services/ApiServices/FitConfigApiService';
import { AppLogger } from 'services/AppLogger';

export type TrainingContextType = {
  serviceData: ModelInfoData;
  updateService?: () => Promise<void>;
  datasets?: DatasetInfoData[];
  datasetDataTypes?: string[];
  fitConfigs?: FitConfigData[];
  getFormData: () => Promise<void>;
  isLoadingGlobal: boolean;
};

export const TrainingContext = createContext({} as TrainingContextType);

interface TrainingContextProviderProps {
  serviceData: ModelInfoData;
  updateService?: () => Promise<void>;
}

export const TrainingContextProviderComponent: React.FC<TrainingContextProviderProps> = ({
  children,
  serviceData,
  updateService,
}) => {
  const { user } = useAuthContext();
  const [isLoading, , startLoading, endLoading] = useLoading();

  const datasetApi = useDI(DatasetApiService);
  const fitConfigApi = useDI(FitConfigApiService);

  const [fitConfigs, setFitConfigs] = useState<FitConfigData[] | undefined>();
  const [datasets, setDatasets] = useState<DatasetInfoData[]>();
  const [datasetDataTypes, setDatasetDataTypes] = useState<string[] | undefined>();

  const getFormData = useCallback(async () => {
    if (!user) return;
    const userAccountId = user?.accountId.toString();
    const { trainingModelAccountId, trainingModelId, id: modelId } = serviceData;
    startLoading();
    try {
      const fitAccount = trainingModelAccountId ? trainingModelAccountId.toString() : modelId.accountId.toString();
      const fitModel = trainingModelId ? trainingModelId.toString() : modelId.modelId.toString();

      const { data: allowedDatatypes } = await datasetApi.getAllowedDataTypesForFit(fitAccount, fitModel);
      setDatasetDataTypes(allowedDatatypes);

      const { data: datasets } = await datasetApi.listDatasets(userAccountId);
      setDatasets(
        datasets.filter(dataset => {
          return (
            (dataset.dataType && allowedDatatypes.find(datatype => datatype === dataset.dataType)) || !dataset.dataType
          );
        })
      );

      const { data: fitConfigs = undefined } = await fitConfigApi.listFitConfigs(
        modelId.accountId.toString(),
        modelId.modelId.toString()
      );

      setFitConfigs(fitConfigs);
    } catch (error) {
      AppLogger.error({ exception: error });
    }
    endLoading();
  }, [datasetApi, endLoading, fitConfigApi, serviceData, startLoading, user]);

  useEffect(() => {
    getFormData();
  }, [getFormData]);

  return (
    <TrainingContext.Provider
      value={{
        serviceData,
        fitConfigs,
        datasets,
        datasetDataTypes,
        getFormData,
        updateService,
        isLoadingGlobal: isLoading,
      }}
    >
      {children}
    </TrainingContext.Provider>
  );
};

export const useTrainingContext = () => useContext(TrainingContext);
