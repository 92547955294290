import React from 'react';
import styles from './styles.module.scss';
import { WidgetComponentProps } from 'HOC/withWidgetLayout';
import CloseButton from 'components/CloseButton';
import FullScreenToggle from '../FullScreenToggle';
import { createPortal } from 'react-dom';

const WidgetLayout = ({
  isFullScreen,
  toggleFullScreen,
  children,
}: WidgetComponentProps & { children: React.ReactNode }) => {
  const content = (
    <div className={styles[`widgetLayout__wrapper${isFullScreen ? '_fullScreen' : ''}`]}>
      {isFullScreen && (
        <CloseButton
          onClick={toggleFullScreen}
          className={styles.widgetLayout__close}
          dataTestId='Widget:FullScreenCloseButton'
        />
      )}
      <div className={styles.widgetLayout__content}>
        <FullScreenToggle
          className={styles.widgetLayout__toggle}
          isFullScreen={isFullScreen}
          toggleFullScreen={toggleFullScreen}
        />
        {children}
      </div>
    </div>
  );

  if (isFullScreen) createPortal(content, document.body);
  return content;
};

export default React.memo(WidgetLayout);
