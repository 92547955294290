import React from 'react';
import Container from 'typedi';
import { tokens } from 'diConfig';

const BannerContainer = () => {
  const bannerMountId = Container.get(tokens.BANNER_MOUNT_ID);
  return <div id={bannerMountId} />;
};

export default React.memo(BannerContainer);
