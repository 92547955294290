import React from 'react';
import { Link } from 'react-router-dom';

import { Icon, ICONS } from '@just-ai/just-ui/dist/Icon';

import './style.scss';
import { LocationDescriptor } from 'history';

interface IconLinkInterface {
  text: string;
  icon: ICONS;
  to?: LocationDescriptor<unknown>;
  onClick?: () => void;
  dataTestId?: string;
}
const IconLink = function ({ text, onClick, icon, to, dataTestId }: IconLinkInterface) {
  const content = (
    <div className='icon-link' onClick={onClick} data-test-id={dataTestId}>
      <Icon name={icon} />
      <span>{text}</span>
    </div>
  );
  return to ? <Link to={to}>{content}</Link> : content;
};

export default React.memo(IconLink);
