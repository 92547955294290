import { Service } from 'typedi';

@Service()
export class AudioDeviceService {
  async getDevices() {
    const rawDevices = await navigator.mediaDevices.enumerateDevices();
    const availableAudioDevices = rawDevices.filter(device => device.kind === 'audioinput');
    return [...availableAudioDevices];
  }

  async checkPermission(): Promise<{ hasPermission: boolean; audioDevices?: MediaDeviceInfo[] }> {
    const permissionName = 'microphone' as PermissionName;
    try {
      if (window.navigator.userAgent.indexOf('Chrome') > -1) {
        const permission = await navigator.permissions.query({ name: permissionName });
        switch (permission.state) {
          case 'prompt': {
            await navigator.mediaDevices.getUserMedia({ audio: true });
            const audioDevices = await this.getDevices();
            return { audioDevices, hasPermission: true };
          }
          case 'granted': {
            const audioDevices = await this.getDevices();
            return { audioDevices, hasPermission: true };
          }
          case 'denied': {
            return { hasPermission: false };
          }
          default: {
            return { hasPermission: false };
          }
        }
      } else {
        await navigator.mediaDevices.getUserMedia({ audio: true });
        const audioDevices = await this.getDevices();
        return { audioDevices, hasPermission: true };
      }
    } catch (error) {
      return { hasPermission: false };
    }
  }
}
