import WaveSurfer from 'wavesurfer.js';
import MicrophonePlugin from 'wavesurfer.js/src/plugin/microphone';

import { BUFFER_SIZE, waveformOptions } from './constants';

export class RecordingWaveForm {
  waveSurfer?: WaveSurfer;

  constructor(deviceId?: string) {
    this.waveSurfer && this.waveSurfer.destroy();
    this.waveSurfer = WaveSurfer.create({
      ...waveformOptions,
      plugins: [
        MicrophonePlugin.create({
          bufferSize: BUFFER_SIZE,
          constraints: { audio: { deviceId: deviceId } },
        }),
      ],
    });
  }

  onMicrophoneReady = (callBack: (stream: any) => void) => {
    this.waveSurfer?.microphone.on('deviceReady', callBack);
  };

  start = () => {
    this.waveSurfer?.microphone.start();
  };

  stop = () => {
    this.waveSurfer?.microphone?.stop();
    this.destroy();
  };
  destroy = () => {
    this.waveSurfer?.destroy();
  };
}
