import React from 'react';
import { t } from 'localization';
import { Icon } from '@just-ai/just-ui/dist/Icon';
import { DateTime } from 'luxon';

import Dropdown from 'components/Dropdown';
import Button from 'components/Button';

import { useBalanceContext } from 'contexts/BalanceContext';

import { useAuthContext } from 'contexts/AuthContext';
import { FEATURES } from 'contexts/AuthContext/constants';
import { formatNumberWithPrecision } from 'utils/numbers';

import styles from './styles.module.scss';

const DECIMAL_PLACES = 2;

const BalanceInfo = () => {
  const { balanceInfo, openBalanceRefillModal, isBillingEnabled } = useBalanceContext();
  const { userHasFeature } = useAuthContext();
  const showFromIncomeBalance = userHasFeature(FEATURES.MLP_PUBLIC_MODEL_ADMIN);

  if (!isBillingEnabled || !balanceInfo) return null;

  return (
    <Dropdown
      labelClassName={styles.balance__chip}
      labelRender={() => (
        <>
          <Icon name='farCreditCard' />
          <span className={styles.balance__overallBalance}>{`${formatNumberWithPrecision(
            balanceInfo.overallBalanceRub || 0,
            DECIMAL_PLACES
          )} ₽`}</span>
        </>
      )}
      contentRender={() => (
        <div className={styles.balance__dropdownContent}>
          <div className={styles.balance__dropdownContentItem}>
            <span>{t('Header:BalanceInfo:MainCailaBalance')}</span>
            <span>
              <b>{`${formatNumberWithPrecision(balanceInfo.overallBalanceRub || 0, DECIMAL_PLACES)} ₽`}</b>
            </span>
          </div>
          <div className={styles['balance__dropdownContentItem-colored']}>
            <span>{t('Header:BalanceInfo:JaicpTariff')}</span>
            <span>
              <b>{`${formatNumberWithPrecision(balanceInfo.jaicpTariffBalanceRub || 0, DECIMAL_PLACES)} ₽`}</b>
            </span>
            {balanceInfo.nextRefillDate ? (
              <small>
                <span>
                  {t(`Header:BalanceInfo:NextRefill`, {
                    date: DateTime.fromJSDate(new Date(balanceInfo.nextRefillDate)).toFormat('dd.MM'),
                    amount: balanceInfo.nextRefillRub,
                  })}
                </span>
              </small>
            ) : null}
          </div>
          {showFromIncomeBalance && (
            <div className={styles.balance__dropdownContentItem}>
              <span>{t('Header:BalanceInfo:IncomeBalance')}</span>
              <span>
                <b>{`${formatNumberWithPrecision(balanceInfo.incomeBalanceRub || 0, DECIMAL_PLACES)} ₽`}</b>
              </span>
            </div>
          )}
          <Button color='primary' onClick={openBalanceRefillModal}>
            {t('Header:BalanceInfo:RefillBalance')}
          </Button>
        </div>
      )}
    />
  );
};

export default React.memo(BalanceInfo);
