export function isJSONParseError(error: unknown): boolean {
  return error instanceof Error && error instanceof SyntaxError && error.message.includes('JSON');
}

export function getPseudoBackendFormValidationError(error?: string, message?: string) {
  return {
    response: {
      data: {
        error,
        message,
      },
    },
  } as any;
}
