import React from 'react';
import { Route, RouteProps } from 'react-router';

const Full = (props: RouteProps) => {
  return (
    <Route {...props}>
      <div className='page-content-full'>{props.children}</div>
    </Route>
  );
};

export default Full;
