import React, { createContext, useContext } from 'react';
import { AudioDeviceService } from 'services/AudioDeviceService';
import Container from 'typedi';

export type RecorderContextType = {
  checkPermission: () => void;
  currentDevice?: MediaDeviceInfo;
  inputDevices: MediaDeviceInfo[];
  setCurrentDevice: (device: MediaDeviceInfo) => void;
  hasPermission: boolean;
  record: Blob | null;
  setRecord: (audio: Blob | null) => void;
  isRecording: boolean;
  setIsRecording: (isRecording: boolean) => void;
};

export const RecorderContext = createContext({} as RecorderContextType);

interface RecorderContextProviderProps {
  record: Blob | null;
  setRecord: (audio: Blob | null) => void;
}

class RecorderContextProviderState {
  currentDevice?: MediaDeviceInfo;
  inputDevices: MediaDeviceInfo[] = [];
  hasPermission: boolean = true;
  isRecording: boolean = false;
}

export class RecorderContextProviderComponent extends React.Component<
  RecorderContextProviderProps,
  RecorderContextProviderState
> {
  state = new RecorderContextProviderState();
  private audioDeviceService: AudioDeviceService = Container.get(AudioDeviceService);

  setInputDevices = (audioDevices: MediaDeviceInfo[] | undefined) => {
    if (!!audioDevices) {
      this.setState(
        { inputDevices: audioDevices && [...audioDevices] },
        //TODO think about moving it to componentDidUpdate
        () => !this.state.currentDevice && this.setCurrentDevice(audioDevices[0])
      );
    }
  };

  checkPermission = () => {
    this.audioDeviceService
      .checkPermission()
      .then(({ hasPermission, audioDevices }) => {
        this.setInputDevices(audioDevices);
        this.setState({ hasPermission });
      })
      .catch(() => this.setState({ hasPermission: false }));
  };

  setCurrentDevice = (value: MediaDeviceInfo) => {
    this.setState({ currentDevice: value });
  };

  setRecord = (audio: Blob | null) => {
    this.props.setRecord(audio);
  };

  setIsRecording = (isRecording: boolean) => {
    this.setState({ isRecording });
  };

  render() {
    const { children, record } = this.props;
    const { hasPermission, inputDevices, currentDevice, isRecording } = this.state;
    return (
      <RecorderContext.Provider
        value={{
          checkPermission: this.checkPermission,
          currentDevice,
          inputDevices,
          hasPermission,
          setCurrentDevice: this.setCurrentDevice,
          record,
          setRecord: this.setRecord,
          isRecording,
          setIsRecording: this.setIsRecording,
        }}
      >
        {children}
      </RecorderContext.Provider>
    );
  }
}

export const useRecorderContext = () => useContext(RecorderContext);
