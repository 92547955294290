import React, { useEffect, useRef } from 'react';
import ErrorInfoBanner from 'components/ErrorInfoBanner';

import styles from './styles.module.scss';
import { t } from 'localization';

import cn from 'classnames';

interface FormContainerInterface {
  showError: boolean;
  errorMessage?: string;
  children: React.ReactNode;
  className?: string;
  scrollToError?: boolean;
}

const FormContainer = ({
  showError,
  errorMessage,
  className,
  scrollToError = false,
  children,
}: FormContainerInterface) => {
  const errorMessageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showError && scrollToError) {
      errorMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showError, scrollToError]);

  return (
    <div className={cn(styles.formContainer, className)}>
      {showError && (
        <ErrorInfoBanner
          ref={errorMessageRef}
          className={styles.formContainer__errorBanner}
          errorMessage={errorMessage || t('Account:FormContainer:Error')}
        />
      )}
      {children}
    </div>
  );
};

export default React.memo(FormContainer);
