import React from 'react';
import cn from 'classnames';
import { Spoiler } from '@just-ai/just-ui/dist/Spoiler';

import JSONView from 'components/JSONView';
import Divider from 'modules/Account/components/Divider';

import { prettifyJSON } from 'utils';
import { t } from 'localization';

import './style.scss';
import styles from './styles.module.scss';

interface PredictResultInterface {
  result?: string;
  withError?: boolean;
  resultRender?: (result: string) => React.ReactNode;
}

const PredictResult = ({ result, withError, resultRender }: PredictResultInterface) => {
  return (
    <div className={styles.predictResult__wrapper}>
      {result && (
        <>
          <Divider />
          <div className={styles.predictResult__responseHead}>
            <h3>{t('ServicePage:TestingForm:Result')}</h3>
          </div>
          {resultRender && resultRender(result)}
          <Spoiler
            initiallyOpened
            key='json-output'
            Header={() => <h6>{t('ServicePage:TestingForm:JSONOutput')}</h6>}
            withWrapper
          >
            <div
              className={styles.predictResult__content}
              data-test-id={!withError ? 'TestingForm:PredictResult' : 'TestingForm:PredictErrorMessage'}
            >
              <JSONView json={prettifyJSON(result)} className={cn('predict-result-json-view', withError && 'error')} />
            </div>
          </Spoiler>
        </>
      )}
    </div>
  );
};

export default React.memo(PredictResult);
