import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Tabs, TabValue as JustUITabValue } from '@just-ai/just-ui/dist/Tabs';

import cn from 'classnames';
import { t } from 'localization';
import './style.scss';

interface TabValue<TABS extends string> extends Omit<JustUITabValue, 'value' | 'name'> {
  value: TABS;
  name: string;
}

interface TabsInterface<TABS extends string> {
  onTabChanged?: (tab: string) => void;
  tabs: TabValue<TABS>[];
  defaultTab?: TABS;
  path?: string;
  paramName?: string;
  className?: string;
  skipTranslate?: boolean;
  renderTabs?: ({
    className,
    activeTab,
    tabs,
  }: {
    className?: string;
    activeTab: string;
    tabs: JustUITabValue[];
  }) => React.ReactNode;
}

const TabsNavigate = <TABS extends string>({
  onTabChanged,
  tabs,
  defaultTab,
  path,
  className,
  paramName = 'tab',
  skipTranslate = false,
  renderTabs,
}: TabsInterface<TABS>) => {
  const [currentTab, setCurrentTab] = useState<TABS>(defaultTab || tabs[0].value);
  const tab = useParams<{ [key: string]: string }>()?.[paramName];

  useEffect(() => {
    setCurrentTab(tab as TABS);
    onTabChanged && onTabChanged(tab as TABS);
  }, [onTabChanged, tab]);

  const tabsWithLinks = useMemo(
    () =>
      tabs.map(tab => ({
        ...tab,
        name: <Link to={`${path || ''}/${tab.value}`}>{!skipTranslate ? t(tab.name) : tab.name}</Link>,
      })),
    [path, skipTranslate, tabs]
  );
  if (renderTabs) {
    return <>{renderTabs({ className, activeTab: currentTab, tabs: tabsWithLinks })}</>;
  }
  return <Tabs className={cn('tabs-navigate', className)} activeTab={currentTab} name='tabs' tabs={tabsWithLinks} />;
};

export default React.memo(TabsNavigate);
