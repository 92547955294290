import React, { useCallback } from 'react';
import Button from 'components/Button';
import { t } from 'localization';
import styles from './styles.module.scss';
import PredictResult from 'modules/ServiceTestComponents/PredictResult';
import { downloadBase64File } from 'utils';

const IMAGE_FORMAT = 'png';
const FILE_NAME = 'newImage';

interface ImageResultInterface {
  result?: string[];
  error?: string;
}

const ImageResult = ({ result, error }: ImageResultInterface) => {
  const handleDownloadImage = useCallback((image: string) => {
    downloadBase64File(image, `${FILE_NAME}.${IMAGE_FORMAT}`, `image/${IMAGE_FORMAT}`);
  }, []);

  if (error) {
    return <PredictResult withError result={error} />;
  }
  if (!result?.length) return null;
  return (
    <>
      {result.map((image, index) => (
        <div className={styles.imageWrap} key={index}>
          <Button
            className={styles.imageWrap__download}
            asLink
            iconLeft='farArrowToBottom'
            data-test-id='downloadImage'
            onClick={() => handleDownloadImage(image)}
          >
            {t('Image:DownloadButton')}
          </Button>
          <img src={`data:image/${IMAGE_FORMAT};base64,${image}`} alt='result' />
        </div>
      ))}
    </>
  );
};

export default React.memo(ImageResult);
