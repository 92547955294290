import { Link, RouteProps } from 'react-router-dom';

import { CardItem } from '../CardItem';
import { history } from 'App';

import { ModelInfoData } from 'api/CailagateApi/api/client';

import styles from './styles.module.scss';
import { CATALOG_BASE_PATH } from 'modules/ServicesCatalog/routesConfig';

interface CardsListInterface extends RouteProps {
  items?: ModelInfoData[];
  setFavorite: (serviceAccountId: number, serviceId: number, favourite: boolean) => Promise<void> | undefined;
}
export default function CardsList({ items, setFavorite }: CardsListInterface) {
  const from = `${history.location.pathname}${history.location.search || ''}`;

  return (
    <div className={styles.catalogList__container}>
      {items && items.length > 0 ? (
        <div className={styles.catalogList__content}>
          {items?.map((item, index) => (
            <Link
              className={styles.catalogList__cardLink}
              key={`${item.id.modelId}${index}`}
              to={{
                pathname: `/${CATALOG_BASE_PATH}/${item.modelAccountName || item.id.accountId}/${
                  item.modelName || item.id.modelId
                }`,
                state: { from },
              }}
              data-test-id={`${item.modelName}-model-card`}
            >
              <CardItem
                service={item}
                toggleFavorite={async () => setFavorite(item.id.accountId, item.id.modelId, !item.favorite)}
              />
            </Link>
          ))}
        </div>
      ) : null}
    </div>
  );
}
