import React from 'react';
import { Icon } from '@just-ai/just-ui/dist/Icon';
import cn from 'classnames';
import './style.scss';
import { withFadeAnimation } from 'HOC/withFadeAnimation';

interface SpinnerInterface {
  hidden?: boolean;
  className?: string;
  centered?: boolean;
  style?: React.CSSProperties;
  zIndex?: number;
}

export const Spinner = ({ hidden = false, className, zIndex, style, centered = false }: SpinnerInterface) => {
  if (hidden) return null;
  return (
    <div
      data-test-id='spinner'
      className={cn('loading-spinner-container', { centered: centered }, className)}
      style={{ ...style, zIndex }}
    >
      <Icon name='faSpinner' size='xl' className='loading-spinner' />
    </div>
  );
};

export const SpinnerWithFade = React.memo(withFadeAnimation(Spinner));

export default SpinnerWithFade;
