import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import { IconButton } from '@just-ai/just-ui';

import ApiSVG from 'img/api.svg';

import { NavigationConstraints } from './types';
import { FEATURES, PERMISSIONS } from 'contexts/AuthContext/constants';
import { WORKSPACE_BASE_PATH } from 'routes';

import { isDev } from 'utils';

const hostname = isDev() ? 'caila.io' : window.location.hostname;
const match = hostname?.match(/^mlp\.(.*?)\/?$/);
export const documentationLink = `${window.location.protocol}//docs.${match?.[1] || hostname}`;

export enum TopSidebarNames {
  Images = 'Images',
  DataImages = 'DataImages',
  Services = 'Services',
  Datasets = 'Datasets',
  ApiTokens = 'ApiTokens',
  S3Viewer = 'S3 Viewer',
  Manuals = 'Manuals',
  ServiceGroups = 'ServiceGroups',
  Reports = 'Reports',
  ResourсeGroupMonitoring = 'ResourсeGroupMonitoring',
  Billing = 'Billing',
  ResourceGroup = 'ResourceGroup',
}

export enum BottomSidebarNames {
  Api = 'Api',
  Documentation = 'Documentation',
}

export interface SidebarDataType<NAMES = TopSidebarNames, AdditionalComponentProps = any>
  extends NavigationConstraints {
  name: NAMES;
  text: string;
  iconName?: string;
  svg?: string;
  svgStyle?: CSSProperties;
  link?: string;
  isDisabled?: boolean;
  additionalComponentProps?: AdditionalComponentProps;
  AdditionalComponent?: React.FC<AdditionalComponentProps>;
  isExternal?: boolean;
  subSections?: SidebarDataType<NAMES, any>[];
}

export const TopSidebarSections: SidebarDataType<TopSidebarNames>[] = [
  {
    name: TopSidebarNames.Services,
    text: 'Account:Sidebar:Services',
    iconName: 'farCube',
    link: 'model',
    AdditionalComponent: () => (
      <Link style={{ display: 'contents' }} to={`/${WORKSPACE_BASE_PATH}/add-model`}>
        <IconButton
          name='farPlus'
          className='account-sidebar__section-subbuton'
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
          }}
        />
      </Link>
    ),
  },
  {
    name: TopSidebarNames.Datasets,
    text: 'Account:Sidebar:Datasets',
    iconName: 'farTh',
    link: 'dataset',
  },
  {
    name: TopSidebarNames.ApiTokens,
    text: 'Account:Sidebar:ApiTokens',
    iconName: 'farCode',
    link: 'api-tokens',
  },
  {
    name: TopSidebarNames.Manuals,
    text: 'Account:Sidebar:Settings',
    iconName: 'farBook',
    subSections: [
      {
        name: TopSidebarNames.ResourceGroup,
        text: 'Account:Sidebar:ResourceGroup',
        link: 'resource-group',
        requiredFeature: FEATURES.MLP_MODEL_ADMIN,
        requiredPermission: PERMISSIONS.MLP_DEVELOPER,
      },
      {
        name: TopSidebarNames.Images,
        text: 'Account:Sidebar:Images',
        link: 'image',
        requiredFeature: FEATURES.MLP_MODEL_ADMIN,
        requiredPermission: PERMISSIONS.MLP_DEVELOPER,
      },
      {
        name: TopSidebarNames.DataImages,
        text: 'Account:Sidebar:DataImages',
        link: 'data-image',
        requiredFeature: FEATURES.MLP_MODEL_ADMIN,
        requiredPermission: PERMISSIONS.MLP_DEVELOPER,
      },
      {
        name: TopSidebarNames.S3Viewer,
        text: 'Account:Sidebar:S3Viewer',
        link: 's3-viewer',
        requiredFeature: FEATURES.MLP_MODEL_ADMIN,
        requiredPermission: PERMISSIONS.MLP_DEVELOPER,
      },
      {
        name: TopSidebarNames.ServiceGroups,
        text: 'Account:Sidebar:ServiceGroups',
        link: 'service-groups',
      },
    ],
  },
  {
    name: TopSidebarNames.Reports,
    text: 'Account:Sidebar:Reports',
    iconName: 'farChartLine',
    subSections: [
      {
        name: TopSidebarNames.ResourсeGroupMonitoring,
        text: 'Account:Sidebar:ResourсeGroupMonitoring',
        link: 'resource-group-monitoring',
        requiredFeature: FEATURES.MLP_MODEL_ADMIN,
        requiredPermission: PERMISSIONS.MLP_DEVELOPER,
      },
      {
        name: TopSidebarNames.Billing,
        text: 'Account:Sidebar:Billing',
        link: 'billing',
        requiredBilling: true,
      },
    ],
  },
];

export const BottomSidebarSections: SidebarDataType<BottomSidebarNames>[] = [
  {
    name: BottomSidebarNames.Documentation,
    text: 'Account:Sidebar:Documentation',
    iconName: 'farBooks',
    link: documentationLink,
    isExternal: true,
  },
  {
    name: BottomSidebarNames.Api,
    text: 'Account:Sidebar:Api',
    iconName: 'farCircle',
    svg: ApiSVG,
    svgStyle: { transform: 'scale(1.45)' },
    link: 'swagger-ui',
  },
];
