import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

export const useCancelableState = <DATA_TYPE extends unknown>(
  initValue?: DATA_TYPE
): [
  DATA_TYPE | undefined,
  Dispatch<SetStateAction<DATA_TYPE | undefined>>,
  Dispatch<SetStateAction<DATA_TYPE | undefined>>,
  () => void,
  () => void
] => {
  const [value, setValue] = useState<DATA_TYPE | undefined>(initValue);
  const [cancelableValue, setCancelableValue] = useState<DATA_TYPE | undefined>();

  useEffect(() => {
    setCancelableValue(value);
  }, [value]);

  const cancel = useCallback(() => {
    setCancelableValue(value);
  }, [value]);

  const save = useCallback(() => {
    setValue(cancelableValue);
  }, [cancelableValue]);

  return [cancelableValue, setValue, setCancelableValue, cancel, save];
};
