import { Service } from 'typedi';

import { DatasetEndpointApi } from 'api/CailagateApi/api/client';

@Service()
export class DatasetApiService extends DatasetEndpointApi {
  constructor() {
    super({ baseOptions: { timeout: 30000 } }, '');
  }
}
