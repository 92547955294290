import React, { useMemo } from 'react';
import styles from './styles.module.scss';

interface ResultInterface {
  result?: string;
}

const Result = ({ result }: ResultInterface) => {
  const text = useMemo(() => {
    if (!result) return undefined;
    try {
      return JSON.parse(result)?.text as string;
    } catch (error) {
      return undefined;
    }
  }, [result]);
  if (!text) return null;
  return (
    <span className={styles.result__wrap} data-test-id='ASRTestingForm:Result'>
      <span>{text}</span>
    </span>
  );
};

export default React.memo(Result);
