import React from 'react';
import ReactDOM from 'react-dom';
import { Icon } from '@just-ai/just-ui/dist/Icon';
import cn from 'classnames';

import { useToggle } from 'utils/hooks';

import styles from './styles.module.scss';

interface DropdownInterface {
  labelRender: () => React.ReactNode;
  contentRender: (isOpen: boolean) => React.ReactNode;
  labelClassName?: string;
}

const Dropdown = ({ labelRender, contentRender, labelClassName }: DropdownInterface) => {
  const [isOpen, open, close] = useToggle(false);
  return (
    <div className={styles.dropdown__container}>
      <div
        className={cn(styles.dropdown__labelWrap, labelClassName, isOpen && styles['dropdown__labelWrap-open'])}
        onClick={open}
      >
        {labelRender()}
        <Icon name='farChevronDown' style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }} />
      </div>
      {isOpen && <div className={styles.dropdown__content}>{contentRender(isOpen)}</div>}
      {isOpen &&
        ReactDOM.createPortal(<div className={cn(styles.dropdown__backdrop)} onClick={close} />, document.body)}
    </div>
  );
};

export default React.memo(Dropdown);
