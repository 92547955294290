import { NotificationData } from './types';

export const getTypeByCode = (code?: string) => {
  code = code || '';
  if (code.includes('error')) {
    return 'error';
  }
  if (code.includes('warn')) {
    return 'warning';
  }
  if (code.includes('finished')) {
    return 'success';
  }
  return 'info';
};

export type NotificationType = 'error' | 'warning' | 'success' | 'info' | 'default';

const isOfNotificationType = (value: string): value is NotificationType => {
  return ['error', 'warning', 'success', 'info', 'default'].includes(value);
};

export const getTypeByNotification = (notification: NotificationData): NotificationType => {
  if (notification?.message?.code?.type) {
    return isOfNotificationType(notification.message.code.type) ? notification.message.code.type : 'default';
  }
  return getTypeByCode(
    (notification && notification.message && notification.message.code && notification.message.code.code) || ''
  );
};
