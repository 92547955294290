import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import SimpleRadioButtonsGroup from 'components/SimpleRadioButtonsGroup';

import { t } from 'localization';
import styles from './styles.module.scss';
import { ConfigDefinitionMode, ConfigSelectFieldsType } from '../../types';

interface ConfigurationModeInterface {}

const ConfigurationMode = (props: ConfigurationModeInterface) => {
  const { control, register } = useFormContext<ConfigSelectFieldsType>();

  const configDefinitionModeOptions = useMemo(
    () => [
      {
        label: t(`ServicePage:TestingForm:${ConfigDefinitionMode.selectExistingConfig}`),
        name: ConfigDefinitionMode.selectExistingConfig,
        value: ConfigDefinitionMode.selectExistingConfig,
      },
      {
        label: t(`ServicePage:TestingForm:${ConfigDefinitionMode.writeConfig}`),
        name: ConfigDefinitionMode.writeConfig,
        value: ConfigDefinitionMode.writeConfig,
      },
    ],
    []
  );
  return (
    <>
      <input type='hidden' {...register('configMode')} />
      <h3>{t('ServicePage:TestingForm:Configuration')}</h3>
      <Controller
        name='configMode'
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <SimpleRadioButtonsGroup
            value={value}
            onChange={onChange}
            containerClassName={styles.configurationSelect__radioButtons}
            name='TesterConfiguration'
            list={configDefinitionModeOptions}
          />
        )}
      />
    </>
  );
};

export default React.memo(ConfigurationMode);
