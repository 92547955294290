import { Reducer, useCallback, useEffect, useReducer, useState } from 'react';
import { ADD_FILTER, UPDATE_FILTER, DELETE_FILTER, RESET_FILTER } from './actions';
import { BaseFilterType } from '../types';
import equals from 'ramda/src/equals';

export type FilterState<FILTER_OPTIONS extends string> = {
  filterData: BaseFilterType<FILTER_OPTIONS>[];
};

export type FilterActions<FILTER_OPTIONS extends string> =
  | {
      type: typeof ADD_FILTER;
      payload: BaseFilterType<FILTER_OPTIONS>;
    }
  | {
      type: typeof UPDATE_FILTER;
      payload: BaseFilterType<FILTER_OPTIONS>;
    }
  | {
      type: typeof DELETE_FILTER;
      payload: FILTER_OPTIONS;
    }
  | {
      type: typeof RESET_FILTER;
      payload?: BaseFilterType<FILTER_OPTIONS>[];
    };

type FilterReducer<FILTER_OPTIONS extends string> = Reducer<FilterState<FILTER_OPTIONS>, FilterActions<FILTER_OPTIONS>>;

export const getFilterInitialState = <FILTER_OPTIONS extends string>(): FilterState<FILTER_OPTIONS> => ({
  filterData: [],
});

export const getReducer =
  <FILTER_OPTIONS extends string>(): FilterReducer<FILTER_OPTIONS> =>
  (state, action) => {
    switch (action.type) {
      case ADD_FILTER: {
        if (!state.filterData.find(filter => filter.key === action.payload.key)) {
          return { ...state, filterData: [...state.filterData, action.payload] };
        } else {
          return state;
        }
      }
      case DELETE_FILTER: {
        const newFilterData = state.filterData.filter(selected => selected.key !== action.payload);
        return { ...state, filterData: [...newFilterData] };
      }
      case UPDATE_FILTER: {
        const { key, value, label } = action.payload;
        if (!state.filterData?.length) {
          return state;
        }
        const index = state.filterData.findIndex(filter => filter.key === key);
        if (index < 0) return state;
        const updatedFilter = [
          ...state.filterData.slice(0, index),
          { ...state.filterData[index], value, label },
          ...state.filterData.slice(index + 1),
        ];
        return { ...state, filterData: updatedFilter };
      }
      case RESET_FILTER: {
        return { ...state, filterData: action.payload ? [...action.payload] : [] };
      }
      default:
        return state;
    }
  };

export const useFilter = <FILTER_OPTIONS extends string>(
  {
    onChange,
    initialState = getFilterInitialState<FILTER_OPTIONS>(),
  }: {
    onChange?: () => void;
    initialState?: FilterState<FILTER_OPTIONS>;
  } = { initialState: getFilterInitialState<FILTER_OPTIONS>() }
): [
  BaseFilterType<FILTER_OPTIONS>[],
  (newFilter: BaseFilterType<FILTER_OPTIONS>) => void,
  (keyToRemove: FILTER_OPTIONS) => void,
  (updatedFilter: BaseFilterType<FILTER_OPTIONS>) => void,
  (key: FILTER_OPTIONS) => BaseFilterType<FILTER_OPTIONS> | undefined,
  (newFilters?: BaseFilterType<FILTER_OPTIONS>[]) => void,
  Record<FILTER_OPTIONS, any>
] => {
  const reducer = getReducer<FILTER_OPTIONS>();
  const [{ filterData }, baseDispatch] = useReducer(reducer, initialState);
  const [filterValues, setFilterValues] = useState<Record<FILTER_OPTIONS, any>>({} as Record<FILTER_OPTIONS, any>);
  const dispatch = useCallback(
    (value: FilterActions<FILTER_OPTIONS>) => {
      baseDispatch(value);
      onChange && onChange();
    },
    [onChange]
  );

  const addNewFilter = useCallback(
    (newFilter: BaseFilterType<FILTER_OPTIONS>) => dispatch({ type: ADD_FILTER, payload: newFilter }),
    [dispatch]
  );
  const deleteFilter = useCallback(
    (keyToRemove: FILTER_OPTIONS) => dispatch({ type: DELETE_FILTER, payload: keyToRemove }),
    [dispatch]
  );
  const resetFilter = useCallback(
    (newFilters?: BaseFilterType<FILTER_OPTIONS>[]) => dispatch({ type: RESET_FILTER, payload: newFilters }),
    [dispatch]
  );
  const updateFilter = useCallback(
    (updatedFilter: BaseFilterType<FILTER_OPTIONS>) => {
      dispatch({ type: UPDATE_FILTER, payload: updatedFilter });
    },
    [dispatch]
  );

  const getFilter = useCallback(
    (key: FILTER_OPTIONS) => {
      return filterData.find(item => item.key === key);
    },
    [filterData]
  );

  useEffect(() => {
    const newFilterValues = filterData?.reduce((filterValues, data) => {
      filterValues[data.key] = data.value;
      return filterValues;
    }, {} as Record<FILTER_OPTIONS, any>);
    if (!equals(filterValues, newFilterValues)) {
      setFilterValues(newFilterValues);
    }
  }, [filterData, filterValues]);

  return [filterData, addNewFilter, deleteFilter, updateFilter, getFilter, resetFilter, filterValues];
};

export interface FilterManagementInterface<FILTER_OPTIONS extends string> {
  addNewFilter: (newFilter: BaseFilterType<FILTER_OPTIONS>) => void;
  deleteFilter: (keyToRemove: FILTER_OPTIONS) => void;
  updateFilter: (updatedFilter: BaseFilterType<FILTER_OPTIONS>) => void;
}
