import React, { createContext, useContext } from 'react';
import { WSContext, WSContextType } from '../WSContext';
import { NotificationData } from './types';
import { notifyActionMessage } from 'components/MessageContainer';
import { getTypeByNotification } from './utils';
import {
  IGNORE_NOTIFICATIONS,
  // MARK_SEEN_PATH,
  // NOTIFICATIONS_LIMIT,
  // NOTIFICATIONS_PATH,
  WS_NOTIFICATIONS_PATH,
} from './constants';
import { AccountInfoData } from 'api/CailagateApi/api/client';

export class NotificationsContextType {
  wsContext: any = {};
}

export const NotificationsContext = createContext(new NotificationsContextType());

interface NotificationsProviderProps {
  user?: AccountInfoData;
}
interface NotificationsProviderState {
  notifications: any[];
}

export default class NotificationsProvider extends React.Component<
  NotificationsProviderProps,
  NotificationsProviderState
> {
  static contextType = WSContext;
  context!: WSContextType;

  subscribeId = '';

  deleteNotification = (id?: string) => {
    setTimeout(() => {
      this.markSeen(String(id));
    }, 0);
  };

  markSeen = (notificationsToDelete: string | string[]) => {
    //TODO: uncommit when it will be fixed
    // this.context.send(MARK_SEEN_PATH, {
    //   notifications: typeof notificationsToDelete === 'string' ? [notificationsToDelete] : notificationsToDelete,
    // });
  };

  handleNotification = (notifications: NotificationData[]) => {
    notifications.forEach(notification => {
      const { id } = notification;
      //TODO:Do we need 'botId?'
      // const notificationData = `${notification.message?.data?.output || notification.message?.data?.botId}`;
      const notificationData = `${notification.message?.data?.output || ''}`;
      const notificationText = `${notification.message?.code?.code}: ${notification.message?.code?.text}${
        notificationData && '\n' + notificationData.toString()
      }`;
      notifyActionMessage(notificationText, {
        type: getTypeByNotification(notification),
        theme: 'dark',
        onClose: () => this.deleteNotification(id),
        onClick: () => this.deleteNotification(id),
      });
    });
  };

  subscriber = (body: any) => {
    let notificationsDataToParse;
    if (Array.isArray(body)) {
      notificationsDataToParse = body as NotificationData[];
    } else if (body && body.records) {
      notificationsDataToParse = body.records as NotificationData[];
    }
    if (!notificationsDataToParse) return;
    const notifications = notificationsDataToParse.filter(
      notification => !IGNORE_NOTIFICATIONS.includes(notification?.message?.code?.code || '')
    );
    this.handleNotification(notifications);
  };

  componentDidMount() {
    //TODO: uncommit when it will be fixed
    // this.subscribeId = this.context.subscribe(WS_NOTIFICATIONS_PATH, this.subscriber);
    // this.context.send(NOTIFICATIONS_PATH, { size: NOTIFICATIONS_LIMIT });
  }

  componentWillUnmount() {
    if (this.subscribeId) this.context.unsubscribe(WS_NOTIFICATIONS_PATH, this.subscribeId);
  }

  render() {
    return (
      <NotificationsContext.Provider
        value={{
          wsContext: this.context,
        }}
      >
        {this.props.children}
      </NotificationsContext.Provider>
    );
  }
}

export const useNotifications = () => useContext(NotificationsContext);
