import React from 'react';

import HeaderButton from '../HeaderButton';
import Menu, { MenuItemInterface } from 'components/Menu';

import { useAuthContext } from 'contexts/AuthContext';
import { useAppContext } from 'contexts/AppContext';

import { PERMISSIONS } from 'contexts/AuthContext/constants';
import { WORKSPACE_BASE_PATH } from 'routes';

import { t } from 'localization';

interface AccountMenuProps {
  userName?: string;
  logout: () => void;
}

const AccountMenu = function ({ logout }: AccountMenuProps) {
  const { userHasPermission, isUserSuperadmin } = useAuthContext();
  const { isBillingEnabled } = useAppContext();

  const menuList: MenuItemInterface[] = [
    {
      linkTo: `/${WORKSPACE_BASE_PATH}/account-info`,
      hide: !(userHasPermission(PERMISSIONS.MLP_ADMIN) && !isUserSuperadmin),
      label: t('Header:AccountMenu:Account'),
      'data-test-id': 'headerMenuAccountLink',
      iconRight: 'faUser',
    },
    {
      linkTo: `/${WORKSPACE_BASE_PATH}/balance-info`,
      label: t('Header:AccountMenu:Balance'),
      hide: !(isBillingEnabled && !isUserSuperadmin),
      'data-test-id': 'headerMenuBalanceLink',
      iconRight: 'faCoins',
    },
    {
      label: t('Header:AccountMenu:Logout'),
      onClick: () => logout(),
      'data-test-id': 'headerMenuLogout',
      iconRight: 'faSignOut',
    },
  ];

  return (
    <Menu
      direction='down'
      toggleClassName='dropdown-menu__toggle'
      menuClassName='dropdown-menu__account'
      toggleRender={() => (
        <HeaderButton className='user' data-test-id='headerMenuButton' color='primary' flat iconRight='farUser' />
      )}
      list={menuList}
    />
  );
};

export default React.memo(AccountMenu);
