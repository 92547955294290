import { CSSProperties, useEffect, useState } from 'react';

export type MountStates = 'beforeMount' | 'mount' | 'unmount';

export type MountStateStyleMapType = Record<MountStates, CSSProperties>;

export function useDelayedMount(show: boolean, delayTime: number = 0): [boolean, MountStates] {
  const [mountState, setMountState] = useState<MountStates>('beforeMount');
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (show && !shouldRender) {
      setShouldRender(true);
      timeoutId = setTimeout(() => setMountState('mount'), 0);
    } else if (!show && shouldRender) {
      setMountState('unmount');
      timeoutId = setTimeout(() => setShouldRender(false), delayTime);
    }
  }, [delayTime, mountState, shouldRender, show]);

  return [shouldRender, mountState];
}
