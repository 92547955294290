import { DeepPartial, UnpackNestedValue, useForm } from 'react-hook-form';

export const useDefaultForm = <FIELD_TYPE extends object>(
  defaultValues: UnpackNestedValue<DeepPartial<FIELD_TYPE & { commonError: any }>>
) =>
  useForm<FIELD_TYPE & { commonError: any }>({
    criteriaMode: 'all',
    defaultValues: { ...defaultValues },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
    resolver: values => {
      let newValues = values;
      if (values.commonError) {
        newValues = { ...values, commonError: undefined };
      }
      return { errors: {}, values: newValues };
    },
  });
