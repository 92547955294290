type StorageSuccessResponse<ELEMENT> = { success: true; payload?: ELEMENT };
type StorageErrorResponse = { success: false; error?: string };

export type StorageResponse<ELEMENT> = StorageSuccessResponse<ELEMENT> | StorageErrorResponse;

export abstract class BaseAbstractStorage {
  public abstract get<ELEMENT>(key: string): Promise<StorageResponse<ELEMENT | null>>;
  public abstract set<ELEMENT>(key: string, value: ELEMENT): Promise<StorageResponse<null>>;

  protected createSuccess<ELEMENT>(payload: ELEMENT): StorageSuccessResponse<ELEMENT> {
    return {
      success: true,
      payload,
    };
  }

  protected createError(error: string): StorageErrorResponse {
    return {
      success: false,
      error,
    };
  }
}
