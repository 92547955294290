import React from 'react';

import { t } from 'localization';
import styles from './styles.module.scss';

const TrainingWidgetHeader = () => {
  return (
    <div className={styles.trainingWidgetHeader}>
      <div className={styles.trainingWidgetHeader__row}>
        <h3>{t('TrainingWidget:Header:ServiceFit')}</h3>
      </div>
      <span>{t('TrainingWidget:Header:ToTestService')}</span>
    </div>
  );
};

export default React.memo(TrainingWidgetHeader);
