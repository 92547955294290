import React from 'react';
import MessageLayout from '../../layouts/MessageLayout';
import { MessageSourceType } from '../../types';

import styles from './styles.module.scss';

const LoadingBubble = () => {
  return (
    <MessageLayout messageSource={MessageSourceType.BOTMESSAGE}>
      <div className={styles.loading__wrap} data-test-id='Chat:LoadingBubble'>
        <div className={styles.loading__bubble}></div>
      </div>
    </MessageLayout>
  );
};

export default React.memo(LoadingBubble);
