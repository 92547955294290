import { NotificationTransport } from './Transports/NotificationTransport';
import { ConsoleLogTransport } from './Transports/ConsoleLogTransport';

interface LogEvent {
  message?: string;
  exception?: unknown;
}

export interface RichLogEvent {
  level: LogLevel;
  message?: string;
  exception?: unknown;
}

export enum LogLevel {
  FATAl,
  ERROR,
  WARN,
  LOG,
  INFO,
  DEBUG,
}

export interface LogTransport {
  level: LogLevel;
  log: (event: RichLogEvent, context?: any) => void;
}

export class Logger {
  transports: Record<string, LogTransport> = {};

  public fatal(event: LogEvent, context?: any) {
    this.notify(LogLevel.FATAl, event, context);
  }
  public error(event: LogEvent, context?: any) {
    this.notify(LogLevel.ERROR, event, context);
  }
  public warn(event: LogEvent, context?: any) {
    this.notify(LogLevel.WARN, event, context);
  }
  public log(event: LogEvent, context?: any) {
    this.notify(LogLevel.LOG, event, context);
  }
  public info(event: LogEvent, context?: any) {
    this.notify(LogLevel.INFO, event, context);
  }
  public debug(event: LogEvent, context?: any) {
    this.notify(LogLevel.DEBUG, event, context);
  }

  public addTransport(key: string, transport: LogTransport) {
    this.transports[key] = transport;
  }

  public removeTransport(key: string) {
    delete this.transports[key];
  }

  private notify(level: LogLevel, event: LogEvent, context?: any) {
    Object.values(this.transports)
      .filter(transport => transport.level >= level)
      .forEach(transport => {
        const richLogEvent: RichLogEvent = Object.assign(event, { level });
        transport.log(richLogEvent, context);
      });
  }
}

const AppLogger = new Logger();
AppLogger.addTransport('notification', new NotificationTransport(LogLevel.ERROR));
AppLogger.addTransport('console', new ConsoleLogTransport(LogLevel.WARN));

export { AppLogger };
