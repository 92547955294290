import { Icon } from '@just-ai/just-ui/dist/Icon';
import React from 'react';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import cn from 'classnames';

import styles from './styles.module.scss';

interface PaginateInterface extends ReactPaginateProps {}

const Paginate = ({ pageCount, ...props }: PaginateInterface) => {
  return (
    <ReactPaginate
      breakLabel='...'
      nextLabel={<Icon name='farChevronRight' />}
      previousLabel={<Icon name='farChevronLeft' />}
      pageRangeDisplayed={5}
      containerClassName={cn(styles.paginate, pageCount <= 1 && styles.hidden)}
      pageClassName={styles.paginate_page}
      pageLinkClassName={styles.paginate_page_link}
      breakClassName={styles.paginate_page}
      breakLinkClassName={styles.paginate_page_link}
      activeClassName={styles.paginate_active}
      previousClassName={styles.paginate_previous}
      nextClassName={styles.paginate_next}
      previousLinkClassName={styles['paginate_previous-link']}
      nextLinkClassName={styles['paginate_next-link']}
      pageCount={pageCount}
      {...props}
    />
  );
};

export default React.memo(Paginate);
