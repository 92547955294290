import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';

import TabsNavigate from 'modules/Account/components/TabsNavigate';

import { ServicePageTabRoutes, ServicePageTabValues } from './types';

import styles from './styles.module.scss';

const ServiceContent = function () {
  const { path: routePath, url } = useRouteMatch();

  return (
    <div className={styles.content__wrapper}>
      <Route path={`${routePath}/:servicePageTab`}>
        <TabsNavigate tabs={ServicePageTabValues} path={url} paramName='servicePageTab' />
      </Route>
      <Switch>
        {ServicePageTabRoutes.map((route, index) => (
          <Route key={index} path={`${routePath}/${route.path}`}>
            <route.Cmp />
          </Route>
        ))}
        <Redirect to={`${routePath}/`} />
      </Switch>
    </div>
  );
};

export default React.memo(ServiceContent);
