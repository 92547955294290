export interface FormSubmitError {
  error: string;
  message: string;
  args: any;
  errors?: {
    error: string;
    message: string;
    args: any;
  }[];
}
export const isFormError = (error: any): error is FormSubmitError => {
  if (!error) return false;
  const formErrorObject = error as FormSubmitError;
  return formErrorObject.error !== undefined && formErrorObject.message !== undefined;
};

export interface ParsedFormError<FIELD_NAME extends string> {
  message: string;
  placement: FIELD_NAME | 'commonError';
}
