import { useEffect } from 'react';
import { UseFormResetField } from 'react-hook-form';

import { useTrainingContext } from 'modules/ServiceFitComponents/contexts/TrainingContext';
import { ConfigDefinitionMode, TrainingFormFieldsNames, TrainingFormFieldsType } from '../types';
import { isNullOrUndefined } from 'utils';

export const useFormExternalData = (resetField: UseFormResetField<TrainingFormFieldsType>, deps: any[] = []) => {
  const { datasets, datasetDataTypes, fitConfigs, serviceData, getFormData, isLoadingGlobal } = useTrainingContext();

  useEffect(() => {
    getFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFormData, ...deps]);

  useEffect(() => {
    if (isLoadingGlobal) {
      return;
    }
    if (datasets?.length) {
      let datasetIdToSet = datasets[0].id?.datasetId.toString();
      datasetIdToSet = !isNullOrUndefined(serviceData?.trainingDatasetId)
        ? datasets
            ?.find(item => item?.id?.datasetId?.toString() === serviceData.trainingDatasetId?.toString())
            ?.id?.datasetId?.toString()
        : datasetIdToSet;
      window.requestAnimationFrame(() => {
        resetField(TrainingFormFieldsNames.datasetId, { defaultValue: datasetIdToSet });
      });
    }

    if (datasetDataTypes?.length) {
      const datasetDataTypeToSet = datasetDataTypes[0].toString();
      window.requestAnimationFrame(() => {
        resetField(TrainingFormFieldsNames.datasetDataType, { defaultValue: datasetDataTypeToSet });
      });
    }

    const manualFitConfigData = fitConfigs?.find(config => config.isManual);
    const defaultFitConfigId = fitConfigs?.find(config => config.isDefault)?.id?.configId.toString();
    const trainingFitConfigId = fitConfigs
      ?.find(config => config.id.configId.toString() === serviceData.trainingFitConfigId?.toString())
      ?.id?.configId.toString();

    const fieldsToReset = (() => {
      if (manualFitConfigData) {
        const { config, id } = manualFitConfigData;
        return {
          config,
          configMode: ConfigDefinitionMode.writeConfig,
          configId: id.configId.toString(),
          shouldEditConfig: true,
        };
      } else if (defaultFitConfigId) {
        return {
          config: '',
          configMode: ConfigDefinitionMode.selectExistingConfig,
          configId: defaultFitConfigId,
          shouldEditConfig: false,
        };
      } else if (trainingFitConfigId) {
        return {
          config: '',
          configMode: ConfigDefinitionMode.selectExistingConfig,
          configId: trainingFitConfigId,
          shouldEditConfig: true,
        };
      } else if (fitConfigs?.length) {
        return {
          config: '',
          configMode: ConfigDefinitionMode.selectExistingConfig,
          configId: fitConfigs?.[0]?.id.configId.toString(),
          shouldEditConfig: true,
        };
      } else {
        return {
          config: '',
          configMode: ConfigDefinitionMode.writeConfig,
          configId: undefined,
          shouldEditConfig: true,
        };
      }
    })();

    Object.entries(fieldsToReset).forEach(([key, value]) => {
      window.requestAnimationFrame(() => {
        resetField(key as keyof typeof fieldsToReset, { defaultValue: value });
      });
    });
  }, [
    datasetDataTypes,
    datasets,
    fitConfigs,
    isLoadingGlobal,
    resetField,
    serviceData.trainingDatasetId,
    serviceData.trainingFitConfigId,
  ]);
};
