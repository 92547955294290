import { Service } from 'typedi';

import { ModelGroupEndpointApi } from 'api/CailagateApi/api/client';

@Service()
export class ModelGroupApiService extends ModelGroupEndpointApi {
  constructor() {
    super({ baseOptions: { timeout: 30000 } }, '');
  }
}
