import { Inject, Service } from 'typedi';
import axios from 'axios';

import { ProcessApiService } from 'services/ApiServices/ProcessApiService';
import { Fit2RequestData } from 'api/CailagateApi/api/client';
import { DatasetApiService } from 'services/ApiServices/DatasetApiService';
import {
  ConfigDefinitionMode,
  DatasetSelectMode,
  ResponseErrorFieldNames,
  TrainingFormFieldsNames,
  TrainingFormFieldsNamesList,
  TrainingFormFieldsType,
} from 'modules/ServiceFitComponents/components/TrainingForm/types';
import { ModelApiService } from 'services/ApiServices/ModelApiService';
import { enhanceAxiosError } from 'utils/form';
import { CacheRequestsService } from 'services/CacheRequestsService';
import { useEffect } from 'react';
import { useAppContext } from 'contexts/AppContext';

const CREATE_DATASET_OPTIONS = { timeout: 180000 };

@Service()
export class TrainingService {
  @Inject(() => ProcessApiService) processApi!: ProcessApiService;
  @Inject(() => DatasetApiService) datasetApi!: DatasetApiService;
  @Inject(() => ModelApiService) modelApi!: ModelApiService;
  @Inject(() => CacheRequestsService) cacheRequestsService!: CacheRequestsService;

  public async submit(account: string, modelAccount: string, model: string, formValues: TrainingFormFieldsType) {
    const { config, configId, configMode, name, shouldEditConfig } = formValues;
    let datasetIdToSend: number = await this.selectDatasetStep(account, formValues);

    let derivedModelId: string;
    const { data: derivedModel } = await this.cacheRequestsService.cacheRequest(
      'createDerivedModel',
      (...props) => this.modelApi.createDerivedModel(...props),
      modelAccount,
      model,
      name
    );
    derivedModelId = derivedModel.id.modelId.toString();

    let fitRequestData: Fit2RequestData = { datasetId: datasetIdToSend };

    switch (true) {
      case !shouldEditConfig: {
        break;
      }
      case configMode === ConfigDefinitionMode.selectExistingConfig: {
        const configIdToSend = configId !== undefined ? parseInt(configId) : undefined;
        fitRequestData = {
          ...fitRequestData,
          configId: configIdToSend,
        };
        break;
      }
      default: {
        const configtTrimmed = config.trim();
        const configToSend = configtTrimmed || undefined;
        fitRequestData = {
          ...fitRequestData,
          config: configToSend,
        };
      }
    }
    await this.processApi.fitV2(account, derivedModelId, fitRequestData);
    this.cacheRequestsService.clear();
    return derivedModel;
  }

  async selectDatasetStep(account: string, formValues: TrainingFormFieldsType) {
    const { datasetId, datasetSelectMode } = formValues;
    let datasetIdToSend: number;
    if (datasetSelectMode === DatasetSelectMode.upload) {
      try {
        const { data: createdDatasetdata } = await this.cacheRequestsService.cacheRequest(
          'createDataset',
          (...props) => this.datasetApi.createDataset(...props),
          account,
          formValues.datasetName,
          undefined,
          formValues.datasetDataType,
          undefined,
          undefined,
          formValues.datasetContent,
          CREATE_DATASET_OPTIONS
        );
        datasetIdToSend = createdDatasetdata.id?.datasetId as number;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const modifiedError = enhanceAxiosError(error, TrainingFormFieldsNamesList, {
            [ResponseErrorFieldNames.name]: TrainingFormFieldsNames.datasetName,
          });
          throw modifiedError;
        } else {
          throw error;
        }
      }
    } else {
      datasetIdToSend = parseInt(datasetId as string);
    }
    return datasetIdToSend;
  }

  public async submitUpdate(account: string, derivedModelId: string, formValues: TrainingFormFieldsType) {
    const { config, configId, configMode, shouldEditConfig } = formValues;
    let datasetIdToSend: number = await this.selectDatasetStep(account, formValues);

    let fitRequestData: Fit2RequestData = { datasetId: datasetIdToSend };

    switch (true) {
      case !shouldEditConfig: {
        break;
      }
      case configMode === ConfigDefinitionMode.selectExistingConfig: {
        const configIdToSend = configId !== undefined ? parseInt(configId) : undefined;
        fitRequestData = {
          ...fitRequestData,
          configId: configIdToSend,
        };
        break;
      }
      default: {
        const configtTrimmed = config.trim();
        const configToSend = configtTrimmed || undefined;
        fitRequestData = {
          ...fitRequestData,
          config: configToSend,
        };
      }
    }
    await this.processApi.fitV2(account, derivedModelId, fitRequestData);
    this.cacheRequestsService.clear();
    return derivedModelId;
  }

  public clearCache() {
    this.cacheRequestsService.clear();
  }
}

export const useTrainingService = () => {
  const trainingService = useAppContext().diContainer.get(TrainingService);

  useEffect(() => {
    return () => {
      trainingService.clearCache();
    };
  }, [trainingService]);
  return trainingService;
};
