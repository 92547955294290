import { WaveSurferParams } from 'wavesurfer.js/types/params';

export const WAVE_FORM_ID = 'waveform';

export const waveformOptions: WaveSurferParams = {
  container: `#${WAVE_FORM_ID}`,
  height: 25,
  waveColor: '#605FE3',
  barWidth: 1,
  interact: false,
  barHeight: 80,
  cursorWidth: 0,
  barGap: 5,
};

export const BUFFER_SIZE = 2048;
