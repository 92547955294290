import React from 'react';
import cn from 'classnames';
import { Button } from '@just-ai/just-ui/dist/Button';

import './style.scss';

interface CloseButtonInterface {
  onClick: () => void;
  className?: string;
  dataTestId?: string;
}

const CloseButton = function ({ onClick, className, dataTestId }: CloseButtonInterface) {
  return (
    <Button className={cn('close-button', className)} aria-label='Close' onClick={onClick} data-test-id={dataTestId}>
      <span aria-hidden='true'>×</span>
    </Button>
  );
};

export default React.memo(CloseButton);
