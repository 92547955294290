import React, { PureComponent, createRef } from 'react';
import keyboardJS from 'keyboardjs';
import cn from 'classnames';
import { IMediaRecorder, MediaRecorder } from 'extendable-media-recorder';

import IconButton from 'components/IconButton';
import Player from './components/Player';

import { RecordingWaveForm } from 'facades/RecordingWaveForm';
import { RecorderContext, RecorderContextProviderComponent, RecorderContextType } from './contexts/RecorderContext';
import { WAVE_FORM_ID } from 'facades/RecordingWaveForm/constants';

import { t } from 'localization';

import styles from './styles.module.scss';

interface RecorderInterface {
  record: Blob | null;
  setRecord: (audio: Blob | null) => void;
}

class Recorder extends PureComponent {
  static contextType = RecorderContext;
  context!: RecorderContextType;

  waveForm?: RecordingWaveForm;
  mediaRecorder?: IMediaRecorder;
  localAudio = createRef() as React.MutableRefObject<any>;

  recordingFinishTimerId?: NodeJS.Timeout = undefined;

  componentDidMount() {
    if (this.context.inputDevices.length === 0) {
      this.context.checkPermission();
    }
    keyboardJS.bind(['space'], null, this.toggleRecord);
  }

  componentWillUnmount() {
    keyboardJS.unbind(['space'], null, this.toggleRecord);
  }

  startRecording = () => {
    try {
      this.waveForm?.destroy();
      this.waveForm = new RecordingWaveForm();
      const audioChunks: any[] = [];
      this.waveForm.onMicrophoneReady(stream => {
        try {
          this.mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/wav' });
          this.mediaRecorder.start();
          this.mediaRecorder.addEventListener('dataavailable', (event: any) => {
            audioChunks.push(event.data);
          });
          this.recordingFinishTimerId = setTimeout(() => {
            this.stopRecording();
            this.resetTimeout();
          }, 120000);
        } catch (error) {
          console.error(error);
        }
        this.mediaRecorder?.addEventListener('stop', event => {
          try {
            const newRecord = new Blob(audioChunks, { type: 'audio/wav' });
            this.context.setRecord(newRecord);
          } catch (error) {
            console.error(error);
          } finally {
            this.context.setIsRecording(false);
            this.resetTimeout();
          }
        });
      });
      this.context.setIsRecording(true);
      this.waveForm.start();
    } catch (error) {
      console.error(error);
    }
  };

  stopRecording = () => {
    try {
      this.mediaRecorder?.stop();
      this.waveForm?.stop();
    } catch (error) {
      this.context.setIsRecording(false);
    }
  };

  resetTimeout = () => {
    if (this.recordingFinishTimerId) {
      clearTimeout(this.recordingFinishTimerId);
      this.recordingFinishTimerId = undefined;
    }
  };

  toggleRecord = () => {
    if (this.context.isRecording) {
      this.stopRecording();
    } else {
      this.startRecording();
    }
  };

  render() {
    return (
      <div className={cn(styles.recorder__wrap, styles.recorder__wrap_player)}>
        <IconButton
          className={cn(
            styles.recorder__recordButton,
            this.context.isRecording && styles.recorder__recordButton_recording
          )}
          name='faMicrophone'
          size='lg'
          onClick={() => this.toggleRecord()}
        />
        {!this.context.isRecording && !this.context.record && <span>{t('RecordTestWidget:PressMic')}</span>}
        <div className={styles.recorder__waveformContainer} hidden={!this.context.isRecording}>
          <div id={WAVE_FORM_ID} />
        </div>
        <Player />
      </div>
    );
  }
}

const RecorderWithContext = React.memo(({ record, setRecord }: RecorderInterface) => {
  return (
    <RecorderContextProviderComponent record={record} setRecord={setRecord}>
      <Recorder />
    </RecorderContextProviderComponent>
  );
});

export default RecorderWithContext;
