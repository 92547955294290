import { Service } from 'typedi';

@Service()
export class AbortControllerService {
  controllers: Map<string, AbortController>;

  constructor() {
    this.controllers = new Map<string, AbortController>();
  }

  async get<RESPONSE_DATA>(id: string, request: (signal: AbortSignal) => Promise<RESPONSE_DATA>) {
    const controller = new AbortController();
    if (this.controllers.has(id)) {
      this.cancel(id);
    }
    this.controllers.set(id, controller);
    const signal = controller.signal;

    return request(signal)
      .then(response => {
        return response;
      })
      .catch(error => {
        throw error;
      })
      .finally(() => {
        this.controllers.delete(id);
      });
  }

  cancel(id: string) {
    this.controllers.get(id)?.abort();
    this.controllers.delete(id);
  }

  cancelAll() {
    this.controllers.forEach((controller, id) => controller.abort());
    this.controllers.clear();
  }
}
