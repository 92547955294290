import React, { useCallback, useMemo, useState } from 'react';
import { FilterModal as FilterModalComponent } from '@just-ai/just-ui/dist/FilterModal';
import { BaseFilterType } from './types';
import { t } from 'localization';
import './style.scss';
import styles from './styles.module.scss';

interface FilterInterface<FILTER_OPTIONS extends string> {
  filter?: BaseFilterType<FILTER_OPTIONS>;
  options: {
    label: string;
    value: FILTER_OPTIONS;
  }[];
  reactKey?: number;
  hidden?: boolean;
  onChoose?: (key: FILTER_OPTIONS) => void;
  onChange?: (value: any, label?: string) => void;
  onDelete?: (key: FILTER_OPTIONS) => void;
  required?: boolean;
  filterComponents: Record<FILTER_OPTIONS, React.FC<any>>;
  labelPrefix?: string;
  stayClosed?: boolean;
}

const Filter = <FILTER_OPTIONS extends string>({
  filter,
  options,
  onChoose,
  onChange,
  onDelete,
  hidden = false,
  required = false,
  stayClosed = false,
  filterComponents,
  labelPrefix = 'Filter:SelectFilter',
}: FilterInterface<FILTER_OPTIONS>) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const onDeleteFilter = useCallback(() => onDelete!(filter?.key!), [filter, onDelete]);
  const handleClose = useCallback(() => {
    setShowModal(false);
    if (!filter?.value) {
      onDeleteFilter();
    }
  }, [filter?.value, onDeleteFilter]);

  const handleResolve = useCallback(
    (value: string, label?: string) => {
      onChange && onChange(value, label);
      setShowModal(false);
    },
    [onChange]
  );

  const label = useMemo(() => {
    if (!filter?.key) return '';
    if (filter.label) {
      return (
        <>
          {`${t(`${labelPrefix}:${filter.key}`)}: `}
          <span className={styles.filter__label}>{filter.label}</span>
        </>
      );
    }
    let value = filter?.value;
    if (Array.isArray(value)) {
      value = value.join(';');
    }
    return value ? (
      <>
        {`${t(`${labelPrefix}:${filter.key}`)}: `}
        <span className={styles.filter__label}>{value}</span>
      </>
    ) : (
      `${t(`${labelPrefix}:${filter.key}`)}`
    );
  }, [filter?.key, filter?.label, filter?.value, labelPrefix]);

  if (hidden) return null;
  return (
    <>
      <FilterModalComponent
        label={label as string}
        id='servicesFilterId'
        type={filter ? 'fulfilled' : 'new'}
        maxHeight={1000}
        options={options}
        onOpen={filter ? () => setShowModal(true) : undefined}
        onClose={filter ? () => handleClose() : undefined}
        forceShowModal={filter ? (showModal as boolean) : undefined}
        onSelect={value => onChoose && onChoose(value as FILTER_OPTIONS)}
        onRemove={filter && onDeleteFilter}
        stayClosed={stayClosed}
        placeholder={t('Catalog:FilterPlaceholder')}
        required={required}
      >
        {filter &&
          filterComponents[filter.key] &&
          filterComponents[filter.key]({
            isOpen: showModal,
            onClose: handleClose,
            onResolve: handleResolve,
            filter: filter,
          })}
      </FilterModalComponent>
    </>
  );
};

export default React.memo(Filter) as typeof Filter;
