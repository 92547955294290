import { t } from 'localization';

export type RequestType = 'modelAdmin' | 'publicModelAdmin';

export const getRequestFormMessages = (requestType: RequestType): string => {
  switch (requestType) {
    case 'modelAdmin':
      return t('AccessRequestModal:ModelAdminFeatureRequest');
    case 'publicModelAdmin':
    default:
      return t('AccessRequestModal:PublicModelAdminFeatureRequest');
  }
};
