import React, { useCallback, useState } from 'react';
import { KeyEvent } from 'keyboardjs';

import TextArea from 'components/TextArea';
import IconButton from 'components/IconButton';

import useKeyboardAction from 'utils/hooks/useKeyboardAction';
import { useToggle } from 'utils/hooks';
import { useChatTestWidgetContext } from '../../contexts/ChatTestWidgetContext';

import { isKeyEvent } from 'utils/typeguards';
import { t } from 'localization';

import styles from './styles.module.scss';

const SendMessage = () => {
  const [text, setText] = useState('');
  const [isFocused, setIsFocused, setIsNotFocused] = useToggle();

  const { handleSendMessage, isLoading: disabled } = useChatTestWidgetContext();
  const clearText = useCallback(() => {
    setText('');
  }, []);

  const handleSubmit = useCallback(
    (event?: KeyEvent | React.MouseEvent<HTMLElement>) => {
      if (isKeyEvent(event) && event?.shiftKey) {
        return;
      }
      event?.preventDefault();
      if (!text.trim() || disabled) return;
      handleSendMessage(text, clearText);
    },
    [clearText, disabled, handleSendMessage, text]
  );

  useKeyboardAction({ enable: isFocused, hotKeys: ['enter'], action: handleSubmit });

  return (
    <div className={styles.sendMessage}>
      <TextArea
        value={text}
        data-test-id='ChatTestWidget:MessageTextarea'
        onFocus={setIsFocused}
        onBlur={setIsNotFocused}
        onChange={setText}
        placeholder={t('ChatTestWidget:MessageToSend')}
        wrapperClassName={styles.sendMessage__text}
      />
      <div className={styles.sendMessage__control}>
        <IconButton
          data-test-id='ChatTestWidget:SendMessageButton'
          name='farArrowRight'
          color='primary'
          round
          onClick={handleSubmit}
          type='submit'
          disabled={!text || disabled}
          className={styles.sendMessage__button}
        />
      </div>
    </div>
  );
};

export default React.memo(SendMessage);
