import React from 'react';
import { Icon } from '@just-ai/just-ui/dist/Icon';

import cn from 'classnames';
import './style.scss';

interface ErrorInfoBannerInterface {
  errorMessage: string;
  className?: string;
}

const ErrorInfoBanner = ({ errorMessage, className }: ErrorInfoBannerInterface, ref: React.Ref<HTMLDivElement>) => {
  return (
    <div className={cn('error-info-banner', className)} ref={ref}>
      <Icon name='farExclamationCircle' color='link' size='lg' />
      {errorMessage}
    </div>
  );
};

export default React.memo(React.forwardRef(ErrorInfoBanner));
