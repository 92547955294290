import { useEffect } from 'react';

const useLockScroll = (element?: HTMLElement, shouldLock: boolean = false) => {
  useEffect(() => {
    if (!element) return;

    const handleScroll = (event: WheelEvent) => {
      if (shouldLock) {
        event.preventDefault();
      }
    };
    element.addEventListener('wheel', handleScroll);
    return () => {
      element.removeEventListener('wheel', handleScroll);
    };
  }, [element, shouldLock]);
};

export default useLockScroll;
