import CailaLogo from 'img/caila-logo.svg';
import styles from './styles.module.scss';

const Logo = () => {
  return (
    <div data-test-id='headerLogoLink' className={styles.logo}>
      <img src={CailaLogo} alt='caila-logo' />
    </div>
  );
};

export default Logo;
