import React, { Ref } from 'react';
import CaptchaContainer from './components/CaptchaContainer';

export interface WidgetConfigProps {
  isRelative?: boolean;
}

export function withCaptcha<PROPS extends object>(Cmp: React.ComponentType<PROPS>) {
  return React.forwardRef(function (props: PROPS, ref: Ref<React.ComponentType<PROPS>>) {
    return (
      <CaptchaContainer>
        <Cmp {...props} ref={ref} />
      </CaptchaContainer>
    );
  });
}
