import React from 'react';
import { MessageSourceType } from '../../types';
import cn from 'classnames';

import styles from './styles.module.scss';

interface MessageLayoutInterface {
  messageSource: MessageSourceType;
  children: React.ReactNode;
}

const MessageClasses: Record<string, string> = {
  [MessageSourceType.BOTMESSAGE]: styles.message__wrap_BOTMESSAGE,
  [MessageSourceType.CLIENTMESSAGE]: styles.message__wrap_CLIENTMESSAGE,
};

const MessageLayout = ({ messageSource, children }: MessageLayoutInterface) => {
  return (
    <div
      data-test-id={`Chat.MessageTypes.${messageSource}`}
      className={cn(styles.message__wrap, MessageClasses[messageSource])}
    >
      <div className={styles.message__container}>{children}</div>
    </div>
  );
};

export default React.memo(MessageLayout);
