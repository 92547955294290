import { RouteProps } from 'react-router';
import DescriptionTab from './components/DescriptionTab';

export type TabElement = {
  name: string;
  value: string;
  dataTestId?: string;
};

export enum ServicePageTabs {
  Description = 'description',
}

interface ServicePageTabRouteInterface extends Omit<RouteProps, 'children'> {
  Cmp: React.FC;
}

export const ServicePageTabRoutes: ServicePageTabRouteInterface[] = [
  {
    path: '',
    Cmp: DescriptionTab,
  },
];

export const ServicePageTabValues: TabElement[] = [
  {
    name: 'ServicePage:Tabs:Description',
    value: '',
    dataTestId: 'servicePage:Tabs:Description',
  },
];
