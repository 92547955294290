import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';

import SimpleRadioButtonsGroup from 'components/SimpleRadioButtonsGroup';
import ControlledFileUpload from 'components/ControlledFileUpload';
import Recorder from '../../../Recorder';

import { AudioCreationMode } from '../../types';
import { useASRTestingFormContext } from '../..';

import { t } from 'localization';
import styles from './styles.module.scss';
import ResizableTextArea from 'components/ResizableTextArea';

interface AudioEditInterface {
  developerMode?: boolean;
}

const AudioEdit = ({ developerMode = false }: AudioEditInterface) => {
  const { control, watch, register } = useASRTestingFormContext();

  const watchAudioCreationMode = watch('audioCreationMode');

  const AudioCreationModeOptions = useMemo(() => {
    let options = [
      {
        label: t(`ASRTestingForm:${AudioCreationMode.upload}`),
        name: AudioCreationMode.upload,
        value: AudioCreationMode.upload,
      },
      {
        label: t(`ASRTestingForm:${AudioCreationMode.record}`),
        name: AudioCreationMode.record,
        value: AudioCreationMode.record,
      },
    ];
    return !developerMode
      ? options
      : [
          ...options,
          {
            label: t(`ASRTestingForm:${AudioCreationMode.json}`),
            name: AudioCreationMode.json,
            value: AudioCreationMode.json,
          },
        ];
  }, [developerMode]);

  return (
    <>
      <input type='hidden' {...register('audioCreationMode')} />

      <h3>{t('ASRTestingForm:AudioRecord')}</h3>
      <Controller
        name='audioCreationMode'
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <SimpleRadioButtonsGroup
            value={value}
            onChange={onChange}
            containerClassName={styles.datasetSelect__radioButtons}
            name='AudioCreationSelect'
            list={AudioCreationModeOptions}
          />
        )}
      />

      {watchAudioCreationMode === 'upload' && (
        <ControlledFileUpload
          name='audioFile'
          data-test-id='ASRTestingForm:FileUploader'
          id='ASRTestingForm:FileUploader'
          className={styles.datasetSelect__upload}
          control={control}
          accept='.wav, .mp3'
          subText={t('ASRTestingForm:AllowedAudioFormats')}
        />
      )}
      {watchAudioCreationMode === 'record' && (
        <Controller
          name='audio'
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => <Recorder record={value} setRecord={onChange} />}
        />
      )}
      {watchAudioCreationMode === 'json' && (
        <Controller
          name='requestJson'
          control={control}
          rules={{ required: false }}
          render={({ field: { value, onChange, name }, fieldState: { invalid, error } }) => (
            <ResizableTextArea
              name={name}
              value={value}
              onChange={onChange}
              placeholder={t('Model:TestingForm:RequestBody')}
              className={styles.datasetSelect__textarea}
              maxHeight={300}
              // onFocus={handleOnFocus}
              // onBlur={handleOnBlur}
              errorText={invalid ? error?.message : ''}
              hasError={invalid}
            />
          )}
        />
      )}
    </>
  );
};

export default React.memo(AudioEdit);
