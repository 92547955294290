import { useFilter } from 'components/Filter/reducer';
import { FilterOptions as CatalogFilterOptions } from '../components/FiltersContainer/types';
import { useServicesContext } from 'contexts/ServicesContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { initializeCatalogFilter } from '../utils';
import { useQueryState } from 'utils/hooks/query';
import { CATALOG_FILTER_STORAGE_KEY, QUERY_FILTER_NAMES } from '../constants';
import { isNullOrUndefined } from 'utils';

export const useCatalogState = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [nameFilter, setNameFilter] = useState<string>('');
  const [pageCount, setPageCount] = useState<number | undefined>();
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const resetCurrentPage = useCallback(() => {
    if (isInitialized) {
      setCurrentPage(0);
    }
  }, [isInitialized]);

  const [filterData, addNewFilter, deleteFilter, updateFilter, getFilter, resetFilter, filterValues] =
    useFilter<CatalogFilterOptions>({ onChange: resetCurrentPage });

  const { taskTypes } = useServicesContext();

  const handleInitialize = useCallback(
    ({ name, page, ...initFilterValues }: Partial<Record<CatalogFilterOptions | 'name' | 'page', string>>) => {
      const newFilterData = initializeCatalogFilter({ name, page, ...initFilterValues }, taskTypes);
      resetFilter(newFilterData);
      setCurrentPage(parseInt(page || '0') || 0);
      name && setNameFilter(name);
      setIsInitialized(true);
    },
    [resetFilter, taskTypes]
  );

  useQueryState(
    { ...filterValues, name: nameFilter, page: currentPage.toString() },
    QUERY_FILTER_NAMES,
    handleInitialize,
    CATALOG_FILTER_STORAGE_KEY
  );

  const hasActiveFilters = useMemo(
    () => !!nameFilter || !!filterData.find(filter => !isNullOrUndefined(filter.value)),
    [filterData, nameFilter]
  );

  useEffect(() => {
    if (pageCount !== undefined && (pageCount === 0 || currentPage > pageCount - 1)) {
      resetCurrentPage();
    }
  }, [currentPage, pageCount, resetCurrentPage]);

  return {
    isInitialized,
    currentPage,
    setCurrentPage,
    nameFilter,
    setNameFilter,
    hasActiveFilters,
    filterState: { filterData, addNewFilter, deleteFilter, updateFilter, getFilter, resetFilter, filterValues },
    pageCount,
    setPageCount,
  };
};
