import { Service } from 'typedi';
import Prism from 'prismjs';

import './langs/json';
import './langs/curl';
import insertTextInPosition from 'utils/text/insertTextInPosition';

import { HighlightLangType } from './types';

@Service()
export class HighlightService {
  public highlight(text: string, type: HighlightLangType) {
    switch (type) {
      case HighlightLangType.CURL:
        text = text.replace(/.{4}\s+-[^\s](?=\s)/g, (match: string) => {
          return match.startsWith('curl') ? match : insertTextInPosition(match, 5, '\\\n');
        });
        return Prism.highlight(text, Prism.languages.curl, 'curl');
      case HighlightLangType.JSON:
        return Prism.highlight(text, Prism.languages.json, 'json');
      default:
        return text;
    }
  }
}
