import { useForm, UseFormProps, UseFormReturn } from 'react-hook-form';

import ControlledInput from 'components/ControlledInput';
import FormContainer from 'modules/Account/components/FormLayout/FormContainer';

import { AccessRequestFormFieldsType, AccessRequestFormMethodsType, defaultFormValues } from './types';

import { t } from 'localization';
import styles from './styles.module.scss';

interface AccessRequestFormInterface {
  formMethods: AccessRequestFormMethodsType;
  message?: string;
}

export const useAccessRequestForm = (
  props?: UseFormProps<AccessRequestFormFieldsType>
): UseFormReturn<AccessRequestFormFieldsType, any> => {
  return useForm<AccessRequestFormFieldsType>({
    criteriaMode: 'all',
    defaultValues: { ...defaultFormValues },
    mode: 'all',
    reValidateMode: 'onChange',
    ...props,
  });
};

export default function AccessRequestForm({ formMethods, message }: AccessRequestFormInterface) {
  const {
    control,
    formState: { errors },
  } = formMethods;
  return (
    <FormContainer
      className={styles.accessRequestForm__container}
      showError={errors?.commonError}
      errorMessage={errors.commonError?.message}
    >
      {message && <p className={styles.accessRequestForm__message}>{message}</p>}
      <ControlledInput
        title={t('AccessRequestModal:Form:ContactInformation')}
        data-test-id='contacts-field'
        name='contacts'
        placeholder={t('AccessRequestModal:Form:ContactInformationPlaceholder')}
        control={control}
        require
      />

      <ControlledInput
        title={t('AccessRequestModal:Form:Comment')}
        data-test-id='comment-field'
        name='comment'
        placeholder={t('AccessRequestModal:Form:CommentPlaceholder')}
        control={control}
      />
    </FormContainer>
  );
}
