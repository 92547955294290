import { LocalStorageService } from 'services/Storage/LocalStorageService';
import { Container } from 'typedi';

export interface KeeperInterface {
  get(): Promise<any>;
  set(data: any): void;
  setKey(key: string): void;
  clear(data: string): void;
}

export class StorageKeeper implements KeeperInterface {
  private readonly storage: LocalStorageService = Container.get(LocalStorageService);
  private key!: string;

  constructor(storageKey: string) {
    this.setKey(storageKey);
  }

  public setKey(key: string) {
    this.key = key;
    return this;
  }

  public async get() {
    return this.storage.get<string>(this.key);
  }

  public set(data: string) {
    this.storage.set(this.key, data);
  }

  public clear() {
    this.storage.set(this.key, undefined);
  }
}
