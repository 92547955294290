import React from 'react';
import { StatusType, StatusIndicatorMap } from './types';
import cn from 'classnames';
import styles from './styles.module.scss';

interface StatusIndicatorInterface {
  status?: StatusType;
  label?: string;
  dataTestId?: string;
  colorizeLabel?: boolean;
  className?: string;
  'label-data-test-id'?: string;
  'data-test-id'?: string;
}

const StatusIndicator = ({
  status,
  label,
  dataTestId,
  className,
  colorizeLabel = false,
  ...rest
}: StatusIndicatorInterface) => {
  if (!status) return null;
  const style = StatusIndicatorMap[status];
  if (label)
    return (
      <div data-test-id={dataTestId} className={cn(styles.status__container, className)}>
        <span
          data-status={status}
          data-test-id={rest['data-test-id']}
          className={cn(styles.status__indicator, style)}
        />
        <span data-test-id={rest['label-data-test-id']} className={cn(styles.status__label, colorizeLabel && style)}>
          {label}
        </span>
      </div>
    );
  return <span data-test-id={rest['data-test-id']} className={cn(styles.status__indicator, style, className)} />;
};

export default React.memo(StatusIndicator);
