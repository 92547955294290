import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import SwitchToggle from '@just-ai/just-ui/dist/SwitchToggle';

import { ConfigSelectFieldsType } from '../../types';

import { t } from 'localization';
import styles from './styles.module.scss';

const handlestopPropagation = (event: React.MouseEvent<HTMLDivElement>) => {
  event.stopPropagation();
};

const ConfigHead = () => {
  const { control } = useFormContext<ConfigSelectFieldsType>();
  return (
    <div className={styles.configurationSelect}>
      <div className={styles.configurationSelect__toggle} onClick={handlestopPropagation}>
        <Controller
          name='shouldSetConfig'
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <SwitchToggle {...field} id='configurationToggle' size='sm' switchType='round' color='primary' />
          )}
        />
      </div>
      <span className={styles.configurationSelect__label}>{t('ServicePage:TestingForm:EditServiceConfiguration')}</span>
    </div>
  );
};

export default React.memo(ConfigHead);
